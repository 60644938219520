@import 'style';
@import 'styles/reset';
@import 'styles/parts/index';
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700,900'); // only one usage!!!
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,500,700,800'); // 2-3 usages!!
@import url('https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Quicksand:500');
@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
    font-family: SegoeUI;
    src: local('Segoe UI'),url('./fonts/segoeui.woff') format('woff');
    font-weight: 400
}

html, body {
    background: $body_bg;
    font-family: "Comfortaa", sans-serif;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        overflow: hidden;
    }
}

.font-text {
    font-family: "Muli", sans-serif;
}

.font-search {
    font-family: $font_search;
}

html {
    box-sizing: border-box;
}

img {
    max-width: 100%;
    max-height: 100%;
}

*, *::before, *::after {
    box-sizing: inherit;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.container {
    @include clearfix;
}

.hidden {
    display: none;
}

.relative {
    position: relative;
}

.loading {
    background-color: transparent;
    overflow: hidden;

    & ~ .loading {
        opacity: 0;
    }
}

.loading-helper {
    opacity: 1;
    transform: translateY(0);
    transition: transform 500ms 500ms, opacity 500ms 500ms;
    height: 100%;

    .loading & {
        transform: translateY(50px);
        opacity: 0;

        & + .spinner {
            display: block;
        }
    }

    & + .spinner {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        color: #333333;
    }
}

.border {
    display: block;
    position: absolute;
    overflow: hidden;
    height: 3px;
    bottom: 0;
    left: 0;
    width: 100%;

    &:after {
        content: '';
        display: block;
        width: 100%;
        padding-top: 100%;
        background-image: linear-gradient(to right, currentColor 0%, currentColor 25%, transparent 25%);
        background-size: 4px 100%;
        transform: translateY(-50%) rotate(-45deg);
        transform-origin: 50% 50%;
    }
}

.cube {
    perspective: 30em;
    $cube: &;

    &:hover {
        #{$cube}__part {
            transform: rotateX(90deg);
            visibility: visible;
        }

        #{$cube}__part-hover {
            transform: rotateX(0deg);
        }
    }

    &__part {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-style: preserve-3d;
        transform: rotateX(0deg);
        transform-origin: 50% 50% -24px;
        transition: all 300ms, visibility 250ms;
    }

    &__part-hover {
        transform: rotateX(-91deg);
        visibility: hidden;
    }
}

.icon {
    max-width: 100%;
    max-height: 100%;
}

.route-change-appear {
    transform: translate(0, -100%);
}

.route-change-appear.route-change-appear-active {
    transform: translate(0, 0);
    transition: all .4s ease;
}

.route-change-slide-appear {
    transform: translate(-100%, 0);
}

.route-change-slide-appear.route-change-slide-appear-active {
    transform: translate(0, 0);
    transition: all .4s ease;
}

.lc {
    text-transform: lowercase !important;
}

.capitalize {
    text-transform: capitalize;
}

.tablet-portrait {
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
        display: block !important;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100%;
        z-index: 666999;
    }
}

.track-vertical {
    z-index: 999;
    position: absolute;
    width: 10px !important;
    transition: opacity 200ms;
    opacity: 0;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 5px;
}

.thumb-vertical {
    position: relative;
    z-index: 101;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.2);
    height: 723px;
    transform: translateY(75.6667px);
    transition: background-color 300ms;

    &:hover {
        background-color: rgba(#000, 0.5);
    }
}

.strike {
    text-decoration: line-through;
}

input {
    border-radius: 0;
}

.hhh {
    background: darkorange;
    color: #fff;
    font-weight: inherit;
}

.value-is-up {
    color: #86D004;
}
.value-is-down {
    color: #F44336;
}

.FadePopupAnim {
    &-enter,
    &-appear {
        opacity: 0;
        transition: all ease-in 300ms;
    }

    &-enter-active,
    &-appear-active {
        opacity: 1;
        transition: all ease-in 300ms;
    }

    &-exit-active {
        opacity: 0;
        transition: all ease-out 300ms;
    }
}

::-webkit-scrollbar {
    min-width: 1px;
    min-height: 1px;
}

.clickable {
    cursor: pointer;

    *:not(.clickable) {
        pointer-events: none;
    }
}

.absolute-overlay {
    position: absolute;
    display: block;
    z-index: 2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

div.GenericContent {
    p, ol, ul, h1, h2, h3, h4, h5, h6 {
        font-size: 16px;
        font-family: "Comfortaa", "sans-serif";
    }

    ul {
        list-style: disc inside none;
    }
    ol {
        list-style: decimal inside none;
    }

    p, li {
        margin: 10px 0;
    }
    img {
        width: 100%;
        display: block;
    }

    a {
        color: initial;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.6;
    }
    100% {
        opacity: 0.3;
    }
}

@keyframes appears-top {
    from {
        transform: translateY(30px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}

.appears-top {
    opacity: 0;
    animation: appears-top 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes appears-bottom {
    from {
        transform: translateY(-30px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}

.appears-bottom {
    opacity: 0;
    animation: appears-bottom 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes appears-left {
    from {
        transform: translateX(30px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}

.appears-left {
    opacity: 0;
    animation: appears-left 0.5s ease-in-out;
    animation-fill-mode: forwards;
}

@keyframes appears-right {
    from {
        transform: translateX(-30px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}

.appears-right {
    opacity: 0;
    animation: appears-right 0.5s ease-in-out;
    animation-fill-mode: forwards;
}


@for $i from 1 through 30 {
    .del-#{$i} {
        animation-delay: 150ms * $i;
    }
}