.LockSection {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    backdrop-filter: blur(10px);
    border-radius: 8px;
    font-size: 18px;
    text-align: center;

    &__icon {
        width: 31px;
        height: 31px;
        background: rgba(white, .1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        font-size: 18px;
        font-family: 'DM Sans', sans-serif;
        font-weight: 400;
        line-height: 1.4;
    }

    &__action {
        position: relative;
        font-size: 14px;
        margin-top: 8px;
    }

    &__action-button {
        display: inline-flex;
        width: auto;
        min-width: 0;
        background: none;
        text-decoration: underline;
        padding: 0;
        text-transform: none;
        font-size: inherit;
    }

    &__wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        background: linear-gradient(106.43deg, rgba(50, 54, 56, 0.7) 2.67%, rgba(40, 43, 47, 0.7) 103.27%);
        box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.18);
        border-radius: 8px;
        padding: 10px;
    }

    &__notification {
        min-width: 500px;
        text-align: center;
        bottom: -8px;
        cursor: default;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
@mixin lockedStyles {
    filter: blur(2px);
    user-select: none;
    pointer-events: none;
}

.section-is-locked {
    @include lockedStyles;
}

.child-is-locked {
    position: relative;

    & > *:not(.LockSection) {
        @include lockedStyles;
    }
}