@import "../../../styles/helpers/ie.scss";

.SelectGrid {
    display: grid;
    grid-template-columns: repeat(var(--gridItems), 1fr);
    grid-gap: 8px;
    padding-right: 16px;

    @include onlyIE {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        @include onlyIE {
            width: calc((100% / 6) - 16px);
            margin-right: 16px;
            margin-bottom: 16px;
        }
        .FiltersContent__columns & {
            @include onlyIE {
                width: calc((100% / 3) - 16px);
            }
        }
    }
}

.SelectGridItem {
    background: #283539;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    color: #7EA3AE;
    transition: color 300ms, background-color 300ms, opacity 300ms;
    border-radius: 8px;
    padding: 0 4px;

    &:hover {
        opacity: 0.8;
    }

    &--active {
        background: #5F8794;
        color: #fff;
    }
}