@use 'sass:math';
@import '../../../style';

.mac-details {
    padding: 0 50px 30px;
    min-height: calc(100vh - 133px);

    &__wrapper {
        background-color: #F6F6F6;
        overflow-x: hidden;
    }

    &__outer {
        height: 100vh;
    }

    &__header {
        padding: 15px 50px 15px;
        background: linear-gradient(0deg, rgba(249, 248, 248, 0.5) 101.41%, rgba(246, 243, 243, 0.761539) 102.52%, rgba(186, 183, 183, 0.26) 102.53%, rgba(248, 246, 246, 0.35) 103.5%, #FFFFFF 103.57%), #FFFFFF;
        border-bottom: 1px solid #979797;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        max-width: 600px;
        font-size: 26px;
        line-height: 42px;
        font-weight: 600;
    }

    &__btn-link {
        margin: 0 10px;
        font-family: $font_search;
        font-size: 18px;
        line-height: 24px;
        color: #2D9CDB;
        text-decoration: none;
        text-transform: lowercase;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }

        .is-feature-flag-enabled & {
            text-decoration: underline;
            &:hover{
                text-decoration: none;
            }
        }
    }

    &__options {
        padding: 30px 0 15px;

        &.center {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
    }

    .documents__change-view-outer{
        margin-top: 2px;
    }

    &__main {
        .is-feature-flag-enabled & {
            padding-top: 60px;
        }
    }

    &__button-loading {
        animation: pulse 1200ms infinite ease-in-out;
    }
}

.canvas {
    $root: &;

    &__outer {
        margin: 0 auto;
        position: relative;
        background-color: #2A313A;
        background-position: 0 0;
        background-repeat: no-repeat;
        display: flex;
        .is-feature-flag-enabled & {
            border-radius: 16px;
        }
    }

    &__col {
        flex: 0 0 25%;
        height: 100%;

        &--4-3 {
            padding-top: calc(100% * .75);

            #{$root}__col-name{
                font-size: 22px;
            }
        }

        &--16-9 {
            padding-top: 600px - 70px + 2px;
        }

        &:nth-child(2) {

            #{$root}__word {
                animation-delay: 0s
            }
        }

        &:nth-child(3) {

            #{$root}__word {
                animation-delay: .2s
            }
        }

        &:nth-child(4) {

            #{$root}__word {
                animation-delay: .4s
            }
        }

        &:nth-child(5) {

            #{$root}__word {
                animation-delay: .6s
            }
        }
    }

    &__col-name-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto 20px;
        height: 60px;
        line-height: 1.3;
        color: #fff;
        text-align: center;
        text-transform: lowercase;
        width: 225px;
        max-width: 90%;
        border-radius: 100px;
        box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.4);

        &--inception {
            background-color: rgb(0, 176, 80);
        }

        &--adoption {
            background-color: rgb(251, 59, 21);
        }

        &--proliferation {
            background-color: rgb(176, 75, 217);
        }

        &--ubiquity {
            background-color: rgb(148, 98, 82);
        }

        .is-feature-flag-enabled & {
            $p-inline: 16px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin: 0 auto 20px;
            height: 60px;
            line-height: 1.3;
            text-transform: lowercase;
            width: 250px;
            max-width: 98%;
            border-radius: 12px;
            box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.4);
            padding: 0 $p-inline 0 #{25px + $p-inline};
            background: #fff;
            color: #000;
            transition: background-color 300ms, color 300ms;
            user-select: none;

            &.is-selectable {
                cursor: pointer;
            }

            &.is-selected {
                color: #fff;
                background: #3A4350;

                &:before {
                    border-color: transparent;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgOSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTguMDAwMiAwLjQ5OTkyNEM3LjYwOTY3IDAuMTA5NCA2Ljk3NjUxIDAuMTA5NCA2LjU4NTk4IDAuNDk5OTI0TDMuMjUwMDQgMy44MzU4N0wyLjAwMDA4IDIuNTg1OTFDMS42MDk1NSAyLjE5NTM5IDAuOTc2Mzg2IDIuMTk1MzkgMC41ODU4NjIgMi41ODU5MUMwLjE5NTMzOCAyLjk3NjQzIDAuMTk1MzM3IDMuNjA5NiAwLjU4NTg2MiA0LjAwMDEyTDIuNTM4OTYgNS45NTMyMkMyLjY2MzM0IDYuMDc3NiAyLjgxMjM0IDYuMTYyMzcgMi45NzAyNyA2LjIwNzUyQzMuMzA5NzQgNi4zMDY5NCAzLjY5MTU3IDYuMjIyNzYgMy45NTkzMyA1Ljk1NUw4LjAwMDIgMS45MTQxNEM4LjM5MDcyIDEuNTIzNjEgOC4zOTA3MiAwLjg5MDQ0OSA4LjAwMDIgMC40OTk5MjRaIiBmaWxsPSIjMDAwIi8+Cjwvc3ZnPgo=');
                    background-repeat: no-repeat;
                    background-size: 14px 14px;
                    background-position: 1.5px 2px;
                }
            }

            &.no-checkbox {
                padding-left: $p-inline;
                &:before {
                    display: none;
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                border-radius: 5px;
                border: 2px solid rgba(#555, .4);
                background-color: #fff;
            }
        }
    }

    &__col-title {
        font-size: 18px;
        font-weight: 900;
        text-transform: uppercase;

        .is-feature-flag-enabled & {
            font-size: 18px;
            font-weight: 900;
            transition: color 300ms;
        }

        #{$root}__col-name-wrap--inception:not(.is-selected) & {
            .is-feature-flag-enabled & {
                color: rgb(0, 176, 80);
            }
        }

        #{$root}__col-name-wrap--adoption:not(.is-selected) & {
            .is-feature-flag-enabled & {
                color: rgb(251, 59, 21);
            }
        }

        #{$root}__col-name-wrap--proliferation:not(.is-selected) & {
            .is-feature-flag-enabled & {
                color: rgb(176, 75, 217);
            }
        }

        #{$root}__col-name-wrap--ubiquity:not(.is-selected) & {
            .is-feature-flag-enabled & {
                color: rgb(148, 98, 82);
            }
        }
    }

    &__col-description {
        font-size: 10px;
        letter-spacing: -0.4px;
        font-weight: 300;
    }

    &__word {
        position: absolute;
        max-width: 25%;
        padding: 3.5px 10px;
        line-height: 1em;
        border-radius: 100px;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.4);
        color: #FFF;
        cursor: pointer;

        &:hover {
            background: #f91929 !important;
            z-index: 10 !important;

            & > #{$root}__word-menu {
                display: block;
            }
        }

        &--capitalize {
            text-transform: capitalize;
            font-size: 14px;
            letter-spacing: -0.1px;
        }

        &--lowercase {
            text-transform: lowercase;
        }

        &--uppercase {
            text-transform: uppercase;
        }

        &.animated {
            animation: zoomIn 1s both;
        }
    }

    &__word-text {
        white-space: pre-wrap;
        font-family: 'Muli', sans-serif;
    }

    &__word-menu {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        background: #f2f2f2;
        color: #fff;
        z-index: 99999;
        min-width: 100%;
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
    }

    &__word-menu-item {
        display: block;
        position: relative;
        line-height: 20px;
        padding: 0 5px;
        white-space: nowrap;
        cursor: pointer;
        background: #444;
        text-align: left;
        font-size: 14px;
        border-top: 1px solid #fff;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        box-sizing: border-box;
        text-transform: capitalize;
        text-decoration: none;

        &:first-of-type {
            border-top: 1px solid transparent;
        }

        &:hover, &:active, &:focus {
            outline: none;
            background: #ff7912;
            background: rgba(255, 121, 18, 0.3);
            color: #444;
        }
    }

    &__curve {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
        margin: 0;
        width: 0;
        transition: width 1s linear;
        pointer-events: none;

        &--visible {
            width: 100%;
        }
    }

    &__curve-img {
        position: absolute;
        top: 115px;
        left: 33px;
        width: 731px;
        height: 378px;
        max-width: inherit;
        //background-position: 0 0;
        //background-repeat: no-repeat;
        //background-image: url('../../styles/img/Curve.png');
        //background-size: 100%, 100%;


        &--widescreen {
            top: 115px;
            left: 67px;
            width: 931px;
        }
    }

    &__loading{
        position: relative;
        margin: 0 auto;
        height: 600px;
        width: 800px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.btn {
    &--with-triangle {
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            margin-bottom: -2px;
            margin-left: 5px;
            border: 4px solid transparent;
            border-top: 5px solid #FFF;
        }
    }
}

.btn-drop {
    position: absolute;
    top: 101%;
    left: 0;
    min-width: 100%;
    background-color: #fff;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
    box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.1), 0px 1px 29px rgba(0, 0, 0, 0.23947);

    &__item{
        padding: 10px 10px;
        font-weight: 500;
        line-height: 1;
        color: #000;
        cursor: pointer;
        transition: all .25s;

        &:hover{
            color: #FFF;
            background-color: transparentize(#2D9CDB, .5);
        }
    }
}

@keyframes zoomIn {
    from {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        opacity: 1;
    }
}

.data-table{
    position: relative;
    z-index: 1;

    &__header{
        padding: 40px 0 15px;
    }

    &__word{
        font-weight: bold;
        font-size: 22px;
        color: #000;
        //color: #FB3B15;
        text-transform: uppercase;
    }

    &__word-outer{
        color: #8D8D8D;
    }

    &__content{
        position: relative;
        //z-index: 1;
        padding: 20px;
        border: 1px solid #E8E9EA;
        background-color: #FFF;
    }
}

.mac-table{
    width: 100%;

    &__outer{
        position: relative;
    }

    &__row{
        font-size: 13px;
    }

    &__description-col{
        width: 40%;
    }

    &__price-col{
        width: 100px;
    }

    &__hightlighted {
        background-color: #2DC0E6;
        color: #fff;
    }
}

.collapsibleDiv {
    position: relative;
    min-height: 2.65em;
    overflow: hidden;
    padding-right: 20px;
    line-height: 1.3;
    text-overflow: ellipsis;
    cursor: pointer;

    &--collapsible-mode {
        height: 2.65em;
        &:before {
            content: '\2026';
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            line-height: 15px;
            font-size: 20px;
        }
    }

    &__expanded {
        height: auto;
        &:before {
            display: none;
        }
    }
}

