@import '../../style';
@import "InstantChartPermissionOverlay";
@import "InstantChartReport";
@import "InstantChartMtLink";
@import "InstantChartFlavorOverlay";

.InstantCharts {

    &__wrapper {
        margin-top: 60px;
    }

    &__content {
        box-sizing: border-box;
        width: 100%;
        padding: 20px 30px;
        position: relative;
        z-index: 0;
        height: calc(100vh - 135px);
        min-height: 600px;
    }

    &__left {
        width: size_in_parent(300px, 1300px);
        float: left;
        height: 100%;
        position: relative;

        @include media-max(1440px) {
            width: size_in_parent(250px, 1300px);
        }
    }

    &__right {
        width: size_in_parent(1000px, 1300px);
        float: right;
        padding-bottom: 20px;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F8F8F8;
        box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.3);
        position: relative;
        height: 100%;

        &.loading {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            max-height: 800px;
        }

        @include media-max(1440px) {
            width: size_in_parent(1050px, 1300px);
            float: left;
        }
    }

    &__container {
        max-width: 1300px;
        margin: 0 auto;
        height: 100%;
        position: relative;

        &--top {
            @include media-max(1300px) {
                padding-left: 30px;
            }
        }

        &--blurred {
            filter: blur(3px);
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                text-shadow: 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000, 0 0 5px #000;
                opacity: 0.15;
            }
        }
    }

    &__title {
        font-weight: 300;
        font-size: 24px;
        text-transform: uppercase;
        padding: 25px 0;

        &-divider {
            width: 100%;
            height: 1px;
            box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
            background: #DADADA;
        }
    }

    &__link {
        float: left;
        width: calc(50% - 5px);
        height: 40px;
        background: #27AE60;
        border: none;
        outline: none;
        color: #fff;
        font-size: 13px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        transition: background .3s ease-out;
        @include media-max (1280px) {
            font-size: 10px;
        }
        @include media-max (1100px) {
            font-size: 8px;
        }

        &:hover {
            background: darken(#27AE60, 10);
        }

        &--red {
            background-color: #F72F23;
            margin-left: 6px;
            &:hover {
                background: darken(#F72F23, 10);
            }
        }

        &-icon {
            float: left;
            margin-left: 10px;
            margin-top: 8px;
            margin-right: 5px;
            @include clearfix;
        }

        &-text {
            display: inline-block;
            font-weight: 700;
            font-family: $font_main;
        }
    }

    &__item {
        font-weight: 400;
        font-size: 16px;
        background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border: 1px solid #E8E9EA;
        box-sizing: border-box;
        box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.1);
        padding: 10px 10px 9px;
        width: 100%;
        color: #8D8D8D;
        cursor: pointer;
        transition: background .3s ease-out;
        display: block;
        text-decoration: none;

        &-bold {
            font-weight: 700;
        }

        &:hover {
            background: #f1fbfd;
        }

        &.is-active {
            box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.3);
            border-right: none;
            border-top: none;
            border-bottom: none;
            width: 100%;
            position: relative;
            z-index: 1;
            color: #fff;
            background: #2DC0E6;

            &:hover {
                background: #2DC0E6;
            }

            //&::after {
            //    content: '';
            //    display: block;
            //    position: absolute;
            //    right: -15px;
            //    top: -15px;
            //    bottom: -15px;
            //    width: 15px;
            //    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F8F8F8;
            //    cursor: auto;
            //}

            &:first-child::after {
                top: 0;
            }

            &:last-child::after {
                bottom: 0;
            }
        }
    }

    &__table {
        box-shadow: none;

        & .table__head {
            font-size: 13px;
        }

        &-total {
            position: relative;
            height: 45px;
            margin: -5px 35px 5px 25px;
            border-top: 1px solid #ccc;
            z-index: 1;
            //background: #000;
            //color: #fff;

            &-item {
                padding: 10px 0 10px 5px;
                font-size: 14px;
                text-align: center;
                display: inline-block;

                &:first-child {
                    text-transform: uppercase;
                    text-align: left;
                    width: 44%;
                }

                &:nth-child(2) {
                    width: 28%;
                }

                &:nth-child(3) {
                    width: 28%;
                }
            }
        }
    }

    &__trial-overlay {
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        background: rgba(255, 255, 255, .6);
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background: (255, 255, 255, .7);
        }
        backdrop-filter: blur(5px);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__trial-text {
        font-size: 40px;
        line-height: 1.2;
    }

    &__button {
        display: inline-block;
        margin: 0 2px;
        background-color: transparent;
        border: 1px solid #ffffff;
        border-radius: 6px;
        color: #ffffff;
        cursor: pointer;
        width: 75px;
        height: 24px;
        font-size: 17px;
        line-height: 20px;
        transition: background-color 500ms;

        &:hover {
            background-color: rgba(#fff, 0.3);
        }
    }
}
