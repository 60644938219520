.LocationTile {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    $root: &;

    &:hover {
        #{$root}__background {
            transform: scale(1.1);
        }
    }

    &__background {
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 -100px 50px -10px rgba(black, .9);
        transition: transform 2000ms linear;
    }

    &__name,
    &__info {
        position: relative;
        z-index: 1;
    }

    &__name {
        font-size: 26px;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 8px;
    }

    &__info {
        display: flex;
        margin-bottom: 16px;
    }

    &__info-item {
        font-size: 16px;

        & + & {
            position: relative;
            margin-left: 16px;

            &:after {
                content: '';
                width: 4px;
                height: 4px;
                background: #fff;
                border-radius: 50%;
                position: absolute;
                left: -9px;
                top: 50%;
                margin-top: -2px;
            }
        }
    }
}