.ReportsItem {
    position: relative;
    border: 1px solid #3C4F6A;
    border-radius: 8px;
    background: #25303E;
    cursor: pointer;
    transition: all 300ms ease-in;
    user-select: none;
    $root: &;

    &:hover {
        background: #1C2531;
    }

    &.is-selected {
        border-color: #56CCF2;
        background: #1C2531;
    }

    &.is-disabled {
        pointer-events: none;
        #{$root}__icon {
            opacity: 0.4;
        }

        #{$root}__title,
        #{$root}__description {
            color: #727D8C;
        }
        
    }

    & + & {
        margin-top: 12px;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
        min-height: 100px;
    }

    &__icon {
        width: 50px;
        position: absolute;
        left: 24px;
        top: 24px;
    }

    &__title,
    &__description {
        padding-left: 70px;
    }

    &__title {
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        display: flex;
    }

    &__title-price {
        color: #56CCF2;
        margin-left: 4px;
    }

    &__description {
        margin-top: 8px;
        color: #a4c0e4;
        font-size: 14px;
        line-height: 1.4;
    }

    &__status-icon {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}