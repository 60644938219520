.package-animation-container {
    display: inline-block;

    &--report {
        display: flex;
        align-items: center;
    }

    & svg {
        transition: all 1.2s ease !important;
    }
}
