.ContactsList {
    position: relative;
    width: 100%;

    &__table-social-icon {
        margin-right: 5px;

        &:hover {
            opacity: 0.8;
        }
    }

    &__cell-name {
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;
    }

    &__cell-name-userpick {
        width: 28px;
        height: 28px;
        min-width: 28px;
        margin-right: 8px;
        position: relative;
    }

    &__cell-name-image,
    &__cell-name-initials {
        min-width: 100%;
        min-height: 100%;
        border-radius: 50%;
        margin-right: 8px;
    }

    &__cell-name-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        background-color: #fff;
    }

    &__cell-name-initials {
        background: #1F2328;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
    }

    &__cell-name-truncated {
        max-width: calc(100% - 36px);

        & > span {
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            display: block;
            min-width: 0;
            line-height: 1.4;
        }
    }

    &__add-panel {
        background: #1F2328;
        color: #56CCF2;
        font-size: 14px;
        font-weight: 700;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 24px;
        border-bottom: 1px solid #323840;
    }

    &__add {
        cursor: pointer;
        padding: 8px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__table {
        @media screen and (max-width: 1024px) {
            overflow-x: auto;
        }
        & > div {
            @media screen and (max-width: 1024px) {
                min-width: 992px;
            }
        }
    }
}