.ButtonGrid {
    @import "../../../../ChainPage.helpers.styles";

    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    width: 100%;

    &__header {
        background: color("page-bg");
        position: relative;
        z-index: 1;
        padding-left: 24px;
        padding-right: 24px;
        color: #2EAFD9;
        font-size: 16px;
        cursor: default;
    }

    &__header-value {
        font-size: 20px;
        font-weight: 700;
        margin-right: 4px;
    }

    &__button {
        &.is-head {
            position: relative;
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                top: 50%;
                height: 1px;
                left: 0;
                background: color("border");
            }
        }

        &.is-small {
            min-width: calc(50% - 4px);
            height: 100px;
            margin-bottom: 8px;
        }

        &.is-large {
            width: 100%;
            height: 50px;
            display: flex;
            margin-top: 8px;
            position: relative;
            order: 5;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: -8px;
                width: 100%;
                height: 1px;
                background: color("border");
            }
        }
    }
}