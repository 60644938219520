.mac-list {
    padding: 0 50px 120px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    &:after {
        content: '';
        flex: auto;
        //width: 100%;
        //width: calc(33.333% - 16px);
        //height: 0;

        @media screen and (max-width: 1600px) {
            //width: calc(25% - 20px);
        }
    }
}
