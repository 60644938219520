@import '@styles';
$food_profile_content_widget_height: 310px;
$food_profile_content_widget_header_height: 54px;

.FoodProfilePairedItems {
    width: size_in_parent(1230px - 245px - 315px - 240px - 30px, 1230px);

    &__loader {
        position: relative;
        width: 100%;
        height: $food_profile_content_widget_height - $food_profile_content_widget_header_height;
    }

    &.is-opened {
        width: 100%;
        min-height: 310px;
    }
}