@import '../../../style';

.document-item {
    position: relative;
    max-height: 172px;
    margin-bottom: 24px;
    width: 100%;
    z-index: 1;
    transition: all 250ms;
    $root: &;

    &:hover, &--hovered {
        z-index: 2;
        transition: z-index 0s 0s;

        #{$root}__inner {
            box-shadow: 0 1px 29px transparentize(#000, .76);
        }

        #{$root}__btns-block {
            height: 19px;
            padding-top: 20px;
            padding-bottom: 12px;
            border-top: 1px solid #000;
            opacity: 1;
            //transition: opacity .25s;
        }

        #{$root}__text {
            height: auto;
            max-height: inherit;
        }

        #{$root}__link {
            opacity: 1;

            @media screen and (max-width: 1700px) {
                width: 63px;
            }

            &--no-float {
                width: 100%;
            }
        }
    }

    &__container {
        width: 100%;
        height: 100%;
    }

    &__img-container {
        width: 100%;
        border-radius: 5px 5px 0 0;
        position: relative;
        padding-top: 170px;
        overflow: hidden;

        & img {
            position: absolute;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            min-height: 100%;
            min-width: 100%;
            top: 0;
            left: 50%;
            max-height: none;
            max-width: none;
            transform: translate(-50%, 0);
        }

        &--flex {
            padding-top: 0;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__info-container {
        height: calc(100% - 170px);
        width: 100%;
        border-radius: 0 0 5px 5px;
        border-top: 1px solid #E8E9EA;
        padding: 10px 10px 5px;
        position: relative;
    }

    &__info-title {
        display: flex;
        align-items: center;
        height: 21px;
    }

    &__info-title-text {
        font-family: $font_main;
        font-size: 19px;
        width: calc(100% - 24px);
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        overflow: hidden;
    }

    &__type-icon {
        width: 19px;
        height: 19px;
        margin-right: 5px;
        transform: translateY(-2px);
    }

    &__info-footer-wrap {
        position: relative;
    }

    &__btn-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -10px;
        top: -5px;
        bottom: 0;
        right: -10px;
        z-index: 2;
        transition: all 200ms;

        #{$root}__btn {
            margin: 0 5px;
        }

        @include IE10plus {
            background: rgba(255, 255, 255, 0.90);
        }
    }

    &__info-footer {
        z-index: 1;
        transform: translateY(-4px);
        transition: filter 200ms;
        will-change: filter;
        backface-visibility: hidden;
    }

    &__info-project {
        margin-bottom: -5px;
        margin-top: 5px;
        font-size: 10px;
        width: 100%;
    }

    &__info-bottom {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        line-height: 12px;
        color: #514F4F;
    }

    &__inner {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        min-height: 172px;
        padding: 22px 22px;
        background-color: #fff;
        border: 1px solid #E8E9EA;
        text-decoration: none;
        //transition: box-shadow .25s, width .25s;
    }

    &__image-block {
        @media screen and (max-width: 1200px) {
            width: 150px;
        }

        &--no-thumbnail {
            //width: 150px;
        }

        width: 193px;

        &, &--no-thumbnail {
            svg {
                width: 100%;
                height: 100%;
                max-height: 120px;
                max-width: 193px;

                @media screen and (max-width: 1200px) {
                    max-width: 150px;
                }
            }
        }
    }

    &__image {
        max-height: 120px;
        //max-height: 154px;
    }

    &__content {
        width: calc(100% - 193px);
        padding-left: 22px;

        @include media-max(1200px) {
            width: calc(100% - 150px);
        }
    }

    &__title {
        margin-top: 5px;
        font-size: 26px;
        font-weight: bold;
        line-height: 1.25;
        text-transform: uppercase;
        color: #000;
        overflow: hidden;

        @media screen and (max-width: 1700px) {
            font-size: 22px;
        }
    }

    &__sub-title {
        font-family: 'Lato', Sans-Serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.2;
        color: #514F4F;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            margin-top: 2px;
            background-color: #000;
            //transition: width .25s;
        }
    }

    &__project-link {
        text-decoration: none;
        transition: color .1s;

        &:hover {
            color: #2F80ED;
        }

        &--list {
            color: #EA6F01;
            font-size: 12px;
            white-space: nowrap;
            word-break: break-word;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            display: inline-block;
            height: 14px;
            line-height: 14px;
        }
    }

    &__link {
        float: right;
        font-family: 'Lato', Sans-Serif;
        font-weight: normal;
        font-size: 14px;
        color: #828282;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        cursor: pointer;
        opacity: 0;
        transition: color .25s;
        //transition: all .35s;

        &:hover {
            color: #2F80ED;
            text-decoration: underline;
        }

        @media screen and (max-width: 1700px) {
            font-size: 12px;
        }

        &--no-float {
            float: none;
            display: block;
            font-size: 16px;
            text-align: right;
            line-height: 20px;
            margin-top: -15px;
            margin-bottom: 5px;
            text-transform: uppercase;
        }
    }

    &__text {
        box-sizing: content-box;
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 22px;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        line-height: 1.2;
        color: #7E7E7E;
        //transition: height .25s, padding .25s;

        .font-text {
            font-family: 'Lato', sans-serif;
        }
    }

    &__btns-block {
        display: flex;
        box-sizing: content-box;
        justify-content: flex-end;
        align-items: flex-start;
        height: 0px;
        padding-top: 0px;
        width: 100%;
        //max-width: calc(100% - 213px);
        border-top: 0px solid #000;
        font-size: 12px;
        line-height: 1;
        text-align: right;
        overflow: hidden;
        opacity: 0;
        //transition: height .25s, padding .25s, border .25s, opacity 0s;

        @media screen and (max-width: 1700px) {
            min-width: 100%;
        }

        &--expanded {
            float: right;
        }
    }

    &__btn {
        margin: 0 8px;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        color: #4F4F4F;
        text-transform: uppercase;
        text-align: center;
        background-color: transparent;
        border: none;
        transition: color .1s, opacity .1s;

        &:hover {
            color: #2F80ED;
            cursor: pointer;

            .btn-icon-svg {
                fill: #2F80ED;
            }

            #{$root}__btn-icon--hovered {
                display: inline-block;
            }
            #{$root}__btn-icon--not-hovered {
                display: none;
            }
        }

        a {
            text-decoration: none;
        }

        &--back {
            position: relative;
            margin-left: 10px;
            background-color: #2F80ED;
            color: #fff;
            box-shadow: 0 -10px 0 10px #2F80ED, 0 10px 0 10px #2F80ED;
            transition: all .25s;

            .btn-icon-svg {
                fill: #FFF;
            }

            &:hover {
                background-color: #fff;
                color: #2F80ED;
                box-shadow: 0 -10px 0 10px #FFF, 0 10px 0 10px #FFF;

                .btn-icon-svg {
                    fill: #2F80ED;
                }
            }
        }

        &--blue {
            color: #2F80ED;
        }

        &--download {
            transition: all .1s;

            .btn-icon-svg {
                fill: #2F80ED;
            }

            &:hover {
                color: orange;

                .btn-icon-svg {
                    fill: orange;
                }
            }
        }
    }

    &__btn-link {
        text-decoration: none;
    }

    &__btn-text {

    }

    &__btn-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        max-width: 25px;
        max-height: 16px;

        &--hovered {
            display: none;
        }
    }

    &--in-list {
        width: calc((100% - 55px) / 6);
        height: 240px;
        max-height: 100vh;
        overflow: hidden;
        border: 1px solid #e8e9ea;
        border-radius: 5px;
        background-color: #fff;
        margin-bottom: 20px;
        margin-right: 10px;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.04);

        &:nth-child(6n) {
            margin-right: 0;
        }

        @include media-max(1750px) {
            width: calc((100% - 45px) / 5);

            &:nth-child(6n) {
                margin-right: 10px;
            }

            &:nth-child(5n) {
                margin-right: 0;
            }
        }

        @include media-max(1500px) {
            width: calc((100% - 35px) / 4);

            &:nth-child(6n) {
                margin-right: 10px;
            }

            &:nth-child(5n) {
                margin-right: 10px;
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }

        @include media-max(1280px) {
            width: calc((100% - 25px) / 3);

            &:nth-child(6n) {
                margin-right: 10px;
            }

            &:nth-child(5n) {
                margin-right: 10px;
            }

            &:nth-child(4n) {
                margin-right: 10px;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

        &:hover:not(.no-blur) {
            box-shadow: 0 1px 29px rgba(0, 0, 0, 0.24);
            #{$root}__btn-wrap {
                opacity: 1;
                visibility: visible;
            }

            #{$root}__info-footer {
                filter: blur(5px);
            }

            #{$root}__project-link--list {
                color: #000;
            }
        }

        #{$root}__btn-text {
            display: inline-block;
            font-size: 10px;
        }

        .btn-icon-svg {
            width: 13px;
            height: 13px;
        }
    }

    &--single, &--in-table {
        height: auto;

        &:hover {
            z-index: 0;

            #{$root}__inner {
                box-shadow: none;
            }
        }

        #{$root}__btns-block {
            height: 19px;
            padding-top: 20px;
            border-top: 1px solid #000;
            opacity: 1;
        }

        #{$root}__sub-title {

            &:after {
                width: 100%;
            }
        }

        #{$root}__text {
            height: auto;
            padding-top: 22px;
            padding-bottom: 22px;
        }

        #{$root}__link {
            opacity: 1;
        }
    }

    &--single {
        max-height: inherit;

        #{$root}__image {
            max-height: 100%;
        }

        #{$root}__image-block--big {
            width: 40%;
        }
        #{$root}__image-block--no-thumbnail {
            width: 40%;
        }

        #{$root}__content {
            width: 60%;
        }

        #{$root}__btns-block {
            height: auto;
        }

        &:hover {

            #{$root}__btns-block {
                height: auto;
                padding-bottom: 0;
            }
        }

        #{$root}__btn {
            display: inline-block;
            vertical-align: middle;
            font-size: 11px;
        }

        #{$root}__btn-text {
            display: inline-block;
        }
    }

    &--hovered {
        z-index: 4;

        #{$root}__btns-block {
            display: block;
            text-align: left;
        }

        #{$root}__btn {
            margin: 0 10px;
            text-decoration: none;
        }
    }

    &--in-table, &--in-table:hover {
        overflow: visible;
        height: 100%;
        width: 100%;
        margin: 0;

        #{$root}__btn {
            font-size: 11px;
            flex-basis: inherit;
        }

        #{$root}__btns-block {
            width: 100%;
            height: 100%;
            padding-top: 0;
            padding-bottom: 0;
            overflow: visible;
            align-items: center;
            padding-top: 0;
            border-top: none;
            opacity: 1;
        }

        #{$root}__btn-text {

        }
    }

    &--in-table {

        #{$root}__btn {
            opacity: 0;
            transition: opacity .1s;
        }

    }
}

.documents-table-row {

    &:hover {
        .document-item {

            &__btn {
                opacity: 1;
            }
        }
    }
}

.documents-table-row__wrapper--active {
    .document-item {

        &--in-table, &--in-table:hover {
            height: 220px;
        }
    }
}
