.FoodProfileLocalIndices {
    width: size_in_parent(315px, 1230px);

    &.is-opened {
        width: 100%;
        min-height: auto;
    }

    &__logo {
        width: 200px;
        position: absolute;
        right: 0;
        bottom: 10px;
        height: 35px;
        transition: all 500ms;
        cursor: pointer;

        svg {
            width: 100%;
            height: 100%;
        }

        &:hover {
            opacity: 0.7;
        }
    }
}