@import '@styles';

.FoodProfileVersatility {
    position: relative;
    overflow: hidden;
    width: size_in_parent(240px, 1230px);

    &__section-wrapper {
        position: relative;
        min-height: 236px;
    }

    &__section {
        padding: 10px;
        box-sizing: border-box;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        &--small {
            text-align: left;
            padding: 10px 12px;

            @include media-max(1280px) {
                padding: 6px 12px;
            }
        }

        &:nth-child(2) {
            border-top: 1px solid #CFCFCF;
        }
    }

    &__info {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: calc(100% - 54px);
        background: rgba(#2C2C2C, .82);
        color: #ffffff;
        font-size: 14px;
        line-height: 14px;
        font-weight: 300;
        transition: all 500ms;

        b {
            font-weight: 700;
        }

        &--show {
            transform: translateY(-100%)
        }
    }

    &__title {
        display: inline-block;
        font-size: 16px;
        line-height: 100%;
        color: #303030;
    }

    &__number {
        margin-left: 10px;
        font-weight: 300;
        font-size: 58px;
        line-height: 100%;
        color: #4A4A4A;
        text-shadow: 0 0 0 #143897;
    }
}