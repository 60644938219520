@import '../../style';

.homepage{
    &__content {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: relative;
        overflow: hidden;
        z-index: 1;
        &--next {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }

        &--out {
            opacity: 0;
            transition: opacity 1000ms;
        }
        &--in {
            opacity: 1;
            transition: opacity 1000ms;
        }
    }

    &__get-snap-btn-container {
        position: absolute;
        z-index: 10;
        top: size(24px);
        right: size(24px);
        border-radius: size(4px);

        &--border {
            border: 1px solid #50555C;
        }
    }

    &__get-snap-btn {
        $base: &;
        width: 100%;
        padding: size(16px);
        text-align: left;
        background-color: #0C0C0C;
        border-radius: size(4px);
        border: none;
        cursor: pointer;

        &:hover {
            #{$base}-text {
                font-weight: bold;
                color: transparent;
            }
        }

        &--border {
            border: 1px solid #50555C;
        }
    }

    &__get-snap-btn-text {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: size(17px);
        font-weight: bold;
        font-size: size(24px);
        line-height: 48px;
        color: #FFFFFF;
        background-clip: text;
        -webkit-background-clip: text;
        background-image: linear-gradient(151.97deg, #1047FB 10.06%, #9B51E0 31.6%, #E051B8 54.53%, #F0771F 76.76%);
        //background-image: linear-gradient(151.97deg, transparent 10.06%, transparent 31.6%, transparent 54.53%, transparent 76.76%);
        transition: background-image 200ms, color 200ms;
    }

    &__get-snap-btn-icon {
        display: inline-block;
        vertical-align: middle;
    }

    &__get-snap-btn-notice {
        display: block;
        padding: size(16px);
        font-weight: bold;
        font-size: size(16px);
        line-height: size(24px);
        color: #FFF;
        background-color: #0C0C0C;
        border-bottom-left-radius: size(4px);
        border-bottom-right-radius: size(4px);
        box-shadow: 0 size(-4px) 0 #0C0C0C;
        user-select: none;
        opacity: 0;
        transition: opacity 200ms;

        &--visible {
            opacity: 1;
        }
    }
}

.homepage__pause {
    width: 50px;
    height: 50px;
    z-index: 3;
    position: absolute;
    bottom: 0;
    right: 0;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 18px;
        width: 4px;
        height: 16px;
        background: #fff;
        box-shadow: 10px 0 0 0 #fff;
        transition: all 500ms;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 18px;
        height: 0;
        width: 0;
        border-style: solid;
        border-color: transparent;
        border-width: 8px 14px;
        border-left-color: #fff;
        transition: all 500ms;
        opacity: 0;
    }

    &--pause {
        &:after {
            opacity: 1;
        }
        &:before {
            opacity: 0;
        }
    }
}
