@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import '../../style';

.page-content {
    @include clearfix;
    z-index: 1;
    height: calc(100vh - #{$platform-navigation-height});
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    transition: z-index 0s 500ms;

    &--collapsed {
        width: 100%;
        float: right;
    }

    &--homepage {
        border-top: 0;
        margin-top: 0;
        height: calc(100vh - #{$platform-navigation-height});
    }

    &--over {
        z-index: 3;
        transition: z-index 0s;
    }

    &__gradient-delimiter {
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            height: 1700px;
            bottom: 0;
            left: -30px;
            right: -30px;
            background-repeat: no-repeat;
            background-image: linear-gradient(180deg, #CFCFCF 0%, #eeeeee 100%), linear-gradient(0deg, rgba(186,183,183,0.26) 0%, #FFFFFF 100%);
            background-size: 100% 2px, 100% 100%;
            background-position: center bottom, center top;
        }
    }
}
