@import '../../style';

.packages {
    outline: none;

    &:focus {
        outline: none;
    }

    &__header-wrapper {
        box-shadow: 0 0 3px 0 #000;
        border-bottom: 1px solid #DADADA;
    }

    &__content {
        box-sizing: border-box;
        width: 100%;
        padding: 0 30px 40px;
        margin-top: 30px;
    }

    &__content-scroll {
        background: #fff;
    }

    &__container {
        @include clearfix;
        width: 1300px;
        margin: 0 auto;
        position: relative;

        @include media-max(1200px) {
            width: 980px;
        }
    }

    &__header {
        padding: 30px 0;
    }

    &__title {
        font-weight: 300;
        font-size: 24px;
        text-transform: uppercase;
    }

    &__button {
        padding: 8px 10px;
        text-transform: uppercase;
        font-size: 12px;
        color: #fff;
        text-align: center;
        border: none;
        outline: none;
        cursor: pointer;
        margin-left: 10px;
        border-radius: 3px;
        transition: all 0.3s ease;

        &:first-child {
            margin-left: 0;
        }

        &--download {
            background: #27AE60;
            text-decoration: none;
        }

        &--send {
            background: #0F81DE;

            &:hover {
                background: lighten(#0F81DE, 10%);
            }
        }
    }

    &__list {
        float: left;
        width: 450px;

        @include media-max(1200px) {
            width: 340px;
        }
    }

    &__list-item {
        border: 1px solid #E8E9EA;
        padding: 15px 20px;
        color: #000;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: all 0.2s ease;
        background: #fff;

        &--active {
            transform: translateY(-5px);
            box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.1), 0 1px 29px rgba(0, 0, 0, 0.24);
        }
    }

    &__created-date {
        font-size: 10.8px;
        color: #8D8D8D;
    }

    &__files-counter {
        color: #8D8D8D;
    }

    &__active-marker {
        color: #C3272F;
        margin-left: 5px;
    }

    &__detail-info {
        float: right;
        width: 840px;
        box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.1), 0 1px 29px rgba(0, 0, 0, 0.24);
        margin-left: 9px;

        @include media-max(1200px) {
            width: 630px;
        }
    }
}

.header-packages {
    $root: &;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    position: relative;

    &--active {
        & .circle-button {
            background: #197BEE;
        }
    }

    &__counter {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #E85959;
        border: 1px solid #E85959;
        color: #fff;
        font-size: 13px;
        border-radius: 50%;
        min-width: 20px;
        height: 20px;
        z-index: 30;
        text-align: center;
        line-height: 20px;
        right: -5px;
        bottom: -4px;
    }

    &__popup {
        width: 100%;
        &:after {
            content: '';
            position: absolute;
            display: block;
            left: 306px;
            top: -11px;
            width: 30px;
            height: 15px;
            background: transparent;
        }
    }

    &__popup-header {
        background: #197BEE;
        padding: 10px 20px;
        color: #fff;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
    }

    &__header-text {
        text-align: right;
        font-size: 10px;
        line-height: 1.2;
        width: 150px;
    }

    &__packages-list-title {
        padding-left: 20px;
        padding-top: 1px;
        font-size: 12px;
        color: #1A0706;
        background-color: #E0E0E0;
        text-transform: uppercase;
    }

    &__packages-list {
        max-height: 300px;
        overflow: auto;
    }

    &__package-info {
        max-width: 320px;
        line-height: 1.2;
        overflow: hidden;
    }

    &__package-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 180px;
        visibility: hidden;
    }

    &__packages-list-item {
        border-top: 1px solid #E0E0E0;
        background: #fff;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        transition: all 0.2s ease;
        text-decoration: none;

        &:first-child {
            border-top: 0;
        }

        &:hover {
            background: #F4F9FF;

            & #{$root}__button--remove {
                display: block;
            }
        }
    }

    &__package-name {
        font-size: 16px;
        color: #1A0706;
    }

    &__package-type {
        font-size: 14px;
        color: #ABABAB;
    }

    &__button {
        border: 0;
        outline: 0;
        background: transparent;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        padding: 0;
        margin: 0;
        margin-left: 10px;
        line-height: 0;

        &:first-child {
            margin-left: 0;
        }

        &--download {
            text-decoration: none;
        }

        &--remove {
            display: none;
            border: 0;
            background-color: transparent;
            position: relative;
            padding-right: 10px;
            cursor: pointer;

            &::before, &::after {
                content: '';
                position: absolute;
                top: 18%;
                margin-top: -5px;
                margin-left: 0px;
                left: 50%;
                width: 2px;
                height: 9px;
                transform-origin: 50% 50%;
                background: #000;
            }

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }

        &:hover #{$root}__button-icon {
            fill: #2F80ED;
        }
        &:hover #{$root}__button-text {
            color: #2F80ED;
        }
    }

    &__button-icon {
        fill: #4F4F4F;
        line-height: 1;

        &--table {
            vertical-align: middle;
            margin-right: 5px;
        }
    }

    &__button-text {
        color: #4F4F4F;
        text-transform: uppercase;
        font-size: 10px;
        margin-top: 5px;
        line-height: 1;
    }

    &__footer {
        padding: 5px 20px;
        background: #0C0C0C;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    &__link-button {
        border: 0;
        outline: 0;
        background: transparent;
        color: #fff;
        font-size: 12px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &--underline {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        &--package-list-item {
            color: #8E8E8E;
            text-decoration: none;
            margin-left: 15px;
            padding: 0;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &__go-to-packages {
        text-align: center;
        background: #595858;
        border-radius: 0 0 6px 6px;
        padding: 5px;
    }

    &__go-to-packages-link {
        text-decoration: none;
        text-transform: lowercase;
        font-size: 12px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__package-name-input {
        border: 0;
        outline: 0;
        background: transparent;
        font-size: 12px;
        color: #fff;
        width: 250px;

        &::placeholder {
            color: #fcfcfc;
        }

        &::selection {
            color: #000;
            background: #fff;
        }

        &--invalid {

            &::placeholder {
                color: #ff0e0c;
            }
        }
    }
}

.package-info {
    $root: &;

    &__name {
        font-weight: bold;
        font-size: 22px;

    }

    &__section {
        @include clearfix;
        border-top: 1px solid #CFCFCF;
        padding: 20px;

        &:first-child {
            border-top: 0;
        }

        &--emails {
            padding: 0;
        }
    }

    &__main-info {
        float: left;
        max-width: 350px;
    }

    &__controls {
        float: right;

        @include media-max(1200px) {
            float: none;
            clear: both;
        }
    }

    &__creator-info {
        color: #848484;
        font-size: 16px;
        font-family: Muli, san-serif;
    }

    &__section-header {
        font-size: 16px;
        font-family: Muli, sans-serif;
        text-transform: uppercase;
        color: #000;
        font-weight: bold;
    }

    &__active-controls {
        margin-bottom: 10px;
    }

    &__author {
        color: #333;
    }

    &__action {
        display: inline-block;
        color: #4f4f4f;
        transition: all 300ms;
        margin-left: 30px;
        font-size: 12px;
        line-height: 18px;
        cursor: pointer;
        text-decoration: none;
        text-align: center;

        &:hover {
            color: #2D9CDB;
            #{$root}__icon {
                fill: #2D9CDB;
            }
        }
    }

    &__icon {
        fill: #4f4f4f;
        transition: all 300ms;
        margin-right: 10px;
        vertical-align: top;
    }
}
