.MenuExamples {
    @import "../../../ChainPage.helpers.styles";
    $root: &;

    position: relative;

    &__grid {
        display: flex;
    }

    &__column {
        & + & {
            margin-left: 24px;
        }
    }

    &__item {
        margin-bottom: 24px;
    }

    &__examples-tab {
        overflow: hidden;
    }

    &__footer {
        background: color("page-bg");
        position: relative;
        z-index: 1;
        text-align: right;
    }

    &__footer-action {
        color: color("link");
        display: inline-flex;
        width: auto;
        text-transform: none;
        font-weight: 700;
        font-size: 13px;
        background: none;
        text-decoration: underline;
        min-width: 0;

        &:hover {
            text-decoration: none;
        }
    }
}
