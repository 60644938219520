.component-notification {
    position: fixed;
    top: 66px;
    right: 75px;
    padding: 4px 20px;
    font-family: 'Lato', Sans-Serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.2;
    color: #FFF;
    background-color: #27AE60;
    border: 1px solid #219653;
    border-radius: 6px;
    //transform: translateX(-50%);
    animation: notification_appears 500ms;
    z-index: 10;

    &.visible {
        box-shadow: 0px 0px 8px #219653;
        //outline: 4px solid red;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }
}

@keyframes notification_appears {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}