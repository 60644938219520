.Spinner {
    display: flex;
    align-items: center;
    justify-content: center;

    &--full {
        z-index: 100;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    @keyframes pow {
        0% {
            opacity: .7;
            transform: scale(1);
        }

        25% {
            opacity: 0.5;
            transform: scale(1.1);
        }

        100% {
            opacity: .7;
            transform: scale(1);
        }
    }

    &__logo {
        width: 200px;
        height: 200px;
        animation: pow 2s ease-in-out infinite;
    }
}