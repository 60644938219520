.TabList {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__link {
        font-family: "DM Sans", sans-serif;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        font-size: 16px;
        line-height: 1;
        padding-bottom: 5px;
        text-decoration: none;
        background: none;
        transition: all 300ms ease-in;
        color: #676d75;
        text-transform: none;
        margin-left: 10px;
        margin-right: 10px;

        @media screen and (max-width: 1500px) {
            margin-left: 0;
            margin-right: 0;
        }

        @media screen and (max-width: 1300px) {
            padding-left: 5px;
            padding-right: 5px;
        }

        &--active,
        &:hover {
            color: #ffffff;
        }

        &--active {
            border-bottom-color: #56ccf2;
        }
    }
}
