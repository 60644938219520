.InstantChartFlavorOverlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 90px;
    bottom: -20px;
    right: 50px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
    z-index: 10;
    backdrop-filter: blur(7px);
    text-align: center;
    padding: 10px;
    $root: &;
    color: #fff;

    &.is-small {
        font-size: 14px;
        line-height: 16px;
    }

    &.is-minimal{
        font-size: 11px;
        line-height: 12px;

        br {
            display: none;
        }

        #{$root}__action {
            margin-top: 0;
            font-size: 11px;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        * {
            margin: 0;
        }
    }

    &__icon {
        margin-left: 15px;
    }
}