//Sizes

$font_main: 'Comfortaa', 'Source Sans Pro', sans-serif;
$font_text: 'Muli', 'Source Sans Pro', sans-serif;
$font_search_new: 'Quicksand', 'Source Sans Pro', sans-serif;
$font_search_new_2: 'Work Sans', 'Source Sans Pro', sans-serif;
$font_search: 'Helvetica Neue', 'Source Sans Pro', sans-serif;
$font_search_new_2: 'Work Sans', 'Source Sans Pro', sans-serif;

$header_height: 60px;
$side_bar_width: 190px;
$side_bar_width_in_collapsed: 75px;
$side_bar_height: calc(100vh - #{$header_height});
$content_height: calc(100vh - #{$header_height});
$content_width: calc(100vw - #{$side_bar_width});
$content_width_in_collapsed: calc(100vw - #{$side_bar_width_in_collapsed});

$search_results_widget_width: 375px;
$search_results_widget_gutter: 25px;

//Colors
$white: #fff;
//$body_bg: #FAFAFA;
$body_bg: #fcfcfc;
$header_bg: #000;
$header_clr: #fff;
$sidebar_bg: #000;
$sidebar_clr: #fff;
$sidebar_menu_item_clr: #484848;
$search_open_button_clr: #32c1e9;

$mtd_chart_bg: #235AE4;
$mtd_chart_point_bg: #8EBCED;
$mtd_chart_point_hover: #FD8B60;
$chains-primary: #e8e9ea;
/* Mask */
