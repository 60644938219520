.ChartTooltipMultiline {
    $root: &;
    $background: #3D3D3D;
    $accent: #FD8B60;
    $border: #575656;
    $shift: 35px;

    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    font-family: 'Comfortaa', sans-serif;
    font-size: 12px;
    line-height: 20px;
    transition: transform 300ms;

    &__inner {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        min-width: 50px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.16);
        transform: translate(-50%, -50%);
        background: $background;
        color: #fff;
        padding: 8px;
        transition: transform 300ms;

        &.p-top {
            transform: translate(-50%, calc(-100% - #{$shift}));

            &.a-right {
                transform: translate(calc(-100% + #{$shift}), calc(-100% - #{$shift}));
            }
        }

        &.p-bottom {
            transform: translate(-50%, #{$shift});

            &.a-right {
                transform: translate(calc(-100% + #{$shift}), #{$shift});
            }
        }
    }

    &__arrow {
        $width: 10px;
        $height: 10px;
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        margin-left: -$width;
        margin-top: -$height;
        border-width: $height $width $height $width;
        border-style: solid;
        border-color: transparent;
        transition: transform 300ms, border-color 200ms;

        &.p-top {
            border-top-color: $background;
            transform: translateY(-#{$shift - $height + 2px});
        }

        &.p-bottom {
            border-bottom-color: $background;
            transform: translateY(#{$shift - $height + 2px});
        }
    }

    &.is-hidden {
        display: none;
    }

    &__header {
        border-bottom: 1px solid $border;
        margin-bottom: 2px;
    }

    &__cell {
        width: 35px;
        margin-left: 15px;
        padding: 0 2px;

        #{$root}__header & {
            text-align: center;
        }

        &:first-child {
            text-align: right;
            width: 100px;
            margin-left: 0;
        }

        &:last-child {
            margin-right: 8px;
        }
    }

    &__row {
        display: flex;
        margin-bottom: 1px;
        height: 18px;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.75);

        &:nth-child(2n + 1) {
            background: rgba(#000, 0.2);
        }

        &:only-child {
            background: transparent;
        }

        &.is-active {
            color: #fff;
            //color: $accent;
            //background: rgba($accent, 0.2);
            font-weight: 900;
        }
    }
}
