@import '@styles';

.FoodProfilePipelineMenu {
    position: relative;
    padding-top: 30px;
    $root: &;

    &:hover {
        z-index: 9;
    }

    &__item {
        display: inline-block;
        position: relative;
        width: 105px;
        text-align: center;
        color: #fff;
        padding-top: 20px;
        text-transform: uppercase;
        font-size: 10px;
        transition: all 300ms;
        vertical-align: top;
        cursor: pointer;

        @include media-max(1400px) {
            width: 80px;
            font-size: 9px;
        }

        &:hover {
            &:before {
                background: #fff;
            }

            #{$root}__hover-popup {
                opacity: 1;
                visibility: visible;
            }
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            background: $mtd_chart_bg;
            left: 50%;
            margin-left: -5px;
            top: 0;
            z-index: 2;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            width: 100%;
            right: 50%;
            top: 4.5px;
            background: #F4F4F4;
            opacity: .5;
            transition: all 300ms;
            z-index: 1;
        }

        &:first-child {
            &:after {
                display: none;
            }
        }

        &.is-active {
            font-size: 15px;

            @include media-max(1400px) {
                font-size: 13px;
            }

            &:hover {
                &:before {
                    background: currentColor;
                }
            }

            &:before {
                background: currentColor;
                width: 22px;
                height: 22px;
                margin-top: -5px;
                margin-left: -11px;
            }
        }
    }

    &__text {
        color: #fff;
    }

    &__hover-popup {
        //display: none;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        padding: 15px;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 170px;
        background: #3d3d3d;
        color: #fff;
        text-align: left;
        text-transform: none;
        font-size: 12px;
        line-height: 16px;

        &:before {
            content: '';
            display: block;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-width: 8px;
            border-style: solid;
            border-color: transparent;
            border-bottom-color: #3d3d3d;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 100%;
            width: 100%;
            left: 0;
            height: 10px;
        }
    }

    &__other-list {
        margin-top: 15px;
    }

    &__other-item {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: capitalize;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}