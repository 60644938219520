.ScrollbarThumb {
    cursor: pointer;
    background-color: #404D5B;
    transition: background-color 300ms ease;

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);

    }
}

.ScrollbarTrack {
    &.is-horizontal {
        right: 2px;
        left: 2px;
        bottom: 0;
        height: 16px !important;
        display: flex;
        align-items: center;

        .ScrollbarThumb {
            height: 6px !important;
            transition: height 300ms ease;
        }

        &:hover {
            .ScrollbarThumb {
                height: 16px !important;
            }
        }
    }

    &.is-vertical {
        right: 0;
        bottom: 2px;
        top: 2px;
        width: 24px !important;

        .ScrollbarThumb {
            width: 6px !important;
            transition: width 300ms ease;
            margin-left: auto;
            margin-right: auto;
        }

        &:hover {
            .ScrollbarThumb {
                width: 16px !important;
            }
        }
    }
}

.CustomScrollbarsVirtualList {
    &__view {
        //padding-right: 24px;

        & > div {
            position: relative;
        }
    }

    &__track {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 8px;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 0;
            width: 1px;
            height: 100%;
            margin-left: -0.5px;
            background: #253240;
        }
    }

    &__thumb {
        background: #404D5B;
        cursor: pointer;
    }

    &__thumb-decor {
        width: 4px;
        height: 2px;
        border-radius: 16px;
        background: #131921;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -2px;
        margin-top: -1px;

        &:after,
        &:before {
            content: '';
            position: absolute;
            left: 0;
            width: 4px;
            height: 2px;
            border-radius: 16px;
            background: #131921;
        }

        &:before {
            top: -4px;
        }

        &:after {
            bottom: -4px;
        }
    }
}