.ConfirmPopup {
    width: 480px;
    min-height: 210px;
    padding: 32px;
    background: #1C2531;
    box-shadow: 0px 32px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    &__content {
        text-align: center;
        color: #fff;
        font-weight: 400;
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.4;
    }

    &__footer {
        display: flex;
        margin: auto 0 0 auto;
        justify-content: flex-end;
        width: 100%;
    }

    &__button {
        flex: 0;
        margin-left: 16px;
    }
}
