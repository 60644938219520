.Textarea {
    @import "../../../pages/ChainPage/ChainPage.helpers.styles";

    position: relative;
    height: 170px;
    overflow: hidden;
    border: 1px solid rgba(white, .2);
    border-radius: 4px;

    &__scrollbars {
        background: none;
        border-radius: 4px;
        transition: background-color 250ms ease-in-out;
        cursor: pointer;
        height: 100%;

        &:hover,
        &.is-focus {
            background: rgba(black, .1);
        }

        & > div {
            bottom: -1px;
        }
    }

    &__input {
        border: none;
        outline: none;
        resize: none;
        padding: 4px;
        min-width: 100%;
        width: 100%;
        max-width: 100%;
        font-family: "DM Sans", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.3;
        color: #fff;
        padding: 8px 16px;
        background: none;
        cursor: inherit;

        &::placeholder {
            color: #5E6C81;
        }

        &.is-visible {
            overflow: hidden;
            width: 100%;
        }

        &.is-hidden {
            overflow: auto;
            visibility: hidden;
            transform: scale(0);
        }
    }

    &--has-error {
        border-color: color("red");
    }
}
