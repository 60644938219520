.NewTooltip {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    background: #6D7784;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;

    padding: 4px 8px;
    min-width: 50px;
    border-radius: 4px;
    filter: drop-shadow(0 4px 6px black);

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;
    white-space: nowrap;
    display: none;


    &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(100% - 1px);
        border-width: 5px 7px;
        border-style: solid;
        border-color: transparent;
        border-top-color: #6D7784;
    }

    &--left {
        left: 0;
        transform: none;
        &:after {
            left: 8px;
        }
    }

    &--right {
        left: auto;
        right: 0;
        transform: none;
        &:after {
            right: 8px;
        }
    }

    &--center {
        &:after {
            left: calc(50% - 7px);
        }
    }

    &--below {
        bottom: auto;
        top: 100%;

        &:after {
            top: auto;
            bottom: 100%;
            border-top-color: transparent;
            border-bottom-color: #6D7784;
        }
    }
}