.ReportsDownload {
    position: relative;

    &__header {
        font-weight: 700;
        font-size: 18px;
        padding-bottom: 24px;
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 45px;
    }

    &__action {
        margin-left: 16px;
    }
}