.NotesItem {
    @import "../../../../styles/helpers/ie.scss";

    position: relative;
    color: white;
    font-size: 14px;
    line-height: 1.4;
    padding: 12px 16px;
    margin-bottom: 1px;
    user-select: none;
    min-height: 80px;
    display: flex;
    flex-direction: column;

    & + & {
        &:after {
            content: '';
            position: absolute;
            left: 16px;
            top: -1px;
            width: calc(100% - 32px);
            height: 1px;
            background: rgba(white, .2);
        }
    }

    &:hover {
        cursor: pointer;
        background: rgba(white, .05);
    }

    &__date {
        opacity: 0.4;
        margin-top: 8px;
        font-size: 12px;
    }

    &__content {
        position: relative;
        display: flex;
        padding-right: 16px;

        @include onlyIE {
            flex: 1;
        }
    }

    &__status {
        margin-right: 16px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        min-width: 100%;
        flex: 1;

        @include onlyIE {
            flex-basis: auto;
        }
    }

    &__actions {
        display: flex;
        width: 48px;
        right: 16px;
        top: 8px;
        align-items: flex-start;
        justify-content: flex-end;
        position: sticky;
        margin-left: auto;
        max-height: 40px;
        margin-top: 4px;
    }

    &__actions-remove {
        fill: white;
    }

    &__actions-button {
        &:hover {
            opacity: 0.6;
        }

        & + & {
            margin-left: 16px;
        }
    }

    &__text-truncated {
        &.is-hidden {
            display: none;
        }
    }
}