@use "sass:math";

.Autocomplete {
    $root: &;
    $gap: 16px;
    $selectInputHeight: 56px;
    $radius: 4px;
    $trs-dur: 250ms;
    $trs-timing: ease-in-out;
    $inputIndex: 300;
    $buttonSize: math.div($selectInputHeight, 1.25);

    position: relative;
    z-index: $inputIndex;
    height: 100%;

    &__dropdown {
        background: #141c25;
        margin-top: $gap * 0.5;
        border-radius: $radius;
        position: relative;
        z-index: $inputIndex - 1;
        overflow: hidden;
        box-shadow: 12px 16px 60px rgba(0, 0, 0, 0.48);
    }

    &__options-loader {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        z-index: 1;
    }

    &__form {
        position: relative;
    }

    &__input {
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.3;
        background: rgba(black, .25);
        border-radius: 4px;
        outline: none;
        border: none;
        height: 56px;
        color: white;
        transition: background-color 250ms ease-in-out;
        cursor: pointer;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background: rgba(black, .4);
        }

        &:focus {
            cursor: text;
        }
    }

    &__option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        cursor: pointer;
        transition: background-color 250ms ease-out;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.4;
        user-select: none;

        & + & {
            border-top: 1px solid rgba(white, .1);
        }

        &:hover,
        &.is-selected {
            background: rgba(white, .05);
        }

        &.is-selected {
            transition-delay: 150ms
        }
    }

    &__option-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        padding: 0 $gap * 1.5;
        width: 100%;
    }

    &__option-title,
    &__option-subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    &__option-title {
        color: white;
        text-align: left;
    }

    &__option-subtitle {
        color: red;
    }

    &__option-highlight {
        background: rgba(#2EAFD9, .5);
        color: white;
        text-shadow: 1px 1px 2px rgba(black, .5);
    }

    &__tags {
        $size: 40px;

        height: calc(100% - 75px);

        &-list {
            display: flex;
            flex-direction: column;
            padding-top: $gap;
        }

        &-item {
            height: $size;
            width: 100%;
            min-width: 100%;
            background: #2EAFD9;
            position: relative;
            padding-left: $gap * 0.5;
            font-size: 16px;
            line-height: 1;
            font-weight: 400;
            overflow: hidden;
            border-radius: 4px;
            display: flex;
            align-items: center;
            cursor: default;
            margin-bottom: $gap * 0.5;
            max-width: calc(50% - #{$gap * 0.5});
            user-select: none;

            &-label {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }

            &-remove {
                width: $size;
                height: $size;
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                background: red;
                border-left: 1px solid rgba(255,255,255,.15);

                svg {
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &__no-match-items {
        padding: $gap $gap * 2;
        font-size: 80%;
        cursor: default;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
    }
}