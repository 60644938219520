.FoodProfileFoundIn {
    $height: 200px;
    $padding_h: 30px;

    &__container {
        padding: 0 $padding_h;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__images-container {
        padding: 30px $padding_h;
        width: calc(100% + #{2 * $padding_h - 200px});
        margin-left: -$padding_h;
        height: $height + 60px;
    }

    &__sizer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    &__image-container {
        position: relative;
        height: $height;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-2px);
        flex: 0 0 $height * 0.75;
        transition: transform 300ms;
        margin-right: 30px;
        margin-bottom: $height;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            z-index: 2;
        }
    }

    &__image-link {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}