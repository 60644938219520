@use "sass:math";

.search-widget {
    $root: &;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12.5vh;
    margin-left: -12.5vh;
    backface-visibility: hidden;
    background-color: transparent;
    width: 25vh;
    height: 25vh;
    cursor: pointer;
    text-align: center;
    opacity: 1;
    box-shadow: 0 0 50px 0 #fff, 0 0 70px 0 #fff inset;
    border-radius: 50%;
    z-index: 3;
    animation: appear 0.6s cubic-bezier(.61,1.71,.76,.89);
    transform-origin: center center;
    &:hover {
        #{$root}__border-hover {
            transform: rotate(360000deg);
            transition: transform 1000s;
        }
        #{$root}__bg-hover {
            transform: rotate(-360000deg);
            transition: transform 8000s;
        }
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 25vh;
        height: 25vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #000000;
        opacity: 0;
        transform-origin: 50% 50%;
        transition: all 600ms, opacity 300ms 600ms;
        border-radius: 50%;
        z-index: 100;
        backface-visibility: hidden;
    }

    #{$root}__border-mask,
    #{$root}__fill-mask,
    #{$root}__content {
        opacity: 1;
        transition: opacity 300ms;
    }

    &--collapse {
        opacity: 0;
        transition: all 360ms, opacity 0s 600ms, border-radius 0s, box-shadow 0s;
        &:after {
            opacity: 1;
            height: 150vw;
            width: 150vw;
            transition: all 600ms, opacity 400ms;
        }

        #{$root}__searchfield,
        #{$root}__border-mask,
        #{$root}__bg,
        #{$root}__content
        {
            opacity: 0;
            transition: opacity 0s;
        }

        #{$root}__bg {
            display: none;
        }
    }


    &__border-mask {
        width: 25vh - math.div(35, 11)*1vh;
        height: 25vh - math.div(35, 11)*1vh;
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__border-hover {
        transition: transform 20000000s;
        height: 100%;
        width: 100%;
    }
    &__border {
        width: 100%;
        height: 100%;
        border-radius: 50%;

        background: linear-gradient(135deg, #FB5789 10%, #FEE140 40%, #96FBC4 60%, #B05DEF 90%);
        //background: linear-gradient(135deg, #231557 13.71%, #FF1361 38.77%, #8E4ED1 62.13%, #FFF800 82.5%);
        transform-origin: 50% 50%;
        animation: spinning 4s infinite linear;
    }

    &__content {
        position: absolute;
        width: 25vh - math.div(45, 11)*1vh;
        height: 25vh - math.div(45, 11)*1vh;
        top: 50%;
        left: 50%;
        //transform: translate(-50%, -50%);
        margin-top: - (25vh - math.div(45, 11)*1vh) * 0.5;
        margin-left: - (25vh - math.div(45, 11)*1vh) * 0.5;
        color: white;
        //font-size: 13px;
        //line-height: 16px;
        font-size: math.div(17, 11)*1vh;
        line-height: math.div(19, 11)*1vh;
        background: #231F20;
        border-radius: 50%;
        box-sizing: border-box;
        padding: math.div(80, 11)*1vh math.div(10, 11)*1vh 0 math.div(10, 11)*1vh;
        //font-smoothing: antialiased;
        //filter: blur(0.000001px);
        backface-visibility: hidden;
    }

    &__title {
        font-weight: 300;
        letter-spacing: 1px;
    }

    &__searchfield {
        margin-top: 5px;
        font-size: math.div(28, 11)*1vh;
        line-height: math.div(30, 11)*1vh;
        display: inline-block;
        opacity: 1;
        text-transform: uppercase;
        //transition: transform 300ms, color 0s 300ms, opacity 300ms, margin 100ms 200ms, border-radius 300ms, border 300ms;
        transition: opacity 0s 300ms;
        transition-timing-function: linear;
        backface-visibility: hidden;
        font-weight: 700;
        position: relative;
        text-align: center;
        //.icon {
        //    transition: opacity 0s 300ms;
        //}
    }

    &__caret-helper {
        display: inline;
        animation: blinking 1000ms infinite;
        border-right-width: 1px;
        border-style: solid;
        padding-right: 5px;
        line-break: normal;
        span {
            display: block;
        }
    }

    &__bg-hover {
        width: 100%;
        height: 100%;
        transition: transform 20000000s;
    }

    &__bg {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: url(../../img/bg.png);
        background-size: 100% 100%;
        animation: spinning 40s infinite linear reverse;
    }
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes blinking {
    0% {
        border-color: transparent;
    }
    50% {
        border-color: #4990E2;
    }
    100% {
        border-color: transparent;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: scale(0.6) rotate(-90deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
    }
}
