.ContactsForm {
    @import "../../../pages/ChainPage/ChainPage.helpers.styles";

    width: 480px;
    min-height: 460px;
    padding: 32px;
    background: color("modal-bg");
    box-shadow: 0px 32px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__header-title {
        font-size: 18px;
        font-family: "DM Sans", sans-serif;
        line-height: 1;
        font-weight: 700;
        color: white;
    }

    &__header-actions {
        display: flex;
        align-items: center;
        color: #5E6C81;
        font-size: 13px;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__row {
        display: flex;
        width: 100%;

        &.has-space {
            padding-top: 28px;
        }

        & > div {
            flex: 1;

            & + div {
                margin-left: 16px;
            }
        }
    }

    &__radio-group {
        display: flex;
        color: white;
        font-weight: 500;
        font-size: 14px;
        margin-top: 32px;
    }

    &__radio-group-label {
        padding-right: 20px;
        cursor: default;
    }

    &__radio-group-buttons {
        display: flex;
        align-items: center;

        & > div {
            margin-right: 20px;
        }
    }

    &__footer {
        display: flex;
        margin: auto 0 0 auto;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    &__footer-action {
        &--remove {
            margin-right: auto;
        }
    }

    &__footer-button {
        flex: 0;

        &--close {
            margin-right: 15px;
        }

        &--remove {
            margin-right: auto;
            fill: white;
            text-transform: none;
            font-weight: 400;
            padding-left: 0;

            & > svg {
                margin-right: 5px;
            }
        }
    }

    &__confirm {
        &-icon {
            fill: color("red");
            margin-bottom: 8px;
        }
    }
}