@use "sass:math";

@import '../../../style';

.ReportProNewReleases {
    position: absolute;
    bottom: 0;
    right: 0;
    $bg: #000;
    z-index: 2;
    transform: translateX(100%);
    transition: transform 500ms;
    $root: &;

    &.is-opened {
        transform: translateX(0);
    }

    &__view {
        background: $bg;
        border-radius: 16px 0 0 0;
        color: #fff;
        padding-bottom: 8px;
    }

    &__inner {
        position: relative;
    }

    &__viewport {
        overflow: hidden;
        position: absolute;
        height: 1000px;
        left: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        z-index: 1;
    }

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        padding: 8px 16px;
    }
    
    &__link-button {
        color: #e41e42;
        display: flex;
        align-items: center;
        text-decoration: none;
        font-family: Source Sans Pro, sans-serif;
        font-size: 16px;
        line-height: 16px;
        cursor: pointer;
        transition: letter-spacing 300ms;

        &:hover {
            letter-spacing: 1px;
            svg {
                margin-left: 12px;
            }
        }

        path {
            fill: #e41e42;
        }

        svg {
            margin-left: 8px;
            transition: margin-left 300ms;
        }
    }

    &__title {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        font-family: 'Montserrat', 'Arial', sans-serif;
        padding: 8px 0;
        margin-right: 40px;

        @include media-max(1400px) {
            font-size: 18px;
        }

        @include media-max(1200px) {
            font-size: 14px;
            margin-right: 20px;
        }
    }

    &__button-container {
        width: 100%;
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    &__button {
        position: absolute;
        right: 100%;
        height: 150px;
        bottom: 50%;
        transform: translateY(50%);
        width: 32px;
        border-radius: 16px 0 0 16px;
        background: $bg;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        $button: &;

        &-icon {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: #fff;
            box-shadow: 0 -6px 0 0 #fff, 0 6px 0 0 #fff;
            transition: box-shadow 300ms, transform 300ms;
        }

        &:before, &:after {
            content: '';
            display: block;
            width: 32px;
            height: 32px;
            position: absolute;
            left: 0;
        }

        &:before {
            bottom: 100%;
            border-radius: 0 0 16px 0;
            box-shadow: 16px 16px 0 0 $bg;
        }

        &:after {
            top: 100%;
            border-radius: 0 16px 0 0;
            box-shadow: 16px -16px 0 0 $bg;
        }

        &:hover {
            $move: 3px;
            #{$button}-icon {
                transform: translateX(-$move);
                box-shadow: 2 * $move -4px 0 0 #fff, 2 * $move 4px 0 0 #fff;

                #{$root}.is-opened & {
                    transform: translateX($move);
                    box-shadow: -2 * $move -4px 0 0 #fff, -2 * $move 4px 0 0 #fff;
                }
            }
        }
    }
}

