@import '@styles';

.HaikuPopup {
    position: relative;
    width: calc(100vw - 200px);
    max-width: 1200px;
    height: 615px;
    margin-bottom: 25px;
    color: #fff;
    $root: &;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.18);
    z-index: 200;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 12px;

    @include media-max(1400px) {
        width: calc(100vw - 130px);
    }

    &__content-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__content {
        padding-left: 20px;
        padding-right: 20px;
        backface-visibility: hidden;
        border-radius: 10px 10px 0 0;
        will-change: clip, transform;
        box-shadow: 0 5px 20px 0 transparent;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background: #235ae4;
        position: relative;
    }

    &__chart-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
    }

    &__header {
        padding-bottom: 0;
        padding-top: 20px;
        height: 150px;
        color: #fff;
        position: relative;
        font-size: 17px;

        @include media-max(1400px) {
            font-size: 12px;
        }
    }

    &__header-title {
        font-weight: 700;
        text-transform: capitalize;
        font-size: 40px;
        line-height: 40px;
        white-space: nowrap;

        @include media-max(1400px) {
            font-size: 30px;
            line-height: 30px;
        }
    }

    &__header-subtitle {
        font-weight: 300;
        margin-top: 5px;
        font-size: 30px;
        line-height: 40px;

        @include media-max(1400px) {
            font-size: 20px;
            line-height: 20px;
        }
    }

    &__header-mark {
        display: inline-block;
        vertical-align: middle;
        padding: 0 8px;
        color: #fd8b60;
    }

    &__footer {
        min-height: 140px;
        background: #fff;
        width: 100%;
        padding: 0 40px 0 0;
        color: #292929;
        border-radius: 0 0 10px 10px;
    }

    &__footer-wrapper {
        display: flex;
        align-items: center;
    }

    &__footer-title {
        padding: 0 20px;
        font-size: 30px;
        font-weight: 800;
        min-width: 26%;
        line-height: 140px;
        font-family: "Work Sans", "Source Sans Pro", sans-serif;
        text-transform: uppercase;
        flex-grow: 0;
    }

    &__footer-caption {
        font-size: 12px;
        line-height: 12px;
        flex-grow: 3;
    }

    &__footer-paragraph {
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__haiku-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        z-index: 2;
    }
}