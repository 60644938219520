.ChartTooltip {
    $root: &;
    $shift: 37px;

    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: transform 300ms;
    font-family: 'Comfortaa', sans-serif;

    &__inner {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        font-family: 'Comfortaa', sans-serif;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        transform: translate(-50%, calc(-100% - #{$shift}));
        color: #fff;
        transition: transform 300ms;
        display: flex;
    }

    &__group {
        padding: 6px 2px 12px;
        position: relative;
        display: flex;

        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }

        &:only-child {
            border-radius: 4px;
            padding: 6px 2px;
        }

        &--actual {
            background: #E77D56;
        }

        &--predicted {
            background: #E06C41;
        }
    }

    &__col {
        min-width: 41px;
    }

    &__cell {
        padding: 0 5px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    &__head {
        color: rgba(#fff, 0.5);
    }

    &__label {
        font-size: 8px;
        line-height: 10px;
        position: absolute;
        bottom: 0;
        left: 3px;
        color: #994121;

        #{$root}__group:only-child & {
            display: none;
        }

        #{$root}__group--predicted & {
            color: rgba(#fff, 0.5);
        }
    }
}
