.Tile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(25% - 16px);
    height: calc(33% - 16px);
    margin: 8px;
    border-radius: 4px;
    padding: 16px;
    text-align: center;
    font-style: normal;
    cursor: pointer;
    transition: background-color 300ms, color 300ms ease;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    font-size: 7%;
    $root: &;
    background-color: #232D3B;
    color: #D7E3F3;

    &--opaque {
        opacity: 0.6;
    }

    &__fill {
        position: absolute;
        width: 50rem;
        height: 50rem;
        margin-top: -25rem;
        margin-left: -25rem;
        transform: scale(0);
        transition: transform 300ms linear, opacity 600ms linear;
        border-radius: 50%;
        z-index: 1;
        background-color: #25B7A5;
    }

    &__text {
        position: relative;
        z-index: 2;
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
    }

    &:hover {
        #{$root}__fill {
            transform: scale(1);
        }
    }
}