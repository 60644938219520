.MenuDetailsButton {
    $background: #252F3B;
    $root: &;

    position: relative;
    background: $background;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    height: 100%;
    user-select: none;

    &:hover {
        background: lighten($background, 5%);
    }

    &.is-selected {
        background: #2EAFD9;
    }

    &.is-small {
        flex-direction: row;
        height: 45px;

        #{$root}__items {
            margin-bottom: 0;
            margin-right: 8px;
            font-size: 20px;
        }
    }

    &.is-disabled {
        opacity: 0.5;
        user-select: none;
        pointer-events: none;

        &:hover {
            background: $background;
        }
    }

    &.is-compact {
        flex-direction: row;

        #{$root}__items {
            font-size: 20px;
            margin-bottom: 0;
            margin-right: 8px;
        }
    }

    &__items {
        font-size: 48px;
        line-height: 1;
        font-weight: 700;
        margin-bottom: 4px;
    }

    &__label {
        font-size: 14px;
        line-height: 1;
    }
}