.LaunchesList {
    @import "../../../../ChainPage.helpers.styles";
    @import "@styles";
    $value-size: 50px;

    position: relative;
    padding-left: 16px;

    &__title {
        font-size: 12px;
        color: #676D75;
        font-weight: 400;
        border-bottom: 2px solid #50637C;
        padding-bottom: 8px;
        margin-bottom: 8px;
        text-align: right;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__item {
        display: flex;
        font-size: 14px;
        line-height: 1;
        padding-top: 8px;
        padding-left: 8px;
        padding-bottom: 8px;
        max-width: calc(50% - 16px);
        min-width: calc(50% - 16px);
        border-bottom: 1px solid #2A3442;
        color: $chains-primary;
        cursor: default;

        &:nth-child(even) {
            margin-left: 32px;
        }

        &:hover {
            color: #fff;
            background: rgba(#56CCF2, .08);
        }
    }

    &__value {
        max-width: $value-size;
        min-width: $value-size;
        margin-left: auto;
        text-align: right;
        padding-right: 8px;
        color: white;
    }

    &__label {
        max-width: calc(100% - #{$value-size});
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
    }

    &__toggler {
        margin-top: 16px;
        color: color("link");
        font-size: 12px;
        display: flex;
        user-select: none;
    }

    &__toggler-button {
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        align-items: center;

        &:hover {
            text-decoration: none;
        }
    }

    &__toggler-text {
        padding-right: 8px;
    }
    &__toggler-icon {
        transform: rotate(-90deg);

        &.is-expanded {
            transform: rotate(90deg);
        }

        path {
            fill: color("link");
        }
    }

    &__total {
        width: 100%;
        height: 28px;
        background: #2A323D;
        color: rgba(248, 129, 18, 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        margin-top: 20px;
        font-size: 18px;
        line-height: 18px;
        border-radius: 3px;
    }

}