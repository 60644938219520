.FoodSummary {
    position: relative;
    display: flex;
    height: 460px;
    color: #fff;
    background: #2D3A4C;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    border-radius: 8px;
    $gap: 8px;
    $root: &;

    b {
        font-weight: 700;
    }

    li {
        margin-bottom: $gap;
        display: flex;
        white-space: nowrap;
        &::before {
            content: '•';
            padding: 0 10px;
        }
    }

    &__bullet-inner {
        display: flex;
        flex-direction: column;
    }

    &__bullet-sub {
        font-size: 14px;
        line-height: 18px;
    }


    &__aside {
        padding: 30px 40px 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 0 0 550px;

        .p {
            margin-bottom: $gap;
        }
    }

    &__aside-content {
        flex: 0 1 100%;
    }
    &__aside-footer {
        flex: 0 0;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 16px;
    }

    &__title {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        text-decoration: none;
        display: flex;
    }

    &__delimiter {
        height: 1px;
        background: rgba(#fff, 0.25);
        margin: 2 * $gap 0;
    }

    &__button-loading {
        animation: pulse 1200ms infinite ease-in-out;
    }

    &__main {
        display: flex;
        flex-direction: column;
        flex: 0 1 100%;
        align-items: flex-end;
        padding: 16px 12px 0;
    }

    &__main-content {
        width: 100%;
        flex: 0 1 100%;
        position: relative;

        &:hover {
            z-index: 2;
        }
    }

    &__main-header {
        position: relative;
        padding-bottom: 10px;

        &:hover {
            z-index: 2;
        }
    }

    &__main-footer {
        width: 100%;
        flex: 0 0 60px;
        display: flex;
        align-items: center;
        position: relative;

        &:hover {
            z-index: 2;
        }
    }

    &__blur {
        #{$root}.is-blocked & {
            filter: blur(5px);
        }
    }
}