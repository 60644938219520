.InstantChartReport {
    background: #ffff;
    height: 100%;
    $root: &;


    &__header {
        height: 200px;
        padding: 20px 20px 0;
        display: flex;
        justify-content: space-between;
    }

    &__header-left {
        flex: 1 1 100%
    }

    &__header-right {
        flex: 0 0 250px;
        margin-left: 50px;
    }

    &__tooltip {
        position: absolute;
        bottom: calc(100% + 10px);
        left: 0;
        white-space: nowrap;
        background: #fff;
        filter: drop-shadow(0 4px 10px rgba(#000, 0.3));
        padding: 10px;
        border-radius: 4px;
        text-align: center;
        font-size: 10px;
        line-height: 1.5;
        color: #000;
        text-transform: none;
        pointer-events: none;
        opacity: 0;
        transform: translateY(10px);
        transition: all 500ms;

        #{$root}__table-header-item:hover & {
            opacity: 1;
            transform: translateY(0px);
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            border-style: solid;
            border-width: 6px 8px;
            border-color: transparent;
            border-top-color: #fff;
        }
    }

    &__table {
        padding: 30px 15px 5px 15px;
        height: calc(100% - 200px);
        position: relative;
    }

    &__table-header {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: 10px;
        width: 100%;
        padding-right: 50px;
    }

    &__table-header-item {
        width: 3 * 100px - 10px;
        margin: 0 5px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        position: relative;
        z-index: 2;
    }

    &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        //text-align: right;

        &.is-bold {
            font-weight: 700;
            font-size: 26px;
            line-height: 37px;
        }

        &.is-small {
            margin: 14px 0;
            font-size: 14px;
        }
    }

    &__download-button {
        height: 48px;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;
        display: block;
        line-height: 47px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 10px;
        font-weight: 700;
        text-align: center;
        float: left;
        clear: left;
        width: 250px;
        font-size: 12px;

        .icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 34px;
            height: 23px;
            fill: #ffffff;
        }

        &--progress-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
        }

        &--download {
            background: #86D004;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        &--downloading {
            opacity: 0.8;
            pointer-events: none;
        }

        &--small {
            font-size: 12px;
            padding: 0;
            width: auto;
            color: #ddd;
            height: 30px;
            line-height: 30px;
            margin-bottom: 0;

            &:hover {
                .package-animation-container {
                    text-decoration: underline;
                }

                .icon {
                    filter: drop-shadow(0 0 3px #fff);
                }
            }

            .icon {
                transition: all 300ms;
                margin-right: 5px;
                width: 23px;
                margin-top: -4px;
                fill: #ddd;
            }
        }
    }
}