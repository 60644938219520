.Documents {
    @import "@styles";

    $root: &;

    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    height: 100%;

    &__body {
        position: relative;
        max-width: 1920px;
        // min-width: 1024px;
        height: calc(100% - 105px);
        margin: 0 auto;
        background-color: #F6F6F6;
        transition: padding-top .2s;
    }
}
