.ChartLine {
    &__items-list {
        width: 100%;
        padding: 15px 10px 5px;
        display: block;
        min-height: 216px;

        &--paired-items {
            padding-bottom: 35px;
        }
    }

    &__item {
        height: 28px;
        position: relative;
        display: block;
        width: 100%;
        padding-top: 6px;
        font-size: 14px;
        line-height: 20px;
        color: #3F3F3F;
        overflow: hidden;

        &:first-child {
            margin-top: -10px;
        }
    }

    &__progress-bar {
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        pointer-events: none;

        .is-inline & {
            border-radius: 3px;
            height: 20px;
            background: transparent;
            bottom: 1px;
        }

        .is-data-top & {
            border-radius: 9px;
            height: 3px;
            background: #ededed;
            bottom: 0;
        }
    }

    &__progress-line {
        border-radius: 3px;
        height: 100%;
        display: block;

        .is-gray & {
            background: rgba(#B0AFAF, .2);
        }

        .is-blue & {
            background: #3d8cde;
        }
    }

    &__info {
        font-size: 14px;
        color: #3F3F3F;
    }

    &__state {
        float: left;
        text-transform: capitalize;

        .is-inline & {
            padding-left: 5px;
        }
    }

    &__state-value {
        float: right;
        padding-right: 5px;
    }

    &__columns {
        display: flex;
    }

    &__col-3 {
        width: 33.3%;
    }

    &__col-5 {
        width: 20%;
    }
}