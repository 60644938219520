.DocumentsHeader {
    @import "@styles";
    $root: &;

    top: 60px;
    position: sticky;
    width: 100%;
    height: 105px;
    max-width: 1920px;
    padding: 20px 50px;
    z-index: 3;
    background: linear-gradient(0deg, rgba(249, 248, 248, 0.5) 101.41%, rgba(246, 243, 243, 0.761539) 102.52%, rgba(186, 183, 183, 0.26) 102.53%, rgba(248, 246, 246, 0.35) 103.5%, #FFFFFF 103.57%), #FFFFFF;
    border-bottom: 1px solid transparentize(#000, .6);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-max(1300px) {
        padding: 20px;
    }

    &__title {
        font-size: 26px;
        text-transform: uppercase;

        @include media-max(1800px) {
            font-size: 24px;
        }

        @include media-max(1440px) {
            font-size: 19px;
        }
    }

    &__total {
        margin-top: 8px;
    }

    &__actions {
        display: flex;
        align-items: center;
    }

    &__toggle-view {
        display: flex;
        margin-right: 8px;
    }

    &__view-button {
        position: relative;
        font-family: Muli, sans-serif;
        font-size: 20px;
        color: #C1C1C1;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 4px 16px;
        overflow: visible;
        user-select: none;

        &--active {
            color: #000;
            cursor: default;
        }

        & + & {
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                width: 2px;
                height: 20px;
                margin-top: -10px;
                background-color: #000;
            }
        }
    }

    &__filter {
        cursor: pointer;
        font-size: 22px;
        line-height: 38px;
        height: 36px;
        position: relative;
        font-family: $font_text;
        z-index: 1000;
        width: 140px;
        padding-right: 25px;
        border: 1px solid transparent;
        opacity: 1;
        visibility: visible;
        text-align: right;
        margin-top: -8px;

        &--hidden {
            opacity: 0;
            visibility: hidden;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            margin-left: 5px;
            border-top: 4px solid #000;
            width: 0;
            height: 0;
            border-right: 4px solid transparent;
            transition: transform 300ms;
            border-left: 4px solid transparent;
            transform-origin: center 0;
        }

        &--opened {
            border-radius: 3px 3px 0 0;
            background: #FFFFFF;
            border: solid #D1D1D1;
            border-width: 1px 1px 0 1px;

            &:after {
                transform: rotate(-180deg);
            }

            #{$root}__filter-list {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__filter-list {
        width: 140px;
        border: 1px solid #D1D1D1;
        border-top-color: #DBDBDB;
        background: #FFFFFF;
        border-radius: 0 0 3px 3px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: -1px;
        padding-bottom: 10px;
    }

    &__filter-list-item {
        font-size: 15px;
        line-height: 23px;
        height: 20px;
        font-weight: 400;
        padding: 2px 25px;
        font-family: $font_main;

        &:hover {
            font-weight: 600;
        }

        &--selected {
            font-weight: 700;
        }
    }
}