.my-documents-types {
    &__item {
        display: block;
        text-decoration: none;
        font-size: 18px;
        line-height: 30px;
        white-space: nowrap;
        @include clearfix;
        transition: all 300ms;
        cursor: pointer;
        color: #4f4f4f;

        &:hover {
            background: #f3f3f3;
        }

        @include media-max(1600px) {
            font-size: 13px;
        }

        @include media-max(1200px) {
            width: 30%;
            margin-left: 5%;
            float: left;
            position: relative;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 1px;
                left: calc(-100%/12);
                background: #989898;
            }

            &:nth-child(3n + 1) {
                margin-left: 0;

                &:after {
                    content: none;
                }
            }
        }
    }

    &__count {
        text-align: right;
        width: 120px;
        padding-left: 20px;
        float: right;

    }
}
