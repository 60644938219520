.OptionTag {
    position: relative;
    padding-right: 40px;
    height: 100%;
    width: 100%;

    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        padding-right: 8px;
        padding-left: 16px;
    }

    &__remove {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-left: 1px solid rgba(black, .1);

        &:hover {
            background: rgba(black, .1);
        }
    }
}