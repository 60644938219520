.ContactAddButton {
    cursor: pointer;

    transition: background-color 300ms, opacity 300ms;

    &__button {
        display: flex;
        align-items: center;
    }

    &__label {
        font-weight: 700;
        font-size: 14px;
        line-height: 1;
        color: #fff;
        padding-left: 16px;
    }

    &:hover {
        opacity: 0.8;
    }
}