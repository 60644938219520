@import '../../style';

.documents {
    $root: &;

    &__image {
        width: 100%;
    }

    &__section {
        position: relative;
        max-width: 1920px;
        min-width: 1024px;
        height: calc(100vh - 60px); // 60 + 350
        margin: 0 auto;
        padding-top: 104px;
        //z-index: 2;
        background-color: #F6F6F6;
        transition: padding-top .2s;

        &--collapsed {
            padding-top: 45px;

            .documents__links-wrapper {
                margin-right: 40px;

                @media screen and (max-width: 1550px) {
                    float: none;
                    clear: none;
                }
            }

            .documents__change-view-button--active {
                color: #fff;
            }

            .documents__change-view-outer{
                margin-top: 0;

                &--enlarged {
                    min-width: 190px;
                }

                @media screen and (max-width: 1550px) {
                    float: right;
                    clear: none;
                }
            }

            .documents__dropdown {
                margin-top: 0;
                font-size: 14px;
                line-height: 30px;
                color: #ffffff;
                height: 30px;
                width: 100px;

                &--opened {
                    background: #007cab;
                }
            }

            .documents__dropdown-items-list {
                background: #007cab;
                width: 100px;
            }

            .documents__dropdown-item {
                padding: 2px 25px 2px 5px;
            }

            .documents__dropdown-item {
                font-size: 12px;
            }
        }
    }

    &__dropdown {
        cursor: pointer;
        font-size: 22px;
        line-height: 38px;
        height: 36px;
        display: inline-block;
        position: relative;
        font-family: $font_text;
        z-index: 1000;
        width: 140px;
        padding-right: 25px;
        margin-top: 5px;
        border: 1px solid transparent;
        opacity: 1;
        visibility: visible;

        @include media-max(1300px) {
            margin-top: 0;
        }

        &--hidden {
            opacity: 0;
            visibility: hidden;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 5px;
            border-top: 4px solid #000;
            width: 0;
            height: 0;
            border-right: 4px solid transparent;
            transition: transform 300ms;
            border-left: 4px solid transparent;
            transform-origin: center 0;
            margin-top: 4px;
        }

        &--opened {
            border-radius: 3px 3px 0 0;
            background: #FFFFFF;
            border: solid #D1D1D1;
            border-width: 1px 1px 0 1px;
            &:after {
                transform: rotate(-180deg);
            }

            #{$root}__dropdown-items-list {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &__dropdown-items-list {
        width: 140px;
        border: 1px solid #D1D1D1;
        border-top-color: #DBDBDB;
        background: #FFFFFF;
        border-radius: 0 0 3px 3px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: -1px;
        padding-bottom: 10px;
    }

    &__dropdown-item {
        font-size: 15px;
        line-height: 23px;
        height: 20px;
        font-weight: 400;
        padding: 2px 25px;
        font-family: $font_main;

        &--selected {
            font-weight: 700;
        }

        &:not(.documents__dropdown-item--selected):hover {
            font-weight: 600;
        }
    }

    &__visibility-sensor-after {
        width: 1px;
        height: 900px;
        max-height: 100%;
        margin-top: -1200px;
        opacity: 0;
        z-index: -1;

        //opacity: 1;
        //background: red;
        //width: 22px;
    }

    &__found {
        margin-right: auto;
        text-align: left;
        line-height: 31px;
        float: left;
        width: calc(100% - 1000px);
        @include clearfix;

        @include media-max(1550px) {
            width: calc(100% - 700px);
        }
        @include media-max(1300px) {
            width: calc(100% - 600px);
        }
    }

    &__keyword {
        display: block;
        text-transform: uppercase;
        font-size: 26px;

        @media screen and (max-width: 1800px) {
            font-size: 24px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 19px;
        }
    }

    &__links-wrapper {
        display: inline-block;
        margin-right: 10px;
        //float: right;
        @include clearfix;

        @media screen and (max-width: 1550px) {
            //float: right;
            //clear: right;
        }

        @media screen and (max-width: 1440px) {
            margin-right: 5px;
        }
    }

    &__tab-header {
        // JACK FIX
        padding: 15px 50px 10px;
        //padding: 40px 75px 15px;
        // END JACK FIX
        @include clearfix;
    }

    &__tab-header-title {
        float: left;
        max-width: 82%;

        &--mac {
            max-width: 100%;
            position: relative;
            font-size: 15px;
        }
    }

    &__change-view-outer {
        float: right;
        margin-top: 7px;
        min-width: 115px;

        &--enlarged {
            min-width: 260px;
        }

        @include media-max(1550px) {
            float: right;
            clear: right;
        }

        @include media-max(1440px) {
            min-width: 94px;
        }

        @include media-max(1300px) {
            margin-top: 0;
        }
    }

    &__change-view-button {
        font-family: Muli, sans-serif;
        font-size: 20px;
        color: #C1C1C1;
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 1px 6px;
        overflow: visible;

        &--active {
            color: #000;
            cursor: default;
        }

        &:not(:last-of-type) {
            position: relative;
            margin-right: 10px;
            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 5px;
                left: calc(100% + 4px);
                width: 2px;
                height: 20px;
                background-color: #000;
            }
        }
    }

    &__change-view-dropdown {
        position: relative;
        display: flex;
        justify-content: flex-end;
        background: transparent;
        border: 0;
        outline: 0;
    }

    &__filter-text {
        color: #2D9CDB;
        text-decoration: underline;
        font-size: 24px;
        font-family: $font_main;
        text-transform: none;
        cursor: pointer;

        &:hover {
            color: lighten(#2D9CDB, 10%);
            text-decoration: none;
        }
    }

    &__filter-dropdown {
        position: absolute;
        top: 100%;
        right: 0;
        background: white;
        z-index: 1000;
        width: 240px;
        box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
    }

    &__filter-dropdown-option {
        height: 45px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ededed;
        font-size: 16px;
        text-align: left;
        cursor: pointer;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background-color: lighten(#2D9CDB, 40%);
        }

        @media screen and (max-width: 1440px) {
            font-size: 16px;

            &:not(:last-child) {
                &:after {
                    top: 1px;
                }
            }
        }
    }

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100vh - 60px);

        &--no-height {
            height: auto;
        }
    }

    &__notice {
        font-size: 36px;
        line-height: 40px;
        text-align: center;

        a {
            color: #2D9CDB;
        }
    }
}

.documents-search {
    display: none;

    //display: flex;
    justify-content: space-between;
    float: left;
    width: 35%;
    min-width: 265px;
    border-bottom: 1px solid #000;

    &__label {
        width: 16px;
        margin-right: 5px;

        svg {
            width: 16px;
            transform: scaleX(-1);
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__input {
        flex-grow: 2;
        font-family: Muli, sans-serif;
        font-size: 22px;
        color: #000000;
        background-color: transparent;
        border: none;
    }
}

.category-content {
    height: 100%;
}

.category-link {
    $root: &;

    &__list {
        //position: absolute;
        top: 60px;
        position: fixed;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 20px 50px;
        z-index: 3;
        //z-index: 4;
        background: linear-gradient(0deg, rgba(249, 248, 248, 0.5) 101.41%, rgba(246, 243, 243, 0.761539) 102.52%, rgba(186, 183, 183, 0.26) 102.53%, rgba(248, 246, 246, 0.35) 103.5%, #FFFFFF 103.57%), #FFFFFF;
        text-align: right;
        border-bottom: 1px solid transparentize(#000, .6);

        @include media-max(1300px) {
            padding: 20px;
            //display: flex;
            //align-items: center;
        }

        @include clearfix;

        &--collapsed {
            padding: 7px 50px;
            background: #01B0F1;
            border-bottom: none;
            box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.1), 0px 1px 29px rgba(0, 0, 0, 0.23947);

            @media screen and (max-width: 1300px) {
                padding: 7px 20px;
            }

            .documents {

                &__keyword {
                    font-size: 22px;
                    color: #fff;
                }

                &__change-view-button {
                    font-size: 14px;
                    line-height: 30px;

                    &:not(:last-child) {
                        &:after {
                            top: 5px;
                        }
                    }
                }

                &__found {
                    @include media-max(1300px) {
                        width: calc(100% - 740px);
                    }
                }

                &__keyword {
                    @include media-max(1300px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    &__btn {
        position: relative;
        margin: 0 5px;
        padding: 13px 10px;
        color: #303030;
        background-color: #fff;
        border: 1px solid #D1D1D1;
        border-radius: 3px;
        //transition: margin .2s, padding .2s;
        cursor: pointer;

        @include clearfix;

        @media screen and (max-width: 1440px) {
            padding: 10px 9px;
            margin: 0 3px;
        }

        @include media-max(1300px) {
            padding: 5px;
        }

        &--active, &:hover {
            box-shadow: 1px 1px 8px 0 transparentize(darken(#2DC0E6, 25%), .6);

            color: #FFFFFF;
            background-color: #2D9CDB;

            #{$root}__text, #{$root}__sub-text {
                color: #FFF;
            }

            #{$root}__amount {
                color: #2D9CDB;
                background-color: #FFF;
            }
        }

        //&--active:after {
        //    content: '';
        //    position: absolute;
        //    top: calc(100% + 12px);
        //    left: 50%;
        //    display: block;
        //    width: 18px;
        //    height: 18px;
        //    background-image: linear-gradient(-45deg, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0) 44%, rgba(246, 246, 246, 1) 45%, rgba(246, 246, 246, 1) 100%); //  #f6f6f6
        //    border-top: 1px solid transparentize(#000, .6);
        //    border-left: 1px solid transparentize(#000, .6);
        //    transform: translateX(-50%) rotate(45deg);
        //}

        &--collapsed {
            margin: 0 15px;
            padding: 0;
            background-color: transparent;
            border: none;

            &:hover {
                background-color: transparent;
                box-shadow: none;
            }

            #{$root}__sub-text, #{$root}__amount {
                display: none;
            }

            #{$root}__text {
                font-size: 14px;
                line-height: 31px;
                color: #fff;
            }
        }

        &--collapsed#{$root}__btn--active {
            box-shadow: none;

            &:after {
                content: '';
                display: block;
                position: absolute;
                bottom: -9px;
                left: 0;
                height: 3px;
                width: 100%;
                background-color: #fff;
            }
        }
    }

    &__text {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        color: #303030;
        text-align: left;

        @media screen and (max-width: 1700px) {
            font-size: 16px;
        }

        @media screen and (max-width: 1440px) {
            font-size: 15px;
        }

        @media screen and (max-width: 1300px) {
            font-size: 12px;
        }
    }

    &__sub-text {
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        line-height: 1;
        color: #494949;

        @media screen and (max-width: 1300px) {
            font-size: 10px;
        }
    }

    &__img {
        display: none;
        width: calc(100% - 30px);
        height: 123px;
        margin: 15px 15px 0;
        object-fit: cover;
        transition: all .2s;

        &--collage {
            position: relative;
            overflow: hidden;
        }
    }

    &__footer {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &__amount {
        float: right;
        height: 28px;
        width: 28px;
        margin-left: 10px;
        font-size: 10px;
        line-height: 30px;
        font-weight: 300;
        color: #FFF;
        border-radius: 17px;
        background-color: #494949;
        margin-top: 5px;

        @media screen and (max-width: 1500px) {
            margin-left: 12px;
        }

        @media screen and (max-width: 1300px) {
            margin-left: 5px;
            line-height: 25px;
            width: 23px;
            height: 23px;
        }
    }
}

.active-category-pointer {
    content: '';
    position: absolute;
    top: calc(100% + 12px);
    display: block;
    width: 18px;
    height: 18px;
    background-image: linear-gradient(-45deg, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0) 44%, rgba(246, 246, 246, 1) 45%, rgba(246, 246, 246, 1) 100%); //  #f6f6f6
    border-top: 1px solid transparentize(#000, .6);
    border-left: 1px solid transparentize(#000, .6);
    transform: translateX(-50%) rotate(45deg);
    transition: left .35s;

    &__scale {
        position: relative;
        display: block;
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        opacity: 0;
    }

    //&:after {
    //    content: '';
    //    position: absolute;
    //    top: calc(100% + 12px);
    //    display: block;
    //    width: 18px;
    //    height: 18px;
    //    background-image: linear-gradient(-45deg, rgba(246, 246, 246, 0) 0%, rgba(246, 246, 246, 0) 44%, rgba(246, 246, 246, 1) 45%, rgba(246, 246, 246, 1) 100%); //  #f6f6f6
    //    border-top: 1px solid transparentize(#000, .6);
    //    border-left: 1px solid transparentize(#000, .6);
    //    transform: translateX(-50%) rotate(45deg);
    //    transition: left .35s;
    //}

    &:nth-child(7) {
        max-width: 1080px;
    }

    &:nth-child(6) {
        max-width: 864px;
    }

    &:nth-child(5) {
        max-width: 642px;
    }

    &:nth-child(4) {
        max-width: 428px;
    }

    &:nth-child(3) {
        max-width: 214px;
    }

}

.collage {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &--0 {
        width: 60%;
        left: 50%;
        z-index: 2;
    }

    &--1 {
        width: 45%;
        left: 32%;
        z-index: 1;
    }

    &--2 {
        width: 45%;
        left: 68%;
        z-index: 1;
    }

    &--3 {
        width: 35%;
        left: 0;
    }

    &--4 {
        width: 35%;
        left: 100%;
    }
}

.download-spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &--mac-details {
        top: 0px;
    }
}

.disabled-underline {
    text-decoration: none;
}

.notification {
    width: 250px;
    position: absolute;
    top: 10px;
    right: 75px;
    z-index: 3;
    text-align: center;
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #219653;
    text-transform: uppercase;
    background-color: #BFFFDA;
    border: 1px solid #219653;
    border-radius: 6px;

    &__icon {
        margin-right: 5px;
        svg {
            transform-origin: 100% 100%;
            animation: chop .35s cubic-bezier(.55, .02, .83, .67) infinite;
        }

    }
}

@keyframes chop {
    from {
        transform: rotate(-25deg);
    }
    100% {
        transform: rotate(5deg);
    }
}

.btn-icon-svg {
    transition: all 100ms 0ms;
    fill: #4F4F4F;
}

.scrollable-content {
    height: 100%;
}
