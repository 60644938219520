.ActionButton {
    @import "@styles";

    display: flex;
    align-items: center;
    margin: 0 em(4px);
    line-height: 1;
    font-weight: 500;
    color: #4F4F4F;
    text-transform: uppercase;
    transition: color .1s, opacity .1s;
    cursor: pointer;

    &:hover {
        color: #2F80ED;

        svg {
            fill: #2F80ED;
        }
    }

    &.is-accent {
        color: #2F80ED;

        svg {
            fill: #2F80ED;
        }

        &:hover {
            color: #ffa500;

            svg {
                fill: #ffa500;
            }
        }
    }

    &__text {
        font-size: em(9px);
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-right: em(4px);

        svg {
            max-width: em(12px);
            min-width: em(12px);
            max-height: em(12px);
        }
    }
}