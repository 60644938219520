@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import "../../style.scss";

.NewTopChains {
    position: relative;
    background-color: #101215;
    padding: 24px 32px;
    color: #fff;
    $page-min-height: calc(100vh - #{$header_height});
    height: calc(100vh - calc(#{$platform-navigation-height} + #{$header_height}));


    &.is-places {
        height: calc(100vh - #{$platform-navigation-height});
    }

    &__wrapper {
        height: 100%;

        @media screen and (min-width: 1680px) {
            max-width: 1680px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__part {
        display: flex;
        align-items: center;
    }

    &__tabs {
        display: flex;
        align-items: center;
    }

    &__part-icon {
        margin-right: 16px;
    }

    &__button {
        margin-left: 16px;
    }

    &__header {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;

        span {
            color: #32C1E9;
        }
    }

    &__filters {
        margin-bottom: 24px;
    }

    &__table {
        position: relative;
        background: #131921;
        padding: 0 24px 24px;
        height: calc(100vh - calc(#{$platform-navigation-height} + #{$header_height})  - 200px);
    }

    &__table-container {
        height: calc(100% - 60px);
    }

    &__table-copy {
        position: absolute;
        right: 24px;
        top: 12px;
    }

    &__link {
        text-transform: uppercase;
        color: #32C1E9;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        display: block;
        width: 100%;
        line-height: 1.4;

        &:hover {
            text-decoration: underline;
        }
    }
}