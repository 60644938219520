.FormToggle {
    display: flex;
    align-items: center;
    user-select: none;

    &__toggle {
        margin-left: 8px;
        margin-right: 8px;
    }

    &__label {
        cursor: pointer;
        transition: color 300ms ease-in;

        &.is-active {
            color: white;
        }
    }
}