@import '../../styles/variables';

.notification-popup {
    z-index: 3;
    position: absolute;
    bottom: 45px;
    right: 0;
    width: 200px;
    height: 90px;
    border-right: 8px solid #2DC0E6;
    background-color: #181818;
    color: #fff;
    font-family: $font_main;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    padding: 25px 15px 10px 20px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 300ms, transform 300ms;
    transform: translateY(30px);

    &--show {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
    }

    &--wide {
        width: 560px;
    }

    &--haiku {
        width: 290px;
        height: 75px;
    }

    &--table {
        background: rgba(246, 120, 78, 0.9);
        border-radius: 4px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        border-right: none;
        font-weight: 700;
        font-family: "DM Sans", sans-serif;
        padding: 12px 24px;
        bottom: 12px;
    }

    &--page {
        background: rgba(246, 120, 78, 0.9);
        border-radius: 4px;
        border-right: none;
        font-weight: 700;
        font-family: "DM Sans", sans-serif;
        padding: 12px 24px;
        position: absolute;
        right: 32px;
        top: 24px;
        bottom: auto;
    }

    &__link {
        color: #2DC0E6;
        background-color: transparent;
        border: 0;
        text-decoration: underline;
        font-size: inherit;
        padding: 0;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    &__close {
        cursor: pointer;
        font-size: 12px;
        position:absolute;
        top: 5px;
        right: 14px;
        background-color: transparent;
        border: 0;
        color: #fff;

        &-icon {
            position: relative;
            top: 2px;
            left: 4px;
        }
        &:hover {
            color: darken(#fff, 10%);
        }
    }
}
