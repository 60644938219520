.EmptyContactCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 275px;
    padding: 16px 0;
    background: rgba(37, 47, 61, 0.4);
    border: 2px dashed rgba(white, .25);
    box-sizing: border-box;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: #303D4E;
    }

    &__icon {
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover {
            opacity: .8;
        }
    }

    &__title {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 700;
    }
}