.Scores {
    color: #fff;
    background: #141932;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;

    &__wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;

        .is-blurry & {
            filter: blur(4px);
        }
    }

    &__list {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &__message {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        cursor: default;
        position: absolute;
        left: 0;
        top: 0;
    }

    &__item {
        display: flex;
        font-size: 14px;
        width: 194px;
        padding-bottom: 2px;
        line-height: 1;
    }

    &__label {
        $width: 105px;
        white-space: nowrap;
        width: $width;
        min-width: $width;
        text-align: right;
        transform: scaleX(0.95) scaleY(0.9);
        letter-spacing: 0.4px;

        &:after {
            content: ":"
        }
    }

    &__value {
        $width: 30px;
        width: $width;
        min-width: $width;
        transform: scaleX(0.98) scaleY(0.9);
        text-align: right;
        font-size: 13px;
    }

    &__total {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &__total-title,
    &__total-value {
        color: #FFC107;
        font-weight: 700;
        font-family: 'Source Sans Pro', sans-serif;
    }

    &__total-title {
        text-transform: uppercase;
        font-size: 12px;
        transform: scaleX(1.2);
    }

    &__total-value {
        font-size: 40px;
        line-height: 1;
        transform: scaleX(1.25);
    }

    &__total-label {
        font-weight: 400;
        font-size: 12px;
        transform: scaleX(0.9);
    }
    &__stars {
        $colors: [#F97960, #8F7161, #C57AEB, #00b0f0, #1AAF54];

        min-width: 50px;
        display: flex;
        align-items: center;
        padding-left: 4px;

        @for $i from 1 through 5 {
            &--#{$i} {
                fill: #{nth($colors, $i)};
            }
        }

        & > svg {
            margin-left: 2px;
            margin-top: -1px;
            width: 10px;
            height: 10px;
        }
    }
}