.SectionHeader {
    @import "../../../pages/ChainPage/ChainPage.helpers.styles";

    @include section-full;
    position: relative;
    border-bottom: 1px solid color("border");
    margin-top: -$section-gap;
    padding-left: $section-gap;
    padding-right: $section-gap;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        padding: 32px 0;

        @media screen and (min-width: 1440px) {
            max-width: $container;
        }
    }

    &__title {
        font-size: 48px;
        line-height: 1;
        font-weight: 700;
        display: flex;
        align-items: center;
        position: relative;
        user-select: none;
        flex: 1;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -4px;
            height: 4px;
            width: 60px;
            background: linear-gradient(to left, #56CCF2, #1485C6);
            border-radius: 10px;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
    }
}
