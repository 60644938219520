$gap: 24px;

.Grid {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + #{$gap});
    margin-left: -($gap * 0.5);
    margin-right: -($gap * 0.5);
}

.GridTile {
    flex: 0 0 100px;
    margin-left: $gap * 0.5;
    margin-right: $gap * 0.5;
    margin-bottom: $gap;
}
