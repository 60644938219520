@import '@styles';

.FoodProfileHeatScore {
    position: relative;
    overflow: hidden;
    color: #303030;

    &__round-box {
        height: 44px;
        width: 160px;
        padding: 10px;
        border-style: solid;
        border-width: 2px;
        border-radius: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 0;
        font-weight: 700;

        &--small {
            height: 20px;
            width: 90px;
            padding: 5px 10px;
            border-radius: 10px;
            border-width: 1px;
            font-size: 10px;
        }
    }

    &__top {
        height: 130px;
        display: flex;
        flex-direction: column;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        height: 127px;
    }

    &__title {
        text-align: left;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 5px;

        @include media-max(1400px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &__center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 1 100%;
    }

    &__metrics {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 18px;
        padding: 0 10px;
    }

    &__metric {
        flex: 1 1 28%;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        
        .is-positive {
            color: #06b050;
        }
        .is-negative {
            color: #fe2221;
        }

        @include media-max(1400px) {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &__info {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#2C2C2C, .9);
        color: #ffffff;
        font-weight: 300;
        transition: all 500ms;

        b {
            font-weight: 700;
        }

        &.is-shown {
            transform: translateY(-100%)
        }
    }

    &__info-top {
        height: 90px;
        font-size: 12px;
        line-height: 14px;
        @include media-max(1400px) {
            font-size: 10px;
            line-height: 12px;
        }
    }

    &__info-bottom {
        height: 165px;
        font-size: 12px;
        line-height: 14px;

        @include media-max(1400px) {
            font-size: 10px;
            line-height: 12px;
        }
    }

    &__config-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 5px;
    }
}