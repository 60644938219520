.MenuDetails {
    display: flex;

    &__table {
        width: 55%;
        margin-right: 5%;
    }

    &__buttons {
        display: flex;
        width: 40%;
    }

    &__column {
        &.is-food {
            width: 66.666%;
            display: flex;
            flex-wrap: wrap;
        }

        &.is-beverage {
            width: 33.333%;
            margin-left: 8px;
        }
    }

    &__group {
        min-width: 100%;

        &.is-large {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__button {
        width: calc(50% - 4px);
        margin-top: 8px;
        height: 100px;

        &:nth-child(even) {
            margin-left: 8px;
        }
    }
}