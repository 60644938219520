@import '@styles';

.FoodProfileMenuItems {
    $root: &;
    width: 100%;
    padding: 45px 0;
    position: relative;
    transition: all 300ms;

    &--centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &--hidden {
        display: none;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        min-height: 0;

        &.is-centered {
            flex-direction: column;
            align-items: center;
            height: 140px;
        }

        &:after {
            content: none;
        }
    }

    &__trial-button {
        text-align: center;
        transition: all 300ms;
        cursor: pointer;
        display: block;
        text-decoration: none;
        margin-left: 10px;
        margin-right: 10px;
        background: #01428E;
        color: #fff;
        height: 30px;
        font-family: $font_text;
        font-size: 16px;
        line-height: 30px;
        margin-top: 20px;
        border-color: transparent;
        padding-left: 20px;
        padding-right: 20px;
        text-transform: uppercase;
        min-width: 140px;
        white-space: nowrap;

        &:hover, &--hover {
            background: rgba(#3D8CDE, .7);
            color: #fff;
        }

        &--hover {
            cursor: default;
        }

        &--green {
            background: #86D004;
            height: 24px;
            padding: 0;
            font-size: 12px;
            line-height: 24px;
            margin: 0;

            &:hover {
                background: orange;
                color: #fff;
            }
        }
    }

    &__purchase-title {
        display: inline-block;
        margin: 5px 0;
    }

    &__purchase-link {
        font-size: 12px;
        color: #3D8CDE;
        display: block;
        text-align: center;
        padding: 0 10px;
        cursor: pointer;
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;

        &:hover {
            text-decoration: underline;
        }

        &--light {
            color: inherit;
        }
    }

    &__purchase-overlay {
        background: rgba(#fff, .95);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 14px;
        color: #4F4F4F;
        padding: 10px 0 20px;

        &--hover {
            transform: translateY(100%);
            background: rgba(79, 79, 79, 0.9);
            transition: all 300ms;
            color: #ececec;
            line-height: 14px;
            font-size: 12px;
        }

        &--opened {
            transform: translateY(0);
        }
    }

    .visibility-sensor-after {
        width: 1px;
        height: 7500px;
        max-height: 100%;
        opacity: 0;
        z-index: -1;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__wrapper {
        margin: 0 auto;
        outline: none;

        &--chain-details {
            margin: 0;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 30px;
        color: #4A4A4A;
        margin-bottom: 10px;
        font-weight: 300;
        display: flex;

        div {
            color: #000;
            font-weight: 700;
        }
    }

    &__button {
        height: 20px;
        background: transparent;
        font-weight: 500;
        font-size: 22px;
        line-height: 20px;
        box-sizing: border-box;
        display: inline-block;
        padding: 0 10px;
        text-align: center;
        transition: all 300ms;
        cursor: pointer;
        color: #8D8D8D;
        letter-spacing: 1px;
        border: 0;
        border-right: 1px solid #000;

        &--active {
            color: #000000;
        }

        &:last-child {
            border: 0;
        }

        &:hover {
            color: $mtd_chart_point_bg;
        }
    }

    &__button-group {
        float: right;
    }

    &__chart {
        margin: 0 auto;
        height: 150px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        &--translated {
            & svg {
                transform: translateX(-10px);
            }
        }
    }

    .grid-sizer {
        width: 300px;
    }

    .gutter-sizer {
        width: 10px;
    }

    &__element {
        width: 300px;
        display: inline-block;
        text-decoration: none;
        border: 1px solid #EAEAEA;
        margin-bottom: 10px;
        background: #fff;
        font-size: 12px;
        line-height: 14px;
        box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.04);
        transition: transform 300ms;

        &--visible {
            transform: translateY(0);
            opacity: 1;
        }

        &--clickable {
            cursor: pointer;
        }

        &:hover {
            box-shadow: 0 5px 25px 0 rgba(#5c5c5c, .5);
            transform: translateY(-5px);
            z-index: 100;

            #{$root}__hover-panel {
                display: block;
                box-shadow: 0 5px 25px 0 rgba(#5c5c5c, .5);
            }
        }

        &--link {
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__element-sup-title {
        color: #3283DC;
        margin-bottom: 4px;
        line-height: 12px;
    }

    &__element-title {
        font-size: 16px;
        font-weight: 700;
        position: relative;
        line-height: 18px;
        margin-bottom: 10px;
        padding-bottom: 6px;
        text-transform: uppercase;
        color: #3A414C;

        &:after {
            //content: '';
            display: block;
            width: 100%;
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #000;
            border-radius: 1.5px;
        }
    }

    &__element-description {

    }

    &__element-chart-container {
        position: relative;
        height: 110px;
        width: calc(100% + 30px);
        transform: translateX(-15px);
        margin: 10px 0 0;
        overflow: hidden;
    }

    &__element-info {
        margin-top: 5px;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;

    }


    &__item-wrapper {
        padding: 15px 15px 0;
        width: 100%;
    }

    &__element-image-wrapper {
        width: 100%;
        position: relative;
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 15px;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__element-image {
        max-width: 100%;
        max-height: 100%;

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            /* IE10+ specific styles go here */
            width: 100%;
        }
    }

    &__additional-info {
        width: 100%;
        height: 36px;
        background: #EAEAEA;
        padding: 10px 15px;
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
    }

    &__date-info {
        font-size: 12px;
        width: calc(100% - 70px);
        display: inline-block;
    }

    &__price-info {
        font-size: 12px;
        width: 70px;
        display: inline-block;
        text-align: right;
    }

    .spinner {
        position: relative;
        margin-top: 40px;
    }

    &__hover-panel {
        display: none;
        position: absolute;
        top: 100%;
        left: -1px;
        right: -1px;
    }

    &__grid-sizer {
        &--chain-details {
            width: 235px;
        }
    }

    &__gutter-sizer {
        width: 10px;

        &--chain-details {
            width: 20px;
        }
    }
}
