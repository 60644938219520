.DocumentView {
    position: relative;
    min-height: 100%;
    padding: 10px 50px 65px;
    background-color: #FCFCFC;

    &.is-mac {
        padding: 0;
    }

    &__header {
        margin-bottom: 12px;
    }

    &__backward {
        text-decoration: none;
        color: #2D9CDB;

        &:hover {
            text-decoration: underline;
        }
    }
}