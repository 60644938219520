@import '@styles';

.Chart {
    position: relative;
    $root: &;

    > svg {
        width: 100%;
        height: 100%;
    }

    path {
        transition: d 500ms;
    }

    &__axis {
        pointer-events: none;
        font-family: "Work Sans", "Source Sans Pro", sans-serif;
        font-size: 16px;

        @include media-max(1400px) {
            font-size: 12px;
        }
    }

    &__curve {
        position: relative;
        z-index: 2;
    }

    &__area {
        position: relative;
        z-index: 1;
    }

    &__dots {
        position: absolute;
    }

    &__prediction-thumb-container {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        transition: transform 300ms, height 300ms;
        width: 100px;
        cursor: pointer;

        &:hover {
            #{$root}__prediction-thumb-title:after {
                transform: translate(5px, -50%);
            }
        }
    }

    &__prediction-thumb {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 50px;
        color: #ffffff;
        text-align: right;
        padding-right: 50px;
        padding-top: 10px;
    }

    &__prediction-thumb-title {
        position: relative;
        font-size: 32px;
        line-height: 35px;
        color: transparent;
        -webkit-background-clip: text;
        animation: text-gradient 2.5s linear infinite;
        background-image: linear-gradient(to left, #fff 30%, #00EBF5 50%, #fff 70%);
        //background-image: linear-gradient(45deg, #fff, #00EBF5, #fff, #00EBF5, #fff);
        background-size: 400px 400px;
        
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: calc(100% + 5px);
            top: 50%;
            transform: translateY(-50%);
            height: 0;
            width: 0;
            border-width: 8px 13px;
            border-style: solid;
            border-color: transparent;
            border-left-color: #fff;
            transition: transform 300ms;
        }
    }
    &__prediction-thumb-sub-title {
        font-family: "Muli", sans-serif;
        font-size: 15px;
        line-height: 20px;
        white-space: nowrap;
        font-weight: 900;
    }

    &__tooltip {
        z-index: 4;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 300ms 200ms;

        &.is-shown {
            opacity: 1;
            transition: opacity 200ms 0s;
        }
    }

    &__future-label {
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        color: #fff;
        font-size: 14px;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            height: 8px;
            width: 36%;
            border-style: solid;
            border-color: #fff;
        }

        &:before {
            left: 0;
            border-width: 0 0 1px 1px;
        }

        &:after {
            right: 0;
            border-width: 0 1px 1px 0;
        }
    }
}

.ChartDot {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Comfortaa","Source Sans Pro", sans-serif;

    transition: transform 500ms;

    &__inner {
        font-size: 12px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2px;
        transition: transform 500ms, background 500ms;

        @include media-max(1200px) {
            width: 30px;
            height: 30px;
            font-size: 10px;
        }

        &.is-hoverable {
            cursor: pointer;

            &:hover {
                transform: translate(-50%, -50%) scale(1.3);
            }
        }

        &--solid {
            background: #8ebced;
            box-shadow: 0 0 0 0 #8ebced;

            &.is-hoverable {
                &:hover {
                    background: #fd8b60;
                }
            }
        }

        &--solid-active {
            background: #fd8b60;
        }

        &--dashed {
            background: #4C7AE8;
            border: 1px dashed #fff;
            box-shadow: 0 0 0 0 #4C7AE8;
        }

        &--dashed-active {
            background: #4C7AE8;
            border: 1px dashed #E06C41;
            box-shadow: 0 0 0 0 #4C7AE8;
        }
    }
}

@keyframes haiku_pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}


.ChartTabs {
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1;
    padding: 0 20px;
}

.ChartTab {
    background-color: transparent;
    border: 0;
    height: 60px;
    font-size: 10px;
    line-height: 60px;
    color: #fff;
    transition: all 500ms;
    position: relative;
    padding: 0;
    cursor: pointer;
    outline: 0;

    @include media-max(1400px) {
        font-size: 8px;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        background: currentColor;
        height: 2px;
        transition: all 500ms;
    }

    &:hover {
        &:after {
            width: 30px;
        }
    }

    &--active {
        font-weight: 700;
        font-size: 13px;
        color: #fd8b60;

        @include media-max(1400px) {
            font-size: 10px;
        }

        &:after {
            width: 100%;
        }
    }
}

@keyframes text-gradient {
    from {
        background-position-x: 0;
    }
    to {
        background-position-x: 400px;
    }
}