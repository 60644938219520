.BrandsHeader {
    margin-bottom: 24px;
    max-width: 100%;
    min-width: 100%;

    &__title {
        font-size: 18px;
        line-height: 1;
        display: flex;
        align-items: center;
        font-weight: 700;

        &.is-center {
            justify-content: center;
        }

        .is-lead & {
            font-size: 32px;
            font-weight: 500;
        }
    }

    &__sub-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 2;
        min-height: 24px;
        margin-top: 4px;
        color: #676D75;
    }

    &__title-icon {
        margin-right: 12px;
    }

    &__copy {
        position: relative;
        top: 0;
        margin-left: 16px;
    }
}