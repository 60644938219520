$colors: (
    "primary": #2d9cdb,
    "accent": #86d004,
    "red": #f44336,
    "info": #32C1E9,
    "page-bg": #101215,
    "section-bg": #131921,
    "modal-bg": #1C2531,
    "link": #56CCF2,
    "border": #252F3D
);

$container: 1680px;
$section-gap: 16px;
$font-primary: 'DM Sans', sans-serif;

@function color($color) {
    @return map-get($colors, $color);
}

@mixin section {
    background: color("section-bg");
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    padding: 24px;
}

@mixin section-full {
    width: calc(100% + #{$section-gap * 2});
    margin-left: -$section-gap;
}

@mixin section-divider($pos: top) {
    &:after {
        @include section-full;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        #{$pos}: 0;
        left: 0;
        background: color("border");
    }
}