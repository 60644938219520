.LTO {
    @import "../../ChainPage.helpers.styles";

    $gap: 24px;

    position: relative;

    &__title {
        font-weight: 500;
        font-size: 32px;
        padding-bottom: 24px;
    }

    &__launches {
        position: relative;
        padding-top: $gap;
        padding-bottom: 16px;
        margin-bottom: 40px;
        @include section-divider("bottom");
    }

    &__loader {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        position: relative;
        height: 48px;
        position: relative;
        margin-bottom: -16px;
        background: #1F2834;
        @include section-full;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        padding-right: 4px;
    }

    @mixin action-link {
        text-decoration: none;
        color: color("link");
        text-decoration: underline;
        font-size: 13px;
        font-weight: 700;
        position: relative;
        z-index: 1;
        text-transform: none;
    }

    &__actions-item {
        flex: 0;
        @include action-link;
    }

    &__launches-actions {
        display: flex;
        justify-content: flex-end;
    }

    &__launches-actions-item {
        @include action-link;
        min-width: 0;
        width: auto;
    }
}