.FoodTabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &__item {
        font-size: 12px;
        font-weight: 300;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        transition: font-weight 300ms;

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            height: 0;
            background: currentColor;
        }

        &.is-active {
            font-weight: 700;

            &:after {
                height: 2px;
                transition: height 300ms;
            }
        }
    }
}