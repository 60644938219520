@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white, $direction: right) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
    &:before {
        content: '...';
        position: absolute;
        right:if($direction == right, 0, auto);
        left: if($direction == bottom, 0, auto);
        bottom: 0;
        width: if($direction == bottom, 100%, auto);
        background-color: if($direction == bottom, $bgColor, $bgColor);
        border-top: if($direction == bottom, 1px solid $bgColor, 0px solid $bgColor);
    }
    &:after {
        content: '';
        position: absolute;
        right: if($direction == right, 0, auto);
        left: if($direction == bottom, 0, auto);
        width: 1em;
        height: $lineHeight * $lineCount;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
