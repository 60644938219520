.Contacts {
    @import "../../ChainPage.helpers.styles";

    $root: &;
    position: relative;
    // min-height: 75vh;

    &__section {
        position: relative;
        padding-top: 32px;
        padding-bottom: 32px;

        & + & {
            @include section-divider("top");
        }

        &:last-child {
            padding-bottom: 0;
        }

        &.is-empty {
            padding-bottom: 16px;
        }
    }

    &__header-actions {
        display: flex;
        align-items: center;
        color: #5E6C81;
        font-size: 13px;
        flex: 1;
    }

    &__header-add {
        margin-left: auto;
    }

    &__section-title {
        font-size: 32px;
        font-weight: 500;
        margin-right: 32px;
    }

    &__section-header {
        display: flex;
        align-items: center;
    }

    &__view {
        display: flex;
        background: #131921;
        border-radius: 24px;
        height: 40px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 1;
    }

    &__actions-item {
        margin-left: 24px;
    }

    &__search-input {
        height: 35px;
        width: 190px;
        border: none;
        background: transparent url("../../../../icons/search_new.svg") no-repeat left center;
        padding: 0;
        appearance: none;
        border-bottom: 1px solid #4a6282;
        padding-left: 32px;
        color: white;
        font-weight: 400;
        font-size: 14px;
        padding-right: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &::placeholder {
            color: #4a6282;
        }
    }

    &__items {
        position: relative;
        margin-top: 24px;

        &.is-empty {
            margin-top: 0;
        }
    }

    &__loader {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background: color("page-bg");
        opacity: 0.75;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &__no-data {
        margin-left: auto;
        margin-right: auto;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 32px;
    }

    &__no-data-message {
        background: #252F3B;
        padding: 10px 16px;
        color: #fff;
        font-size: 14px;
        line-height: 1.2;
        border-radius: 4px;
        text-align: center;
    }
}
