@import '@styles';

.FoodProfile {
    width: 100%;
    box-sizing: border-box;
    padding: 30px 30px 0;
    background: #F8F6F6;

    &__section {
        max-width: 1540px;
        width: calc(100vw - 200px);
        margin: 0 auto 40px;
        min-height: 275px;
        position: relative;
        z-index: 2;

        @include media-max(1400px) {
            width: calc(100vw - 130px);
        }
    }

    &__flex-container {
        display: flex;
        margin-bottom: 10px;
        position: relative;

        &--fixed-height {
            height: 310px;
        }
    }

    &__title {
        color: #4A4A4A;
        font-size: 36px;
        font-weight: 300;
        line-height: 40px;
        margin-bottom: 10px;
    }
}

.FoodProfileWidgetSection {
    position: relative;
    z-index: 100;
    min-height: 270px;
    margin-bottom: 40px;
    transition: filter 300ms;
    transition-timing-function: linear;
}