.SelectCompany {
    font-size: 14px;

    &__input {
    }

    &__options {
        height: 150px;
    }
}
