.TableNew {
    @import "@styles";
    $root: &;
    $head-height: 60px;
    $light_theme: #{$root}--theme-light;

    position: relative;
    font-weight: 400;
    width: 100%;
    height: 100%;

    &__container {
        height: 100%;
    }

    &__tooltip {
        background: #252F3B;

        @media screen and (max-width: 992px) {
            visibility: hidden;
        }

        &:after {
            border-top-color: #252F3B;
        }
    }

    &__row,
    &__head {
        display: flex;
    }

    &__head {
        //background: #101215;
        height: $head-height;
        border-bottom: 2px solid #50637C;


        svg {
            fill: currentColor;
            margin-right: 5px;
            min-width: 11px;
            min-height: 10px;
        }
    }

    &__row {
        border-bottom: 1px solid #323840;

        #{$light_theme} & {
            border-color: #ccc;
        }

        &:hover {
            background: #1F2328;

            #{$light_theme} & {
                background: #eeeeee;
            }

            #{$root}__cell.is-toggle-visible > * {
                opacity: 1;
            }
        }
    }

    &__body-wrapper {
        height: calc(100% - #{$head-height});
        min-height: 40px;
    }

    &__copy {
        width: 32px;
    }

    &__cell {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 1.2;
        padding-left: 24px;
        color: $chains-primary;
        min-width: 0;
        flex-grow: 1;
        flex-shrink: 1;

        #{$light_theme} & {
            color: #000;
        }

        @media screen and (max-width: 1200px) {
            min-width: 80px;
        }

        &.is-head {
            font-weight: 700;
            font-size: 14px;
            line-height: 1;
            color: #676D75;
        }

        &.is-hoverable {
            cursor: pointer;

            &:hover {
                color: #fff;

                #{$light_theme} & {
                    color: #000;
                }
            }
        }

        &.is-active {
            color: #fff;

            #{$light_theme} & {
                color: #000;
            }
        }


        &.is-toggle-visible > * {
            opacity: 0;
        }

        &:hover {
            #{$root}__tooltip {
                display: block;
            }
        }

        span {
            & > b {
                background: #E41E42;
                color: #fff;
                font-weight: 400;
                text-shadow: 1px 1px 1px rgba(black, .5);
            }
        }
    }

    &__loader {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__no-data {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__copy-button {
        position: absolute;
        right: 0;
        top: 15px;
        z-index: 1;
        width: 32px;
        height: 32px;

        &.is-no-scroll{
            right: 0;
        }

        &.is-no-head {
            top: -85px;
        }
    }

    &__copy {
        cursor: pointer;
        user-select: none;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(#56CCF2, .12);
        border-radius: 8px;

        &:hover {
            opacity: 0.8;
        }
    }

    &--auto-height {
        height: auto;

        #{$root}__container {
            height: auto;
        }

        #{$root}__no-data {
            padding: 16px 0;
        }
    }
}

.TableCellValue {
    max-width: 100%;
    overflow-wrap: break-word;

    &.is-wrap {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}