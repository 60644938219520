.Profile {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__section {
        display: flex;
        margin-bottom: 16px;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;

        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
        }

        .is-compact-view & {
            display: block;
        }
    }

    &__map {
        margin-right: 8px;
        width: calc(50% - 8px);

        @media screen and (max-width: 992px) {
            margin-right: 0;
            width: 100%;
            margin-bottom: 16px;
        }

        .is-compact-view & {
            float: left;
        }
    }

    &__map-wrapper {
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        padding-bottom: 60%;
    }

    &__info-blocks {
        display: flex;
        flex-wrap: wrap;
        margin-left: 8px;
        width: calc(50% - 8px);

        @media screen and (max-width: 992px) {
            width: 100%;
            margin-left: 0;
        }

        & > div {
            margin-bottom: 16px;
        }
    }

    &__info-block {
        $size: calc(50% - 8px);

        width: $size;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        &:nth-child(odd) {
            margin-left: 16px;

            @media screen and (max-width: 1200px) {
                margin-left: 0;
            }
        }

        &:first-child {
            width: 100%;
            margin-left: 0;
        }

        & > div {
            min-height: 40px;
        }
    }

    &__row {
        min-width: 100%;
        margin-top: 16px;

        .is-compact-view & {
            width: calc(50% - 8px);
            min-width: 0;
            float: left;
            margin-top: 0;

            @media screen and (max-width: 992px) {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    &__screenshot {
        width: 744px;
        height: 444px;

        position: absolute;
        left: -9999px;
        top: -9999px;
        opacity: 0;
    }
}

.GroupGrow {
    padding-top: 4px;

    &__value {
        display: block;
        margin-top: 8px;
        font-weight: 700;
        font-size: 16px;

        &.value-is-zero {
            color: #fff;
        }
    }
}
.Profile-info-block {
    display: flex;
    width: 100%;

    &__label,
    &__table {
        flex: 1;
    }

    &__label {
        max-width: 75px;
        color: #676D75;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.2;

        @media screen and (max-width: 1200px) {
            max-width: 100px;
        }
    }

    &__table {
        max-width: calc(100% - 75px);
        margin-left: auto;

        &:only-child {
            max-width: none;
        }

        @media screen and (max-width: 1440px) {
            max-width: none;
        }

        @media screen and (max-width: 1200px) {
            max-width: calc(100% - 100px);
        }
    }
}