.Brands {
    @import "../../ChainPage.helpers.styles";
    $root: &;
    $gap: 24px;
    $charts-size: 170px;

    position: relative;

    &__content {
        position: relative;
        margin-top: $gap;
    }

    &__section {
        padding-top: $gap;
        padding-bottom: $gap;
        position: relative;

        & + & {
            @include section-divider;
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__footer {
        background: color("page-bg");
        position: relative;
        z-index: 1;
        text-align: right;
    }

    &__footer-action {
        color: color("link");
        display: inline-flex;
        width: auto;
        text-transform: none;
        font-weight: 700;
        font-size: 13px;
        background: none;
        text-decoration: underline;
        min-width: 0;

        &:hover {
            text-decoration: none;
        }
    }
}
