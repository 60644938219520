.Menu {
    @import "../../ChainPage.helpers.styles";

    &__header-actions {
        display: flex;
    }

    &__header-action {
        margin-left: 16px;
    }

    &__sections {
        position: relative;
    }

    &__section {
        position: relative;
        padding: 48px 0;

        & + & {
            @include section-divider;
        }

        &:last-child {
            padding-bottom: 24px;
        }
    }

    &__details {
        position: relative;
    }

    &__footer {
        @include section-full;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 48px;
        position: relative;
        margin-bottom: -$section-gap;
        font-size: 14px;
        color: #9199A5;
        background: #1F2834;
        padding: 0 24px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    &__footer-text,
    &__footer-actions {
        position: relative;
        z-index: 1;
    }

    &__footer-link {
        color: color("link");
        display: inline-flex;
        width: auto;
        text-transform: none;
    }
}