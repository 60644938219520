.SectionContent {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 9; // index must be less than z-index header
    min-height: 370px;

    &.is-compact {
        min-height: 170px;
    }

    &__loader {
        flex: 1;
    }
}