$progress_bar_size: 30px;
$progress_bar_white: rgba(#fff, 0.8);
$progress_bar_black: rgba(#000, 0.3);

@keyframes ProgressBar {
    from {
        background-position: 0 0;
    }
    to {
        background-position: $progress_bar_size 0;
    }
}

.ProgressBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
            -60deg,
            $progress_bar_white 0%,
            $progress_bar_white 25%,
            $progress_bar_black 25%,
            $progress_bar_black 75%,
            $progress_bar_white 75%,
            $progress_bar_white 100%
    );
    background-size: $progress_bar_size 100%;
    animation: ProgressBar 500ms infinite linear;
}