.ItemYoutube {
    background: #131921;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    &__thumbnail {
        padding-bottom: 56%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        transition: opacity 300ms ease;

        &:hover {
            opacity: 0.8;
        }
    }

    &__description {
        padding: 16px;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 500;
    }
}