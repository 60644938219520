@use '@datassential/platform-ui-lib/lib/style/constants' as *;
@use '@datassential/platform-ui-lib/lib/style/utils' as *;
@import "../../style.scss";
@import "ChainPage.helpers.styles";

.ChainPage {
    @import "../../styles/helpers/ie.scss";

    $page-min-height: calc(100vh - #{$header_height});

    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #181B20;
    font-family: "DM Sans", sans-serif;
    flex-direction: column;
    min-height: calc(100vh - #{$platform-navigation-height} - #{$platform-header-height});

    &__modal {
        font-family: "DM Sans", sans-serif;
    }

    &__header {
        position: sticky;
        top: calc(#{$platform-header-height} - 1px);
        z-index: 10;
        width: 100vw;
        left: 0;
        max-height: 213px;
        margin-bottom: auto;

        @include onlyIE {
            position: relative;
        }

    }

    &__spacer {
        height: 213px;
        width: 100%;
        margin-bottom: -213px;
    }

    &__profile-carousel {
        position: relative;
        width: 50%;
        padding-bottom: 33.333%;

        & > .ImagesGallery {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: $page-min-height;
        color: #fff;
    }

    &__section {
        position: relative;
        width: 100%;
        background: #101215;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;
        min-height: 300px;

        &.is-full {
            height: calc(100vh - 300px);
        }
    }

    &__map {
        position: relative;
        width: 50%;
        max-width: 660px;
        height: 400px;
        border-radius: 4px;
        overflow: hidden;
    }

    &__description {
        margin-top: 16px;
        font-size: 14px;
        line-height: 16px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding: 0 16px;
        color: #ffffff;
        width: 100%;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: 1300px) {
            padding: 0 32px;
        }

        @media screen and (min-width: 1680px) {
            max-width: $container;
        }
    }

    &__info-top {
        display: flex;
        justify-content: center;
    }

    &__info-top-right {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        color: color("info");
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }



    .InfoBlock__details {
        margin-left: 100px;
    }
}
