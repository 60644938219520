@import '@styles';

.FoodProfileRecommendedCharts {
    $root: &;
    background: #F8F6F6;
    border-radius: 3px;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.18);
    max-height: 260px;
    height: auto;
    margin-bottom: 0;
    font-size: 12px;

    &.is-opened {
        max-height: none;
        height: auto;
    }

    @include media-max(1440px) {
        max-height: none;
    }

    &:first-child {
        margin-left: 0;
        width: grid_width(1, 2, 1240px, 25px);
    }

    &:first-child:last-child {
        width: 100%;
        margin-left: 0;
    }

    &__body {
        flex-direction: column;
        padding: 15px 20px;
        min-height: 210px;
    }

    &__links {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
    }

    &__link {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        line-height: 18px;
        width: calc((100% - 70px) / 3);
        height: 27px;
        display: flex;
        align-items: flex-end;
        margin-bottom: 0;
        text-decoration: none;
        border-right: 1px solid #979797;
        margin-left: 35px;

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            /* IE10+ specific styles go here */
            width: calc((100% - 71px) / 3);
        }

        @supports (-ms-accelerator:true) {
            /* Edge 12+ CSS */
            width: calc((100% - 71px) / 3);
        }

        @supports (-ms-ime-align:auto) {
            /* Edge 16+ CSS */
            width: calc((100% - 71px) / 3);
        }

        &:nth-child(3n), &:last-child {
            border: 0;
        }

        &:nth-child(3n+1) {
            margin-left: 0;
        }

        .is-short &:nth-child(n+16) {
            display: none;
        }

        @include media-max(1440px) {
            width: 100%;
            max-width: 50%;
            margin-left: 0;

            &:nth-child(3n), &:last-child {
                border-right: 1px solid #979797;
            }

            &:nth-child(2n) {
                padding-left: 35px;
            }

            &:nth-child(2n) {
                border: 0;
            }

            .is-short &:nth-child(n+15) {
                display: none;
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__type {
        font-size: 12px;
        line-height: 18px;
        color: #828282;
        display: inline-block;
        vertical-align: bottom;
    }

    &__link-name {
        display: inline-block;
        vertical-align: bottom;
        line-height: 22px;
        margin-right: 8px;
    }
}