.LTOItem {
    @import "../../ChainPage/ChainPage.helpers.styles";

    $root: &;


    position: relative;
    color: #000;
    height: 453px;
    font-family: $font-primary;
    font-size: 14px;
    line-height: 1;
    transform-origin: top;
    transition: transform 500ms ease, z-index 0s 500ms;
    backface-visibility: hidden;
    text-decoration: none;
    display: block;
    background: #fff;

    &.is-clickable {
        cursor: pointer;
    }

    &__wrapper {
        position: relative;
        min-height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.8);
    }

    &__header {
        padding: 12px;
        background: #fff;
        margin-bottom: -1px;
    }

    &__sub-title {
        color: color("info");
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        line-height: 1.2;
    }

    &__content {
        background: #fff;

        &.is-blank {
            height: 340px;
        }
    }

    &__title {
        font-size: 18px;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
        height: 44px;
        overflow: hidden;
    }

    &__download {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 3;
        width: 40px;
        height: 40px;
        background: color("info");
        color: #fff;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        font-size: 0;
        line-height: 0;
        cursor: pointer;

        &--loading {
            cursor: progress;
            opacity: 0.8;
        }

        &:hover {
            background-color: darken(color("info"), 10%);
        }
    }

    &__image-wrapper {
        height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        display: block;
        max-width: 100%;
        max-height: 100%;

        &.is-cover {
            min-width: 100%;
            object-fit: cover;
        }
    }

    &__description {
        display: block;

        &-hover {
            display: none;
        }
    }

    &__description-wrapper {
        $line: 18px;
        $description-max-lines: 3;
        $height: ($description-max-lines * $line) + 16px;

        padding: 12px;
        overflow: hidden;
        font-size: 13px;
        line-height: $line;
        min-height: $height;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 8px;
            width: 100%;
            background: #fff;
            user-select: none;
            pointer-events: none;
        }
    }

    &__link {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
    }

    &__scores {
        height: 110px;
        position: relative;
        z-index: 3;
        overflow: hidden;

        &.is-blank {
            background: #fff;
            height: 0;
        }
    }

    &__footer {
        position: relative;
        z-index: 3;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;
        font-size: 12px;
        background: #F3F3F3;
    }

    &__scores-attention {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #252F3B;
        z-index: 1;
        flex-direction: column;
        font-size: 13px;
        color: #fff;
        cursor: default;

        &-title {
            text-align: center;
        }

        &-button {
            padding: 7px 15px;
            background: color("info");
            color: #fff;
            border-radius: 15px;
            margin: 8px 0;
            font-size: 13px;
            line-height: 1;
            cursor: pointer;
            width: 130px;

            &:hover {
                background: darken(color("info"), 15%);
            }
        }

        &-info {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(black, .7);
            transform: translateY(100%);
            transition: transform 300ms ease-in;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 10px;
            padding-left: 12px;
            padding-right: 12px;
            text-align: center;

            &.is-active {
                transform: translateY(0);
            }
        }

        &-info-open {
            color: color("primary");
            user-select: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &-info-close {
            margin-top: auto;
            user-select: none;

            &:hover {
                text-decoration: underline;
            }
        }

        &-notificaton {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(color("accent"), .9);
            transform: translateY(100%);
            transition: transform 300ms ease-in;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding-top: 20px;
            padding-bottom: 10px;
            padding-left: 12px;
            padding-right: 12px;
            text-align: center;
            font-size: 14px;
            z-index: 2;

            &.is-active {
                transform: translateY(0);
            }
        }
    }

    &:hover {
        z-index: 3;
        transition: transform 500ms ease, z-index 0s 0s;
        transform: scale(1.05) perspective(1000px);

        #{$root}__description {
            display: none;

            &-hover {
                display: block;
            }
        }

        #{$root}__wrapper {
            position: absolute;
            z-index: 2;
        }

        #{$root}__download {
            visibility: visible;
        }

        #{$root}__description-wrapper {
            height: auto;
        }

        #{$root}__content {
            height: auto;
            &.is-blank {
                min-height: 340px;
            }
        }
    }

    &__notification {
        width: 100%;
        height: 60px;
        min-width: 0;
        min-height: 0;
    }
}