.PopupCarousel {
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1001;
        width: 32px;
        height: 32px;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
    }
}