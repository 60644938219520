.Checkbox {
    @import "../../../pages/ChainPage/ChainPage.helpers.styles";

    $root: &;
    $radius: 4px;
    $border: #4A6282;
    $gap: 16px;
    $trs: all 250ms ease-in;

    position: relative;
    width: 100%;
    height: 100%;
    font-size: inherit;

    &__label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        .is-opt-group-single & {
            cursor: default;
        }
    }

    &__input {
        visibility: hidden;
        position: absolute;
        left: -9999px;
        top: -9999px;
    }

    &__icon {
        $size: 16px;

        width: $size;
        min-width: $size;
        height:$size;
        min-height: $size;
        border-radius: $radius;
        margin-right: $gap * 0.5;
        position: relative;
        overflow: hidden;
        box-shadow: inset 0 0 0 1px #4A6282;
        transition: $trs;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 40%;
            height: 40%;
            margin-left: -20%;
            margin-top: -20%;
            border-radius: 2px;
            transform: scale(0);
        }

        &.is-radio {
            box-shadow: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 0;
            width: 32px;

            .is-nested-single & {
                left: $gap * 1.5;
            }

            #{$root}__icon-image {
                left: 0;
                top: 50%;
                width: 32px;
                height: 32px;
                margin-top: -16px;
            }
        }

        &-image {
            transition: $trs;
            transform: scale(0);
            position: absolute;
            width: $size * 0.5;
            height: $size * 0.5;
            top: 50%;
            left: 50%;
            margin-left: -#{$size * 0.25};
            margin-top: -#{$size * 0.25};
            z-index: 1;
        }
    }

    &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        font-size: 100%;
        user-select: none;
    }

    &__toggle-icon {
        background: color("red");
        width: 34px;
        height: 14px;
        background: rgba(black, .4);
        border-radius: 7px;
        position: relative;
        margin-left: 10px;

        &:after {
            content: '';
            position: absolute;
            left: 10px;
            top: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            background: #fff;
            border-radius: 50%;
            transform: translateX(-20px);
            transition: transform 300ms, background-color 300ms;
        }
    }

    &__input:checked + &__toggle-icon:after {
        transform: translateX(calc(100% - 15px));
        background: color("red");
    }

    &__input:checked + &__icon {
        border-color: #32C1E9;
        box-shadow: inset 0 0 0 1px #32C1E9;

        &.is-radio {
            border-color: transparent;
            box-shadow: none;
        }

        #{$root}__icon-image {
            transform: scale(1);
            fill: #32C1E9;
        }
    }

    &--partial-checked {
        #{$root}__icon {
            &-image {
                display: none;
            }

            &:after {
                transition: $trs;
                transform: scale(1);
            }
        }
    }

    &--toggle {
        #{$root}__text {
            padding-left: 15px;
        }
    }
}
