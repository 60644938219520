@use "sass:math";

.ReportProTile {
    position: relative;
    display: block;
    width: 100%;
    padding-top: math.div(4, 3) * 100%;
    transition: transform 300ms;
    $root: &;
    color: #fff;
    font-size: 14px;

    &:hover {
        z-index: 2;
        transform: scale(1.1);

        #{$root}__content {
            opacity: 1;
            pointer-events: all;
            transform: translateY(0) scale(1);
        }
    }

    &__img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
        border-radius: 4px;
    }

    &__content {
        position: absolute;
        bottom: 50%;
        left: 50%;
        margin-left: -150px;
        width: 300px;
        min-height: 160px;
        padding: 16px;
        border-radius: 8px;
        background: #000;
        opacity: 0;
        pointer-events: none;
        transition: transform 800ms, opacity 600ms;
        transform: translateY(20px) scale(0.8);
        box-sizing: border-box;

        #{$root}.is-wide & {
            width: 350px;
            margin-left: -175px;
        }

        #{$root}.is-dyn-h & {
            min-height: 0;
        }

        .is-first-visible #{$root} & {
            left: -30px;
            margin-left: 0;
        }

        .is-last-visible #{$root} & {
            left: auto;
            right: -30px;
            margin-left: 0;
        }

    }

    &__title {
        font-size: 24px;
        line-height: 28px;
        font-weight: bold;
        font-family: 'Montserrat', 'Arial', sans-serif;
        margin-bottom: 8px;
        word-break: break-word;
        max-height: 28px * 4;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;

        #{$root}.is-dyn-h & {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__sub-title {
        font-family: Source Sans Pro, sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #868D95;
        display: flex;
    }

    &__description {
        font-family: Source Sans Pro, sans-serif;
        font-size: 14px;
        line-height: 20px;
        max-height: 80px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}