.search-results-content-widget {
    $widget: &;
    height: 275px;
    box-shadow: 0 5px 14px 0 rgba(#000, .18);
    background: #fff;
    float: left;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-radius: 3px;
    transform: translateY(0);
    opacity: 1;



    &--hidden {
        display: none;
    }

    @for $i from 1 to 4 {
        &:nth-child(#{$i}) {
            transition: opacity 300ms #{$i*200ms}, transform 300ms #{$i*200ms}
        }
    }

    &__header {
        position: relative;
        padding: 10px 25px;
        background: #F4F4F4;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        //&:after {
        //    content: '';
        //    display: block;
        //    position: absolute;
        //    bottom: 0;
        //    left: 0;
        //    width: 100%;
        //    height: 3px;
        //    border: 1px solid #EFEFEF;
        //    background: #D8D8D8;
        //    box-shadow: inset 0 1px 1px 0 rgba(#000,.5);
        //}

        & + #{$widget}__body {
            height: calc(100% - 75px);
        }
    }

    &__title {
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        color: #303030;
    }

    &__subtitle {
        color: #7A7A7A;
    }

    &__body {
        display: flex;
        position: relative;
        height: 100%;
    }

    &__section {
        height: 50%;
        display: flex;
        padding: 0 30px;
        position: relative;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: #3D8CDE;

        &:first-child {
            .border {
                display: none;
            }
        }

        & + #{$widget}__section {
            .border {
                top: -1px;
            }

            //&:after {
            //    content: '';
            //    display: block;
            //    position: absolute;
            //    top: 0;
            //    left: 0;
            //    width: 100%;
            //    height: 3px;
            //    border: 1px solid #EFEFEF;
            //    background: #D8D8D8;
            //    box-shadow: inset 0 1px 1px 0 rgba(#000,.5);
            //}
        }
    }

    .border {
        color: #3e3e3e;
    }
}
