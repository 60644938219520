.ChainPermissionPopup {
    width: 1024px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "DM Sans";
    text-shadow: 1px 1px 1px rgba(black, .9);

    &:after {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        box-shadow: 0 -70px 60px 150px rgba(7,10,14,80%);
        bottom: 0;
        pointer-events: none;

        @media screen and (max-height: 480px) {
            display: none;
        }
    }

    &__title {
        font-size: 26px;
        line-height: 1.4;
        text-align: center;
        margin-bottom: 24px;
    }

    &__image {
        max-height: 45vh;

        @media screen and (max-height: 480px) {
            display: none;
        }
    }

    &__message {
        position: relative;
        text-align: center;
        font-size: 16px;
        line-height: 1.4;

        a {
            color: #2EAFD9;
        }
    }

    &__footer {
        position: relative;
        z-index: 1;
        padding-top: 24px;
        padding-bottom: 12px;
        margin-top: -6%;

        @media screen and (max-height: 480px) {
            margin-top: 0;
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
    }

    &__actions-item {
        margin-left: 20px;
        margin-right: 20px;
        height: 50px;
        display: flex;
        align-items: center;

        .Button {
            height: 100%;
            font-size: 24px;
            font-family: "DM Sans";
            text-transform: none;
        }
    }

    &__link {
        color: #828282;
        font-size: 16px;
        line-height: 1;
    }

    &__notification {
        min-width: 550px;
        text-align: center;
        top: 50%;
        bottom: auto;
        cursor: default;
        position: absolute;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        text-shadow: none;
        padding: 16px 24px;
    }
}