html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
    //-webkit-font-smoothing: antialiased;
}
ol, ul {
    list-style: none;
}

.content {
    li {
        list-style: none;
        margin: 10px 0;
        padding-left: 20px;

        &:before {
            content: '•';
            display: inline-block;
            width: 20px;
            text-align: center;
            margin-left: -20px;
        }
    }
}

blockquote, q {
    quotes: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input, button {
    font-family: inherit;
    outline: 0;
    &::-ms-clear {
        display: none;
    }
}

a {
    color: inherit;
    &:hover {
        text-decoration: none;
    }
}

button {
    border-radius: 0;
}
