.BrandsFunnel {
    @import "../../../ChainPage.helpers.styles";

    display: flex;
    flex-wrap: wrap;

    &__image {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        z-index: 1;

        @media screen and (min-width: 1440px) {
            min-width: 100%;
            min-height: 110%;
        }

        @media screen and (max-width: 768px) {
            min-width: 100%;
            min-height: 110%;
        }
    }

    &__columns {
        display: flex;
        width: calc(50% - 8px);
        position: relative;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__column-wrapper {
        padding: 0;
        flex: 1;
        display: flex;
        flex-direction: column;

        & + & {
            margin-left: 16px;
        }
    }

    &__column {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 250px;

        @media screen and (min-width: 1680px) {
            height: 325px;
        }
    }

    &__title,
    &__value,
    &__label {
        @media screen and (min-width: 1680px) {
            position: relative;
            z-index: 1;
        }
    }

    &__title {
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #5D7A8D;
        padding-top: 8px;
    }

    &__value {
        width: 100%;
        text-align: center;
        font-weight: 900;
        font-size: 24px;
    }

    &__label {
        width: 100%;
        margin-top: auto;
        font-size: 12px;
        line-height: 1.2;
        text-align: center;
        padding-bottom: 8px;
        padding-left: 8px;
        padding-right: 8px;
    }

    &__description-wrapper {
        width: calc(50% - 8px);
        margin-left: 16px;
        display: flex;
        padding: 32px;

        @media screen and (max-width: 768px) {
            width: 100%;
            margin-left: 0;
            margin-top: 16px;
        }
    }

    &__description {
        display: flex;
        align-items: center;

        &-icon {
            width: 20%;
            min-width: 20%;
        }

        &-text {
            font-size: 18px;
            line-height: 1.4;
            padding-right: 16px;
        }
    }
}