.Locations {
    @import "../../ChainPage.helpers.styles";

    $root: &;

    position: relative;

    &__content {
        position: relative;
        margin-top: 16px;
    }

    &__tiles {
        display: flex;
        margin-bottom: 64px;
    }

    &__tile {
        flex: 1;
        height: 205px;

        & + & {
            margin-left: 12px;
        }
    }

    &__tabs {
        position: relative;
        padding-right: 33.33%;
        min-height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 1200px) {
            padding-right: 0px;
        }
    }

    &__tabs-logo {
        position: absolute;
        right: -$section-gap;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        opacity: 0.2;
        overflow: hidden;

        @media screen and (max-width: 1200px) {
            display: none;
        }
    }

    &__tabs-logo-icon {
        width: 360px * 1.5;
        height: 335px * 1.5;
        position: relative;
        right: -120px;
        top: 25px;
    }

    &__tab {
        height: 540px;
        background: rgba(color("page-bg"), .9);

        @media screen and (max-width: 992px) {
            overflow-x: auto;
        }

        & > div {
            @media screen and (max-width: 992px) {
                min-width: 900px;
            }
        }
    }

    &__tab-tooltip {
        background: #252F3B;

        @media screen and (max-width: 992px) {
            visibility: hidden;
        }

        &:after {
            border-top-color: #252F3B;
        }
    }

    &__competitors {
        height: 100%;
    }

    &__competitors-table {
        height: calc(100% - 44px);
    }

    &__competitors-sub-header {
        display: flex;
        padding-right: 35px;
        height: 44px;

        &-item {
            text-align: center;
            padding: 10px;
            height: 100%;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 80%;
                margin-left: -40%;
                height: 2px;
                background: #50637c;
            }

            &:hover {
                #{$root}__tab-tooltip {
                    display: block;
                }
            }

            &.is-highlight {
                background: rgba(#56ccf2, 0.08);
                margin-left: auto;
            }

            &.is-hoverable {
                cursor: pointer;
            }
        }
    }

    &__tables-group {
        height: 100%;
    }

    &__tables-wrapper {
        height: calc(100% - 60px);
    }

    &__tables-group-head {
        width: 100%;
    }

    &__table-sub-header {
        background: #222B30;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        pointer-events: none;
        color: #fff;
        height: 40px;
        padding-left: 24px;
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 10;
        border-bottom: 1px solid #323840;
    }

    &__tables-group-copy {
        top: 73px;
        position: absolute;
        right: 0;
        z-index: 1;
        width: 32px;
        height: 32px;
    }
}
