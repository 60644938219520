.Toggle {  
	width: 32px;
	height: 16px;
	display: inline-flex;
	box-sizing: border-box;
	flex-shrink: 0;
	vertical-align: middle;


    &__input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
    }

    &__checkbox {
        top: 0;
        left: 0;
        width: 100%;
        cursor: inherit;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        z-index: 1;
        position: absolute;

        &:checked {
            & ~ .Toggle__thumb {
                left: calc(100% - 2px);
                transform: translateY(-50%) translateX(-100%);
            }

            // & ~ .Toggle__track {
            //     background-color: #2EAFD9;
            // }
        }
    }

    &__track {
        background-color: #2E3E56;
        border-radius: 16px;
        height: 100%;
        flex: 1;
    }

    &__thumb {
        height: 12px;
        width: 12px;
        background: #ffffff;
        border-radius: 12px;
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

}