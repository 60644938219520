.NotesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    &__title {
        font-weight: 700;
        font-size: 16px;
        padding-left: 16px;
        cursor: default;
        user-select: none;
    }

    &__close {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            fill: red;
        }
    }

    &__toggle-status {
        color: #3e99b6;
        font-size: 13px;
        margin-right: 16px;
    }
}