.FoodPermissionOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        padding: 16px 20px;
        border-radius: 8px;
        background: rgba(#000, 0.8);
        font-weight: 400;
        b {
            font-weight: 700;
        }
    }

    &__footer {
        font-size: 12px;
        line-height: 15px;
        margin-top: 10px;
        display: flex;
    }

    &__button {
        text-decoration: underline;
        cursor: pointer;
        &:hover {
            text-decoration: none;
        }
    }
}