.DocumentItem {
    $root: &;

    position: relative;
    background: #fff;
    height: 240px;
    overflow: hidden;
    border: 1px solid #e8e9ea;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(black, .04);
    display: flex;
    flex-direction: column;

    &:hover {
        box-shadow: 0 1px 29px rgba(black, 0.24);

        #{$root}__actions {
            display: flex;
        }

        #{$root}__group--bottom,
        #{$root}__project {
            filter: blur(5px);
        }
    }

    &__preview {
        width: 100%;
        border-radius: 5px 5px 0 0;
        position: relative;
        padding-top: 170px;
        overflow: hidden;
    }

    &__image {
        position: absolute;
        backface-visibility: hidden;
        min-height: 100%;
        min-width: 100%;
        top: 0;
        left: 50%;
        max-height: none;
        max-width: none;
        transform: translate(-50%, 0);

        &--blank {
            min-width: 80%;
            width: 80%;
            height: 80%;
            min-height: 0;
            transform: translate(-50%, -50%);
            top: 50%;
        }
    }

    &__details {
        flex: 1;
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 8px;
        border-top: 1px solid #E8E9EA;
    }

    &__group {
        display: flex;
        align-items: center;

        &--bottom {
            margin-top: auto;
        }
    }

    &__project {
        color: #EA6F01;
        font-size: 12px;
        white-space: nowrap;
        word-break: break-word;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        display: inline-block;
        height: 14px;
        line-height: 14px;
        margin-bottom: 4px;
    }

    &__ext {
        width: 24px;
        display: flex;
        align-items: center;
        height: 24px;

        svg {
            width: 19px;
            height: 19px;
        }
    }

    &__name {
        font-family: "Comfortaa", "Source Sans Pro", sans-serif;
        font-size: 19px;
        width: calc(100% - 24px);
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        overflow: hidden;
    }

    &__date,
    &__size,
    &__pages {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        line-height: 12px;
        color: #514F4F;
        align-items: center;
    }

    &__pages {
        margin-left: auto;
    }

    &__size {
        position: relative;
        padding-left: 10px;

        &:after {
            content: '';
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: #514F4F;
            top: 50%;
            margin-top: -1.5px;
            position: absolute;
            left: 4px;
        }
    }

    &__actions {
        display: none;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(white, .1);
        font-size: 10px;
        padding: 6px;
        font-size: 16px;
    }
}