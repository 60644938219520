@import '../../../style';

.mac-item {
    position: relative;
    display: inline-block;
    max-height: 337px;
    margin-right: 20px;
    margin-bottom: 30px;
    width: calc(16.66666666666667% - 20px);
    z-index: 0;
    //transition: z-index 0s .3s;
    $root: &;


    @media screen and (min-width: 1721px) {

        &:nth-of-type(6n){
            margin-right: 0;
        }
    }

    @media screen and (max-width: 1720px) {
        max-height: 26vw;
        width: calc(25% - 15px);

        &:nth-of-type(4n){
            margin-right: 0;
        }
    }

    @include media-max(1280px) {
        max-height: 32vw;
    }

    &:hover, &--hovered {
        z-index: 2;
        transition: z-index 0s 0s;

        #{$root}__btn {
            margin: 0 10px;
            text-decoration: none;
            opacity: 1;

            &--back {
                margin: 10px;
            }

            @include IE10plus {
                &--no-margin {
                    margin: 0;
                }
            }
        }

        #{$root}__inner {
            width: calc(100% + 73px);
            margin-left: -36px;
            box-shadow: 0 1px 29px transparentize(#000, .76);
            height: auto;
            position: absolute;

            @media screen and (max-width: 1700px) {
                width: calc(100% + 63px);
                margin-left: -31px;
            }
        }

        #{$root}__text {
            //max-height: 3.6em;
        }

        #{$root}__title {
            //max-width: 150px;
        }

        #{$root}__btns-block {
            height: 48px;
            padding-top: 10px;
            border-top: 1px solid #000;
            opacity: 1;
            //transition: opacity .35s;
        }

        #{$root}__link {
            display: block;
        }
    }

    &__inner {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        min-height: 345px;
        padding: 22px 22px 10px;
        background-color: #fff;
        border: 1px solid #E8E9EA;
        text-decoration: none;
        box-shadow: 2px 5px 5px rgba(0,0,0,0.04);
        //transition: box-shadow .35s, width .35s, margin .35s;

        @media screen and (max-width: 1700px) {
            height: 24vw;
        }
    }

    &__image {
        width: 100%;
    }

    &__image-block {
        width: 100%;
    }

    &__content {
        width: 100%;
    }

    &__title {
        height: 2.5em;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.25;
        color: #000;
        overflow: hidden;
        overflow-wrap: break-word;
        word-break: break-word;

        @media screen and (max-width: 1700px) {
            font-size: 20px;
        }
    }

    &__sub-title {
        font-family: 'Lato', Sans-Serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.2;
        color: #514F4F;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            margin-top: 2px;
            background-color: #000;
            //transition: width .35s;
        }

        @media screen and (max-width: 1700px) {
            font-size: 14px;
        }
    }

    &__link {
        float: right;
        margin-left: 5px;
        font-family: 'Lato', Sans-Serif;
        font-weight: normal;
        font-size: 14px;
        color: #828282;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        display: none;
        transition: color .25s;
        //transition: all .35s;

        &:hover {
            text-decoration: underline;
            color: #2F80ED;
        }

        @media screen and (max-width: 1700px) {
            font-size: 12px;
        }
    }

    &__text {
        max-height: 2.4em;
        box-sizing: content-box;
        margin: 5px 0;
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        line-height: 1.2;
        color: #7E7E7E;
        overflow: hidden;
        ////transition: max-height .4s;
    }

    &__btns-block {
        display: flex;
        box-sizing: content-box;
        justify-content: space-between;
        align-items: flex-start;
        height: 0px;
        padding-top: 0px;
        width: 100%;
        border-top: 1px solid transparent;
        font-size: 12px;
        line-height: 1;
        text-align: right;
        overflow: hidden;
        //opacity: 0;
        //transition: height .35s, padding .35s, border .35s, opacity 0s;
    }

    &__btn {
        display: inline-block;
        vertical-align: middle;
        flex-basis: 25%;
        margin: 0 3px;
        padding: 0;
        font-size: 10px;
        font-weight: 500;
        color: #4F4F4F;
        text-transform: uppercase;
        text-align: center;
        background-color: transparent;
        border: none;
        transition: color .1s, opacity .1s;

        &:hover {
            color: #2F80ED;
            cursor: pointer;

            .btn-icon-svg{
                fill: #2F80ED;
                pointer-events: none;
            }

            #{$root}__btn-icon--hovered {
                display: inline-block;
            }
            #{$root}__btn-icon--not-hovered {
                display: none;
            }
        }

        @media screen and (max-width: 1280px) {
            font-size: 8px;
        }

        a {
            text-decoration: none;
            display: block;
        }

        &--back {
            position: relative;
            margin-left: 10px;
            background-color: #2F80ED;
            color: #fff;
            box-shadow: 0 -10px 0 10px #2F80ED, 0 10px 0 10px #2F80ED;
            transition: color .25s;
            //transition: all .35s;

            .btn-icon-svg{
                fill: #FFF;
            }

            &:hover {
                background-color: transparent;
                color: #2F80ED;
                box-shadow: 0 -10px 0 10px transparent, 0 10px 0 10px transparent;

                .btn-icon-svg{
                    fill: #2F80ED;
                }
            }
        }

        &--blue{
            color: #2F80ED;
        }

        &--download{
            transition: all .1s;

            .btn-icon-svg{
                fill: #2F80ED;
            }

            &:hover{
                color: orange;

                .btn-icon-svg{
                    fill: orange;
                }
            }
        }

        &--in-mac-details{
            float: right;
            margin-right: 50px;

            #{$root}__btn-text, #{$root}__btn-icon, .btn-icon-svg{
                display: inline-block;
                margin-top: 0;
                line-height: 34px;
                vertical-align: middle;
            }
        }
    }

    &__btn-link {
        text-decoration: none;
    }

    &__btn-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        max-width: 25px;

        &--hovered {
            display: none;
        }
    }

    &__btn-text {
        margin-top: 4px;
        font-size: 12px;
    }

    &__download-btns{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
    }

    &--single, &--in-table {
        height: auto;
        width: 100%;
        max-height: 100%;

        &:hover {
            z-index: 0;

            #{$root}__inner {
                box-shadow: none;
                width: 100%;
                margin-left: 0;
                box-shadow: none;
            }

            #{$root}__btns-block {
                height: 48px;
                padding-top: 20px;
            }

            #{$root}__text {
                height: auto;
                padding-bottom: 22px;
            }

            #{$root}__link {
                width: 83px;

                @media screen and (max-width: 1700px) {
                    width: 63px;
                }
            }
        }

        #{$root}__inner {
            width: 100%;
        }

        #{$root}__title {
            max-width: 100%;
        }

        #{$root}__image {
            width: auto;
        }

        #{$root}__btns-block {
            height: 48px;
            padding-top: 20px;
            border-top: 1px solid #000;
            justify-content: flex-end;
            opacity: 1;
        }

        #{$root}__btn {
            flex-basis: auto;
            margin-left: 10px;
            margin-right: 10px;
        }

        #{$root}__btn-text {
            display: inline-block;
        }

        #{$root}__sub-title {

            &:after {
                width: 100%;
            }
        }

        #{$root}__text {
            height: auto;
            padding-bottom: 22px;
        }

        #{$root}__link {
            visibility: visible;
            opacity: 1;
        }
    }

    &--hovered {
        z-index: 3;

        #{$root}__btns-block {
            display: block;
            text-align: left;
        }

        #{$root}__btn {
            margin: 0 10px;
            text-decoration: none;
            opacity: 1 !important;
        }
    }

    &--in-table, &--in-table:hover{
        display: block;
        margin-bottom: 0;
        overflow: visible;
        // JACK FIX
        //height: 243px;
        //height: 0;
        height: 100%;
        // END JACK FIX
        transition: height 250ms;

        #{$root}__inner{
            min-height: 275px;
            padding: 15px 20px;
            border: 1px solid transparent;
        }

        #{$root}__content{
            width: auto;
            flex-grow: 1;
        }

        #{$root}__text{
            padding-top: 0;
            height: 126px;
            min-height: 100px;
        }

        #{$root}__sub-title{

            &:after{
                display: none;
            }
        }

        #{$root}__image-block{
            float: left;
            width: 200px;
            margin-right: 20px;
        }

        #{$root}__btn {
            font-size: 11px;

            @include IE10plus() {
                margin: 0 10px;
            }
        }

        #{$root}__btn-text {
            font-size: 11px;
            display: block;
        }

        #{$root}__btns-block {
            width: 100%;
            height: 100%;
            overflow: visible;
            align-items: center;
            padding-top: 0;
            border-top: none;
        }
    }

    &--in-table{

        #{$root}__btn {
            opacity: 0;
            transition: opacity .1s;
        }

    }
}

.documents-table-row{

    &:hover{
        .mac-item{

            &__btn {
                opacity: 1;
            }
        }
    }
}

.documents-table-row__wrapper--active{
    .mac-item{

        &--in-table, &--in-table:hover{
            //height: 243px;
        }
    }
}
