.ImagesGallery {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;

    &__counter {
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 1;
        background: #2D9CDB;
        color: #fff;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 5px;
        user-select: none;
        cursor: default;
        white-space: nowrap;
    }
}