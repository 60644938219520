@import "../../../ChainPage.helpers.styles";
.NewsCarousel {
    background: color("section-bg");
    color: white;
    padding: 16px 16px 32px;
    box-shadow: 0px 5px 14px rgba(black, .18);
    border-radius: 8px;
    width: 100%;

    &__title {
        margin-bottom: 16px;
        font-family: 'DM Sans', sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.4;
    }

    &__content {
        position: relative;
        min-height: 100px;
    }

    &__no-data {
        font-size: 18px;
        line-height: 1.4;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100px;
    }
}

.NewsCarouselItem {
    font-family: "DM Sans";
    font-style: normal;
    cursor: default;
    padding-right: 16px;

    &__title {
        font-weight: bold;
        font-size: 14px;
        line-height: 1.2;
        margin-bottom: 8px;
        word-break: break-word;
    }
    &__info {
        display: flex;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 1;
    }

    &__date {
        color: #fff;
        opacity: 0.5;
        display: flex;
        align-items: center;
        white-space: nowrap;

        &:after {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            display: inline-flex;
            margin: 0 10px;
            background: #fff;
        }
    }

    &__link {
        max-width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: color("link");
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }
    &__text {
        font-size: 14px;
        line-height: 1.4;
    }
}