.CircularBar {
    position: relative;
    display: flex;
    justify-content: center;
    user-select: none;
    cursor: default;

    &__circle {
        position: relative;
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }

    &__circle-svg {
        width: 100%;
        height: 100%;
    }

    &__background {
        stroke: #182732;
        width: 100%;
        height: 100%;
        fill: none;
        stroke-width: 10;
        stroke-linecap: round;
    }

    &__line {
        width: 100%;
        height: 100%;

        fill: none;
        stroke-width: 14;
        stroke-linecap: round;
        transform-origin: 50% 50%;
        transform: rotate(-90deg);
        // stroke-dasharray: 570;
        // stroke-dashoffset: calc(570 - (570 * 60) / 100);

    }
}