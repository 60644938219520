.share-popup {
    &__scroll-view {
        overflow-x: hidden !important; // to override react-custom-scrollbar library styles
        margin-bottom: 0 !important;
    }

    &__track-horiz {
        display: none;
    }
}
