.companies-list-drop-down {
    position: absolute;
    left: 70px;
    top: 100%;
    width: 300px;
    background: #fff;
    padding: 10px 20px;
    z-index: 2;
    text-align: left;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

    &__options {
        height: 200px;
    }
    &__link {
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__input {
        border: 0;
        border-bottom: 1px solid;
        font-size: 16px;
        height: 30px;
        line-height: 20px;
        padding: 0;
        width: 100%;
        display: block;
        margin-bottom: 10px;
        border-radius: 0;
    }
}
