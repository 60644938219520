.DocumentsTable {
    height: 100%;
    padding: 25px 50px 50px;

    &__wrapper {
        height: 100%;
        background: #fff;
        padding: 0 12px 12px 12px;
    }

    &__row-actions {
        margin-left: auto;
        font-size: 16px;
    }

    &__cell-title {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
    }

    &__cell-title-icon {
        margin-right: 8px;
        width: 24px;

        svg {
            max-width: 100%;
        }
    }

    &__cell-title-text {
        width: calc(100% - 24px - 8px);
    }

    &__cell-title-link {
        text-decoration: none;

        &:hover {
            color: #2DC0E6;
        }
    }
}