.search-button {
    display: block;
    height: 40px;
    width: auto;
    max-width: 400px;
    box-sizing: border-box;
    border: 1px solid #B6B6B6;
    padding: 5px 20px;
    background: #fff;
    text-align: left;
    font-size: 14px;
    vertical-align: middle;
    position: relative;
    cursor: text;
    transition: all 300ms;
    border-radius: 15px;
    color: #B6B6B6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:before {
        display: inline-block;
        content: 'Search for foods, places, articles and more';
    }

    @include media-max(1345px) {
        width: 200px;

        &:before {
            display: inline-block;
            content: 'Search';
        }
    }

    @include media-max(1140px) {
        width: 105px;
    }

    &--small {
        width: 280px;
        height: 40px;
    }

    &__icon {
        vertical-align: middle;
        position: absolute;
        height: 22px;
        width: 22px;
        top: 50%;
        right: 9px;
        transform: translateY(-50%);
    }
}
