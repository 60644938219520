.TableRows {
    $root: &;

    width: 100%;

    &__table-row {
        display: flex;
        padding: 0 16px;

        &:nth-child(odd) {
            background-color: #252F3B;
        }

        &:nth-child(even) {
            background-color: #131921;
        }
    }

    &__table-cell {
        font-size: 12px;
        line-height: 24px;
        vertical-align: middle;

        &:first-child {
            color: #676D75;
            width: 130px;
            text-align: right;
            padding-right: 16px;

            .white-labels & {
                color: #fff;
            }

            @media screen and (max-width: 1440px) {
                width: 50px;
            }

            @media screen and (max-width: 1200px) {
                width: 140px;
            }

            .is-full & {
                @media screen and (max-width: 1440px) {
                    width: 130px;
                }

                @media screen and (max-width: 1200px) {
                    width: 140px;
                }
            }

            .is-large & {
                width: 190px;
                text-align: left;
            }
        }
    }

    &__two-columns {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 992px) {
            flex-wrap: wrap;
        }

        #{$root}__table {
            width: 49%;

            @media screen and (max-width: 992px) {
                width: 100%;
            }

            &:last-child {
                #{$root}__table-row {
                    &:nth-child(odd) {
                        @media screen and (max-width: 992px) {
                            background-color: #131921;
                        }
                    }

                    &:nth-child(even) {
                        @media screen and (max-width: 992px) {
                            background-color: #252F3B;
                        }
                    }
                }
            }
        }
    }
}