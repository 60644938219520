.Launches {
    @import "../../../ChainPage.helpers.styles";
    @import "@styles";

    $list-width: 35%;
    $root: &;

    position: relative;
    display: flex;
    padding-bottom: 16px;

    @media screen and (max-width: 992px) {
        flex-wrap: wrap;
    }

    &__chart {
        width: calc(100% - #{$list-width});

        @media screen and (max-width: 992px) {
            width: 100%;
        }
    }

    &__list {
        width: $list-width;
        margin-left: auto;
        padding-left: 16px;
        padding-top: 42px;

        @media screen and (max-width: 992px) {
            width: 100%;
            padding-left: 0;
            margin-top: 32px;
        }
    }

    &__header {
        padding-bottom: 24px;
        display: flex;
    }

    &__header-column {
        line-height: 1.4;

        & + & {
            margin-left: 8px;
        }
    }

    &__info {
        position: relative;
        height: 32px;
        display: flex;
        align-items: center;
    }

    &__info-dropdown {
        font-size: 12px;
    }

    &__info-button {
        background: none;
        min-width: 0;

        &:hover {
            opacity: 1;
        }

        .Tooltip {
            @media screen and (max-width: 1700px) {
                left: 0;
                transform: none;

                &:after {
                    left: 5%;
                }
            }

        }
    }

    &__info-item {
        & > span {
            opacity: 0.5;
        }
    }

    &__title {
        font-size: 32px;
        line-height: 1;
        color: #fff;
        font-weight: 500;
        margin-bottom: 4px;
    }

    &__sub-title {
        font-size: 12px;
        color: $chains-primary;
    }

    &__footer {
        background: color("page-bg");
        position: relative;
        z-index: 1;
        text-align: right;
        margin-top: 12px;
    }

    &__footer-action {
        color: color("link");
        display: inline-flex;
        width: auto;
        text-transform: none;
        font-weight: 700;
        font-size: 13px;
        background: none;
        text-decoration: underline;
        min-width: 0;

        &:hover {
            text-decoration: none;
        }
    }
}