.my-documents-document-tile {
    display: block;
    text-decoration: none;
    background: linear-gradient(1.15deg, rgba(249, 248, 248, 0.5) -139.95%, rgba(246, 243, 243, 0.761539) -27.16%, rgba(186, 183, 183, 0.26) -13.94%, rgba(248, 246, 246, 0.35) 12.55%, #FFFFFF 96.49%), #FFFFFF;
    width: 100%;
    margin-bottom: 15px;
    $root: &;
    transition: transform 300ms;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    z-index: 1;
    position: absolute;
    color: #848484;
    height: 100%;
    padding: 20px;
    overflow: hidden;
    min-height: 100%;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 10px 0 rgba(#000, .3);
        background-image: none;
        z-index: 2;
        height: auto;

        #{$root}__text,
        #{$root}__description {
            height: auto;
        }

        #{$root}__footer {
            display: block;
        }
    }

    &__thumbnail-container {
        width: 200px;
        height: 150px;
        float: left;
        margin-right: 20px;
        text-align: center;
    }

    &__text {
        width: calc(100% - 220px);
        float: right;
        height: 100%;
        position: relative;
    }

    &__header {
        @include clearfix;
        border-bottom: 1px solid #303030;
        max-height: 90px;
        padding-bottom: 10px;
        box-sizing: border-box;
    }

    &__footer {
        display: none;
        padding-top: 15px;
        border-top: 1px solid #303030;
        text-align: right;
    }

    &__title {
        font-size: 26px;
        line-height: 28px;
        font-weight: 700;
        max-height: 64px;
        overflow: hidden;
        color: #303030;
        text-decoration: none;
        transition: all 300ms;
        padding: 4px 0;
        &:hover {
            color: #2D9CDB;
        }
    }

    &__sub-title {
        line-height: 14px;
    }

    &__main {
        padding: 10px 0;
        @include clearfix;
        max-height: calc(100% - 90px);
    }

    &__description {
        line-height: 25px;
        height: 50px;
        min-height: 50px;
        overflow: hidden;
    }

    &__action {
        display: inline-block;
        color: #4f4f4f;
        transition: all 300ms;
        margin-right: 15px;
        font-size: 10px;
        line-height: 20px;
        cursor: pointer;
        text-decoration: none;


        &:hover {
            color: #2D9CDB;
            #{$root}__icon {
                fill: #2D9CDB;
            }
        }

        &--highlighted {
            color: #2D9CDB;
            #{$root}__icon {
                fill: #2D9CDB;
            }

            &:hover {
                color: orange;
                #{$root}__icon {
                    fill: orange;
                }
            }
        }
    }

    &__icon {
        fill: #4f4f4f;
        display: block;
        vertical-align: middle;
        transition: all 300ms;
        pointer-events: none;
    }
}
