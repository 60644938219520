.InfoBlock {
    position: relative;
    padding: 16px;
    background: #131921;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.18);
    border-radius: 8px;

    &__title {
        margin-bottom: 16px;
        font-family: 'DM Sans', sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.2;
        display: flex;
    }

    &__sub-title {
        color: #676D75;
        font-weight: 400;
        margin-left: 4px;
    }

    &__content {
        display: flex;
        width: 100%;
    }

    &__details {
        display: flex;

        span {
            padding: 0 24px;
            font-weight: bold;
            font-size: 28px;
            line-height: 32px;
            color: #252F3B;
        }
    }

    &__franchised,
    &__owned {
        font-size: 32px;
        line-height: 36px;
        font-weight: bold;

        p {
            font-size: 12px;
            line-height: 14px;
        }
    }

}