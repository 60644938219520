@import '@styles';

.FoodProfileSummary {
    width: 100%;
    height: 460px;
    color: #fff;
    $root: &;
    z-index: 200;
    backface-visibility: hidden;
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    background: #235ae4;
    border-radius: 0 5px 5px 0;

    @include media-max(1400px) {
        width: calc(100vw - 130px);
    }

    &__main-content {
        position: relative;
        height: 100%;
        flex-grow: 3;
    }

    &__consumer-affinity-content {
        width: 170px;
        height: 100%;
        flex-grow: 0;

        &.is-expand {
            z-index: 11;
        }
    }

    &__cols {
        display: flex;
        height: 100%;

        &--v {
            flex-direction: column;
            align-items: end;
        }
    }

    &__col-info {
        flex-grow: 0;
    }

    &__col-float {
        flex-grow: 3;
    }

    &__tip {
        position: absolute;
        top: 100%;
        right: 0;
        margin-top: 5px;
        font-size: 10px;
        line-height: 24px;
        color: #4F4F4F;
        z-index: -2;
    }
}

.FoodProfileSummaryChartInfo {
    width: 460px;
    height: 100%;
    padding: 30px 0 20px 40px;
    font-weight: 300;
    display: flex;
    flex-direction: column;

    @include media-max(1400px) {
        width: 290px;
        padding: 20px 0 20px 20px;
    }

    &__header {
        @extend %bordered;
        position: relative;
        font-size: 17px;

        @include media-max(1400px) {
            font-size: 13px;
        }
    }

    &__header-bottom {
        min-height: 70px;
        padding-top: 5px;
        width: 100%;
    }

    &__title {
        font-weight: 700;
        text-transform: capitalize;
        font-size: 40px;
        line-height: 40px;
        white-space: nowrap;

        @include media-max(1400px) {
            font-size: 30px;
            line-height: 32px;
        }
    }

    &__subtitle {
        font-weight: 300;
        margin-top: 5px;
        font-size: 30px;
        line-height: 40px;

        @include media-max(1400px) {
            font-size: 20px;
            line-height: 20px;
        }
    }

    &__prediction-btn {
        position: relative;
        display: inline-block;
        color: #00EBF5;
        text-decoration: underline;
        padding-right: 3px;
        cursor: pointer;
        
        &:hover {
            text-decoration: none;
        }
    }

    &__prediction-btn-info {
        position: absolute;
        height: 12px;
        display: flex;
        left: 100%;
        top: 50%;
        margin-top: -10px;

        &:hover {
            .PredictionTooltip,
            .PredictionTooltip__triangle {
                opacity: 1;
            }
        }

        svg {
            width: 12px;
            height: 12px;

            * { fill: #00EBF5; }
        }

    }

    &__filter-label {
        font-size: 14px;
        line-height: 15px;
        padding: 15px 0;

        @include media-max(1400px) {
            font-size: 12px;
        }
    }

    &__mark {
        color: #fd8b60;
    }

    &__body {
        @extend %bordered;
        position: relative;
        font-size: 16px;
        margin-bottom: 20px;
        padding: 15px 0;
        max-height: 120px;
        transition: opacity .3s;

        @include media-max(1400px) {
            font-size: 12px;
        }
    }

    &__footer {
        margin-top: auto;
    }

    &__button {
        height: 48px;
        width: 350px;
        border: 0;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;
        display: block;
        font-size: 16px;
        line-height: 47px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 10px;
        font-weight: 700;
        text-align: center;
        float: left;
        clear: left;
        $button: &;

        @include media-max(1400px) {
            width: 250px;
            font-size: 12px;
        }

        .icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            width: 34px;
            height: 23px;
            fill: #ffffff;
        }

        &--progress-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
        }

        &--download {
            background: #86D004;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            #{$button}.is-error & {
                background: red;
            }
        }

        &--downloading {
            opacity: 0.8;
            pointer-events: none;
        }

        &--small {
            font-size: 12px;
            padding: 0;
            width: auto;
            color: #ddd;
            height: 30px;
            line-height: 30px;
            margin-bottom: 0;

            &:hover {
                .package-animation-container {
                    text-decoration: underline;
                }

                .icon {
                    filter: drop-shadow(0 0 3px #fff);
                }
            }

            .icon {
                transition: all 300ms;
                margin-right: 5px;
                width: 23px;
                margin-top: -4px;
                fill: #ddd;
            }
        }
    }

    %bordered {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-image: linear-gradient(to right, #fff 0%, #fff 75%, transparent 75%);
            background-size: 4px;
            background-attachment: fixed;
        }
    }
}

.FoodProfileChartInfo {
    position: relative;
    width: 100%;
    height: 100%;

    &:hover {
        z-index: 9;
    }
}

.FoodProfileOverlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 300;
    font-size: 22px;
    line-height: 25px;
    z-index: 10;
    backdrop-filter: blur(7px);
    text-align: center;
    padding: 10px;
    $root: &;

    &.is-narrow {
        width: 170px;
        left: auto;
        font-size: 16px;
        padding-top: 170px;

        #{$root}__action {
            flex-direction: column;
        }

        #{$root}__icon {
            margin-left: 0;
            margin-top: 10px;
        }
    }

    &.is-wide {
        right: 170px;
    }

    &__footer {
        display: flex;
    }

    &__icon-container {
        width: 100px;
        height: 100px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000;
    }

    &__button {
        background: #26b7a5;
        color: #ffffff;
        height: 34px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 17px;
        margin-top: 20px;
        font-size: 15px;
        min-width: 140px;
        cursor: pointer;
        transition: background 300ms;

        &:hover {
            background: darken(#26b7a5, 10%);
        }

        &--inactive, &:disabled {
            pointer-events: none;
            background: #aaa;
            //opacity: 0.7;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        margin-top: 10px;

        * {
            margin: 0;
        }
    }

    &__icon {
        margin-left: 15px;
    }
}

.PredictionTooltip {
    width: 380px;
    position: absolute;
    top: calc(100% + 15px);
    left: -10px;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    padding: 15px;
    background: #7BC903;
    z-index: 200;
    transition: opacity 300ms;
    pointer-events: none;
    box-shadow: 0 4px 10px 0 rgba(#000, 0.4);
    opacity: 0;

    &__triangle {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        width: 0;
        height: 0;
        border-width: 8px 6px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #7BC903;
        transition: opacity 300ms;
        opacity: 0;
    }

    &__data {
        position: relative;
        width: 100%;
        z-index: 2;
    }
}
