.Dropdown {
    $moveY: -20px;
    $trs-dur: 300ms;

    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 90;

    &-enter,
    &-appear {
        opacity: 0;
        transform: translateY($moveY);
    }

    &-enter-active,
    &-appear-active {
        opacity: 1;
        transform: translateY(0);
        transition: all ease-in $trs-dur;
    }

    &-exit-active {
        opacity: 0;
        transform: translateY($moveY);
        transition: all ease-out $trs-dur;
    }

    &--right {
        display: flex;
        left: auto;
        right: 0;
        width: auto;
        min-width: 100%;
    }

    &--inside {
        top: 0;
        height: 100%;
    }
}
