.ContactsTiles {
    @import "../../ChainPage.helpers.styles";
    @import "../../../../styles/helpers/ie.scss";

    width: 100%;
    position: relative;

    &__loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: color("page-bg");
        opacity: 0.75;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &__no-data {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__row {
        display: flex;
        width: calc(100% + 16px);
        position: relative;
        left: -8px;
        flex-wrap: wrap;

        & + & {
            margin-top: 16px;
        }

        & > div {
            $size: calc((100% / var(--columns)) - 16px);
            flex: 1;
            max-width: $size;
            min-width: $size;
            margin: 8px;

            @include onlyIE {
                min-width: calc((100% / 6) - 18px);
                max-width: calc((100% / 6) - 18px);
            }
        }
    }

    &__toggle {
        font-size: 13px;
        font-weight: 700;
        text-decoration: underline;
        color: color("link");
        cursor: pointer;
        user-select: none;
        margin-top: 16px;
        text-align: right;

        &:hover {
            text-decoration: none;
        }
    }
}