.LTOChart {
    @import "../../../../ChainPage.helpers.styles";
    @import "@styles";
    display: flex;
    flex-direction: column;
    $lines-height: 300px;
    $footer-height: 30px;
    $padding: 12px;

    &__wrapper {
        height: $lines-height + $footer-height;
        position: relative;
    }

    &__lines {
        height: $lines-height;
        position: relative;
        display: flex;
        align-items: flex-end;
        padding-top: $footer-height;
        padding-bottom: $footer-height;
    }

    &__years {
        height: $footer-height;
        width: 100%;
        position: relative;
        display: flex;
    }

    &__line {
        width: calc(100%/12);
        padding: 0 $padding;
    }

    &__group-label {
        padding: 0 $padding;
        position: relative;

        &-1 {
            width: calc(100%/12);
        }
        &-2 {
            width: calc(100%/6);
        }
        &-3 {
            width: calc(100%/4);
        }
        &-4 {
            width: calc(100%/3);
        }
    }

    &__year-label {
        font-size: 14px;
        line-height: 1;
        font-weight: 700;
        color: white;
        background: #2A323D;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 3px;
    }

    &__delimiter {
        width: 0;
        position: relative;

        &:last-child {
            display: none;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: - $footer-height*0.5;
            left: 0;
            width: 1px;
            height: $lines-height - $footer-height*0.5;
            background-image: linear-gradient(to bottom, #546071 50%, transparent 50%);
            background-size: 1px 8px;
        }
    }

    &__content {
        display: flex;
        max-width: 100%;
        min-width: 100%;
        padding-right: 32px;
    }

    &__header {
        padding-bottom: 16px;
    }

    &__title {
        font-size: 14px;
        color: #fff;
    }
    &__sub-title {
        font-size: 14px;
        color: $chains-primary;
    }
}