.FadeIn {
    $bg: #181B20;
    opacity: 0;
    transition: all 750ms ease;
    // transform: scale(0.95) translate(30px);

    &:hover {
        z-index: 1;
    }

    .LockSection {
        background: $bg;
    }

    &.is-hidden {
        opacity: 0;
        // transform:scale(0.95) translate(30px);
    }

    &.is-visible {
        opacity: 1;
        transform:scale(1) translateY(0px);

        .LockSection {
            transition: all 750ms ease;
            transition-delay: 750ms;
            background: transparent;
        }
    }
}