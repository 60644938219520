.ColumnSlider {
    width: 100%;
    margin: 0 auto;
    padding-left: 45px;
    padding-right: 45px;

    &__sensor {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        pointer-events: none;
        z-index: 2;
    }

    &__viewport {
        position: relative;
        width: 100%;
    }

    &__view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition-property: transform;
        transition-timing-function: ease;
        display: flex;
        min-height: 20px;

        &:hover {
            z-index: 2;
        }
    }

    &__slide {
        margin: 0 4px;
        flex: 0 0 auto;

        &--hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__nav {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 46px;
        z-index: 2;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:after {
            content: '';
            display: block;
            width: 32px;
            height: 32px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #383f45;
            border-radius: 4px;
            z-index: 1;
        }

        svg {
            position: absolute;
            z-index: 2;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &--prev {
            right: 100%;
            background-image: linear-gradient(to right, black, transparent);
        }

        &--next {
            left: 100%;
            background-image: linear-gradient(to left, black, transparent);
        }
    }
}