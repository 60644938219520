@import '../../style';
@import './my-documents-document-tile';
@import './my-documents-project-tile';
@import './my-documents-types';

.my-documents-projects-grid {
    &__item {
        position: relative;
        float: left;
        height: 310px;
        margin-left: 30px;
        width: calc(25% - #{90px/4});
        @include IE10plus {
            width: calc(25% - 23px);
        }
        margin-bottom: 30px;

        &:nth-child(4n + 1) {
            margin-left: 0;
        }


    }
}

.my-documents-documents-grid {
    &__item {
        position: relative;
        height: 200px;
        float: left;
        @include init-grid(2, 40px, 1920px - 170px);
        margin-bottom: 15px;
        @include media-max(1600px) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.my-documents {
    background: #F6F6F6;
    $root: &;

    &__scrollbar-outer {
        height: calc(100% - 83px);
    }

    &__wrapper {
        max-width: 1920px;
        margin: auto;
        @include clearfix;

        &--flex {
            display: flex;
            align-items: center;
            justify-content: space-between;

            #{$root}__title {
                transform: translateY(3px);
            }
        }
    }

    &__table-sub-title {
        font-size: 10px;
        display: inline-block;
        color: #888;
    }

    &__content-wrapper {
        position: relative;
    }

    &__projects-list {
        @include clearfix;
    }

    &__visibility-sensor-after {
        min-width: 5px;
        width: 1px;
        height: 1000px;
        max-height: 100%;
        margin-top: -1500px;
        opacity: 0;
        z-index: -1;
    }

    &__header {
        background: #fcfcfc;
        padding: 15px 80px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        @include clearfix;

        @include media-max(1500px) {
            padding: 20px 40px;
        }

        @include media-max(1340px) {
            padding: 20px;
        }

        &--transparent {
            border-bottom: 0;
            background-color: transparent;
            box-shadow: none;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                border-bottom: 1px solid #4f4f4f;
                margin-top: 15px;
            }
        }

        &--project {
            @include media-max(1440px) {
                height: 93px;
            }
        }
    }

    &__title {
        font-size: 24px;
        line-height: 26px;
        font-weight: 300;
        //height: 42px;
    }

    &__title-highlight {
        font-size: 34px;
    }

    &__sub-title {
        font-size: 20px;
        line-height: 20px;
        font-weight: 300;
        padding-bottom: 15px;

        &--in-sidebar {
            margin-bottom: 10px;
            padding-bottom: 10px;
            font-size: 17px;
            line-height: 27px;
            position: relative;

            & > div {
                margin-top: 10px;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                height: 1px;
                width: calc(100% + 40px);
                left: -20px;
                bottom: 0;
                background: #f0f0f0;
            }

            @include media-max(1340px) {
                font-size: 16px;
            }
        }
    }

    &__content {
        padding: 30px 80px;
        @include clearfix;
        min-height: calc(100vh - 170px);

        @include media-max(1500px) {
            padding: 20px 40px;
        }

        @include media-max(1340px) {
            padding: 20px;
        }
    }

    &__sidebar {
        width: 340px;
        float: right;
        margin-left: 30px;
        background: #fff;
        padding: 20px;
        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.05));
        position: sticky;
        top: 10px;

        @include media-max(1600px) {
            width: 220px;
            margin-left: 20px;
            padding: 15px;
        }

        @include media-max(1200px) {
            width: 100%;
            margin: 0 0 20px;
            position: relative;
            top: auto;
        }
    }

    &__main {
        width: calc(100% - 350px - 30px);
        float: left;

        @include media-max(1600px) {
            width: calc(100% - 270px);
        }

        @include media-max(1200px) {
            width: 100%;
        }
        //@include media-max(1200px) {
        //    width: calc(100% - 340px - 30px);
        //}
    }

    &__btn {
        display: inline-block;
        padding: 7px 24px;
        font-size: 13px;
        line-height: 28px;
        height: 42px;
        color: #fff;
        text-transform: uppercase;
        background-color: #2D9CDB;
        border: 1px solid transparent;
        cursor: pointer;
        box-shadow: 0 1px 5px rgba(#445C74, .4);
        font-weight: 700;
        position: relative;
        transition: all 300ms;

        &--empty {
            border-color: #2D9CDB;
            color: #2D9CDB;
            background-color: transparent;
        }

        &:hover {
            background-color: darken(#2D9CDB, 10%);
        }

        &:active {
            transform: translate(1px, 1px);
        }
    }

    &__scrollbar-outer {
        height: calc(100% - 93px);

        &--doclib {
            height: calc(100% - 73px);
        }

        @include media-max(1440px) {
            height: calc(100% - 120px);
        }

        &--project {
            @include media-max(1440px) {
                height: calc(100% - 93px);
            }
        }
    }

    &__link {
        font-family: $font_search;
        font-size: 20px;
        margin-right: 10px;
        color: #828282;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: color .25s;
        display: inline-block;
        text-decoration: none;

        &--indent-left {
            margin-left: 10px;
        }

        &:hover {
            color: #2D9CDB;
            text-decoration: underline;
        }
    }

    &__left-side {
        float: left;
        min-height: 26px;

        &--cutted {
            max-width: calc(100% - 645px);
            width: 100%;
        }
    }

    &__right-side {
        float: right;
        min-height: 26px;
        text-align: right;
        margin-left: auto;

        &--flex {
            display: flex;
            align-items: center;
        }
    }

    &__search {
        height: 30px;
        width: 100%;
        max-width: 100%;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid #000;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 22px;
        padding-left: 30px;
        padding-right: 20px;
        box-sizing: border-box;
        font-family: $font_search;

        &--in-sidebar {
            font-size: 18px;
            font-weight: 700;

            @include media-max(1340px) {
                font-size: 16px;
            }
        }

        &::-webkit-search-cancel-button {
            cursor: pointer;
            display: none;
        }
    }

    &__input-container {
        position: relative;
        margin-bottom: 25px;
        width: 560px;
        max-width: 100%;

        &--in-sidebar {
            margin-bottom: 20px;
        }
    }

    &__search-icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &__tabs {
        display: inline-block;
        margin-left: 10px;
        padding-bottom: 20px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &__tab {
        font-size: 22px;
        color: #8d8d8d;
        border-left: 1px solid #8d8d8d;
        transition: all 300ms;
        display: inline-block;
        padding: 0 10px;
        box-sizing: border-box;
        cursor: pointer;

        &:first-child {
            border: 0;
        }

        &--active {
            color: #000;
            font-weight: 500;
        }
    }

    &__submit-btn {
        position: relative;
        z-index: 1;
        float: right;
        margin-top: -22px;
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        animation: slideInLeft .5s;

        &:hover {
            padding-left: 10px;

            svg {
                transform: rotate(180deg) translateX(-10px);
            }
        }

        svg {
            transform: rotate(180deg);
            transition: all .2s;
        }
    }

    &__company-field {
        display: inline-block;
        margin-right: 20px;
        font-family: $font_search;
        font-weight: 500;
        position: relative;
        padding-right: 13px;

        &:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            top: 10px;
            right: 0;
            position: absolute;
            border: 4px solid transparent;
            border-top-color: currentColor;
        }
    }

    &__company-name {
        border-bottom: 1px dashed;
        cursor: pointer;
    }
}
