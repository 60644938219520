@import '../../style';

.table {
    $root: &;

    width: 100%;

    &__data-row {
        &--expanded {
            #{$root}__data-collapsed {
                max-height: 10000px;
                transform: scale(1);
            }
            #{$root}__main-menu-item {
                border-bottom: 1px solid #ccc;
                padding-bottom: 5px;
            }
        }
    }

    &__head-text {
        cursor: pointer;

        &--no-sort {
            cursor: default;
        }
    }

    &-container {
        position: relative;
        padding: 25px 35px 5px 25px;
        background: #fff;
        box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.18);

        // sorry for that
        &--chain-page {
            box-shadow: none;
            padding: 0;

            & #{$root}__head-text {
                font-family: Muli, sans-serif;
                font-size: 14px;
                color: #4A4A4A;
                font-weight: normal;
                cursor: pointer;
            }

            & #{$root}__button {
                top: 8px;
                right: -80px;
            }

            & td:first-child {
                width: 260px;
            }

            & td:last-child {
                //background: #EEF9FF;
            }

            & th:last-child {
                & #{$root}__head-text {
                    color: #4A4A4A;
                }
                //background: #2D9CDB;
                //background: #f8f8f8;

            }
        }

        &--my-documents {
            padding: 15px 35px 5px 25px;

            @include media-max(1280px) {
                padding: 15px 15px 5px 15px;
            }

            & #{$root}__head {
                padding: 0 15px 10px;
                font-size: 14px;

                @include media-max(1650px) {
                    font-size: 12px;
                }
            }

            & #{$root}__head-text {
                cursor: pointer;

                @include media-max(1280px) {
                    white-space: normal;
                }
            }

            & #{$root}__data-row {
                &:hover {
                    #{$root}__data {
                        &:last-child {
                            opacity: 1;
                        }
                    }

                    #{$root}__cell-footer {
                        opacity: 1;
                    }
                }
            }

            & #{$root}__data {
                vertical-align: middle;
                padding: 25px 0 25px 10px;
                @include media-max(1600px) {
                    font-size: 12px;
                }

                &:nth-child(3) {
                    white-space: nowrap;

                    @include media-max(1500px) {
                        white-space: normal;
                    }
                }

                &:nth-child(1) {
                    width: 47%;

                    @include media-max(1280px) {
                        font-size: 10px;
                    }
                }

                //&:last-child {
                //    opacity: 0;
                //    transition: opacity .2s;
                //}
            }

            & #{$root}__data-link {

                @include media-max(1400px) {
                    font-size: 12px;
                }
            }

            @include IE10plus {
                & .table {
                    table-layout: fixed;

                    &__head {
                        &:first-child {
                            width: 47%;
                        }
                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            width: 10%;
                        }
                        &:last-child {
                            width: 13%;
                        }
                    }

                    &__data {
                        &:nth-child(3) {
                            white-space: normal;
                        }
                    }
                }
            }
        }

        // sorry for that
        &--packages-page {
            box-shadow: none;
            padding: 0;

            & #{$root}__head-text {
                font-family: Muli, sans-serif;
                font-size: 14px;
                color: #4A4A4A;
                font-weight: normal;
            }

            & #{$root}__data {
                vertical-align: middle;
            }

            & #{$root}__data-row {
                border-bottom: 1px solid #ccc;
            }

            & td:first-child {
                width: 260px;
            }

            & td:last-child {
                text-transform: uppercase;
                padding: 0 10px;
            }

            & th:last-child {
                width: 180px;
            }

            & tbody tr:hover {
                background: #F4F9FF;
                & .header-packages__package-controls {
                    visibility: visible;
                }
            }
        }

        &--blurred {

            .table, .table__button {
                filter: blur(3px);
                -ms-filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='50');
            }

            @include IE10plus {
                .table__button, .table {
                    opacity: 0.22;
                }
            }

            @supports (-ms-ime-align:auto) {
                .table__button, .table {
                    opacity: 0.4;
                }
            }
        }
    }

    &__button {
        position: absolute;
        right: -60px;
        top: 15px;
        width: 63px;
        padding: 10px;
        background: #FFFFFF;
        border: 1px solid #D7DCE0;
        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1), 0 1px 15px rgba(0, 0, 0, 0.23947);
        border-radius: 6px;
        font-size: 11px;
        line-height: 100%;
        color: #3A414C;
        cursor: pointer;
        //z-index: -1;
        transition: background .3s ease-out;


        @include Safari10plus {
            box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.1);
        }


        &:hover {
            background: #f1fbfd;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            top: -12px;
            left: -8px;
            display: block;
            width: 10px;
            height: calc(100% + 24px);
            background-color: #fff;
        }

        &:before {
            top: 0;
            height: 100%;
            box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.3);

            @include Safari10plus() {
                box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
            }
        }

        &:after {
            left: -22px;
            width: 24px;
        }

        &--lower-positioned {
            top: 125px;
        }
    }

    &__button-icon {
        display: block;
        width: 25px;
        margin: 0 auto 5px;
    }

    &__head {
        padding: 10px 15px 5px;

        &:first-child {
            text-align: left;
        }

        &-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 7px;
            width: 16px;
            margin-left: -16px;
            cursor: pointer;
        }

        &-text {
            display: inline-block;
            vertical-align: middle;
        }

        &-row {
            border-bottom: 2px solid #000;
        }
    }

    &__data {
        padding: 10px 0 10px 5px;
        font-size: 14px;
        color: #000;
        text-align: center;

        &:first-child {
            text-transform: uppercase;
            word-break: break-all;

            @media screen and (-webkit-min-device-pixel-ratio: 0) {
                word-break: break-word;
            }
        }

        &-link {
            color: #3D8CDE;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        &--overlay {
            position: absolute;
            opacity: 0;
            right: 40px;
            padding-right: 10px;
            padding-left: 10px;
            display: block;
            z-index: -1;
            background: #E5F7FF;
            box-shadow: 0 0 10px 0 #E5F7FF;
            transition: all 300ms, z-index 0s;
        }

        &-row {
            border-bottom: 1px solid #ccc;
            transition: all 300ms;

            &:hover {
                background: #E5F7FF;
                #{$root}__data {
                    opacity: 1;
                    z-index: 2;
                }
                #{$root}__data--overlay ~ #{$root}__data {
                    //filter: blur(3px);
                    //opacity: 0.3;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }

    &__data-icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;

        @include media-max(1200px) {
            width: 20px;
        }
    }

    &__data-description {
        padding-right: 20px;
    }

    &__data-btn {
        cursor: pointer;
        background-color: transparent;
        border: 0;
        outline: none;
        padding: 0;
        white-space: nowrap;

        &:hover {
            text-decoration: underline;
        }
    }

    &__data-highlight {
        background: #8a4200;
        margin: 0;
        color: #fff;
        font-weight: inherit;

        &--punctuation {
            margin-left: -4px;
            margin-right: 4px;
        }
    }

    &__data-inner-row {
        padding: 5px 0;
        border-bottom: 1px solid #ccc;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }

    &__data-collapsed {
        max-height: 0;
        transform: scale(0);
        animation: expand 200ms;
    }

    &__data-title {
        vertical-align: middle;
        max-width: calc(100% - 50px);

        &--pr {
            padding-right: 10px;
        }

        &--no-decor {
            text-decoration: none;
        }
    }

    &__overlay-cell {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 24px;
        &--tall {
            height: 35px;
        }
    }

    &__visibility-sensor {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1000px;
        width: 1px;
    }

    &__visibility-sensor-after {
        opacity: 0;
        height: 1px;
    }

    &__cell-footer {
        display: flex;
        position: absolute;
        bottom: -18px;
        left: 0;
        opacity: 0;
        transition: opacity 300ms;
        height: 16px;

        #{$root}__data-icon ~ & {
            left: 38px;
        }
    }

    &__cell-container {
        position: relative;
        display: flex;
        align-items: center;

        & > span {
            width: 100%;
        }
    }
}

.order-icon-dark {
    //fill: #656565;
    fill: #979797;
    pointer-events: none;
}

.order-icon-white {
    fill: #FFFFFF;
}

@keyframes expand {
    from {
        max-height: 0;
    }
    to {
        max-height: 10000px;
    }
}

.TABLE {
    &__DATA-HIGHLIGHT {
        background-color: yellow;
        margin-right: 4px;
    }
}
