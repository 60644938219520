@import '../../style';

.file-upload {
    @include clearfix;
    padding: 15px 15px 15px 25px;
    box-sizing: border-box;
    transition: all 300ms, transform 1s;
    position: relative;
    z-index: 2;
    height: 240px;
    $fu: &;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(#fff, .80);
        z-index: -1;
        opacity: 0;
        transition: opacity 300ms, z-index 0s 300ms;
    }

    &--blur {
        &:before {
            opacity: 1;
            z-index: 1;
            transition: opacity 300ms, z-index 0s;
        }
    }

    &--focus {
        background: #f3f3f3;
    }

    &--canceling {
        opacity: 0.7;
        transform: translateX(-100%)
    }

    &__cross-icon {
        position: absolute;
        top: 5px;
        right: 15px;
        width: 20px;
        height: 20px;
        cursor: pointer;

        &:before, &:after {
            content: '';
            display: block;
            width: 2px;
            height: 16px;
            background: #4f4f4f;
            position: absolute;
            top: 10px;
            right: 9px;
            transform-origin: center;
        }

        &:before {
            transform: translateY(-50%) rotate(-45deg);
        }
        &:after {
            transform: translateY(-50%) rotate(45deg);
        }
    }

    &__sub-title {
        font-size: 12px;
        line-height: 16px;
    }

    &__progress {
        width: 200px;
        height: calc(100% + 9px);
        padding-right: 15px;
        padding-left: 32px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__file-info {
        color: rgba(255, 255, 255, 0.6);
        margin-top: 5px;
    }

    &__file-name {
        color: #fff;
        font-family: $font_search_new_2;
        font-size: 11px;
        line-height: 17px;
        font-weight: 300;
        max-height: 40px;
        overflow-wrap: break-word;
        word-break: break-word;
        padding-bottom: 5px;
    }

    &__text {
        width: 100%;
        position: relative;
        margin-bottom: 30px;
    }

    &__credentials {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &--full {
            width: 600px;
        }
    }

    &__textarea {
        font-size: 14px;
        line-height: 20px;
        background: transparent;
        height: 60px;
        display: block;
        resize: none;
        max-height: none;
        overflow: auto;

        &:focus {
            border-bottom: 0;
        }
    }

    &__input {
        width: 100%;
        border: 0;
        font-size: 32px;
        line-height: 38px;
        border-bottom: 1px solid #fff;
        background: transparent;
        font-family: $font_search_new_2;
        font-weight: 300;
        color: #fff;
        &::placeholder {
            color: #fff;
        }

        &--invalid {
            border-color: #ff0e0c;
            &::placeholder {
                color: rgba(#ff0e0c, 0.5);
            }
        }

        &--warning {
            border-color: #c06009;
            color: #c06009;
            &::placeholder {
                color: rgba(#c06009, 0.5);
            }
        }
    }

    &__msg {
        display: inline;

        &--invalid {
            color: #ff0e0c;
        }
    }

    &__drop-down {
        display: inline-block;
        padding-left: 5px;
        text-decoration: underline;
        position: relative;
        color: #4f4f4f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 55px);
        vertical-align: bottom;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        &--medium {
            max-width: calc(100% - 80px);
        }

        &--small {
            max-width: 100px;
        }

        &:after {
            //content: '';
            display: inline-block;
            width: 0;
            height: 0;
            position: absolute;
            top: 10px;
            right: 3px;
            border-style: solid;
            border-width: 5px 4px;
            border-color: transparent;
            border-top-color: #000;
        }
    }

    &__error-msg {
        position: absolute;
        left: 5px;
        top: -18px;
        font-size: 10px;
        padding: 0 5px;
        text-transform: uppercase;
        color: #ff0e0c;
        line-height: 20px;
        &--name {
            background-color: #fff;
            top: auto;
            left: 0;
        }
        &--size {
            background-color: #fff;
            left: auto;
            top: auto;
            bottom: 0;
            right: 160px;
            line-height: 20px;
        }
    }

    &__file-size {
        font-family: $font_search_new_2;
        font-size: 15px;
        line-height: 20px;
    }

    &__error-mark {
        #{$fu}__file-size {
            position: relative;
            top: 0;
            background-color: #fff;
        }
        color: #ff0e0c;
    }
}

.file-upload-progress {
    &__change-btn {
        background-color: rgba(0,0,0,0.6);
        color: #fff;
        font-family: $font_main;
        font-size: 11px;
        line-height: 24px;
        height: 24px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        width: 120px;
        border: 0;
        outline: none;
        cursor: pointer;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            background-color: lighten(rgba(0,0,0,0.6), 20%);
        }
    }
    &__img {
        position: relative;
        max-width: 120px;
        display: block;
    }
    &__img-wrap {
        position: relative;
        width: 120px;
        max-height: 160px;
    }
    &__wrap {
        position: relative;
    }

    &__file-input {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
    }

    &__icon {
        fill: transparent;
        stroke-width: 3px;
    }

    &__file-icon {
        width: 100px;
        height: 125px;

        &--next-to-button {
            margin-bottom: 20px;
        }
    }

    &__circle {
        transition: all 300ms;
        transform-origin: center center;
        transform: rotate(-90deg);
        animation: progress-spinner 3s linear infinite;
    }

    &__text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #BFBFBF;
        fill: #27AE60;
    }
}

.file-upload-credential {
    display: inline-block;
    font-family: $font_search_new_2;
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-align: left;

    &__type {
        margin-right: 15px;
    }

    &--small {
        font-size: 12px;
        line-height: 14px;
        margin-top: 20px;
        width: 100%;
    }

    &__status {
        display: inline-block;
        width: 17px;
        min-width: 17px;
        height: 17px;
        min-height: 17px;
        border-radius: 50%;
        transition: all 300ms;
        box-sizing: border-box;
        border: 1px solid transparent;
        line-height: 16px;
        padding-left: 3px;
        font-size: 0;
        text-align: left;
        margin-right: 5px;
        vertical-align: bottom;

        &--filled {
            font-size: 11px;
            color: #9FCD63;
            background: #fff;
        }

        &--need-to-fill {
            //border-color: #BDBDBD;
            display: none;
        }

        &--invalid {
            border-color: #ff0e0c;
        }
    }

    b {
        display: inline-block;
        font-weight: 400;
        color: #0c0c0c;
        padding-right: 5px;
    }
}

@keyframes progress-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
