.MenuExamplesItem {
    @import "@styles";
    position: relative;
    border: 1px solid  #293341;
    border-radius: 16px;
    padding: 16px;

    &__header {
        display: flex;
        line-height: 20px;
        position: relative;
        padding-right: 100px;

        @media screen and (max-width: 1200px) {
            padding-right: 0;
            flex-wrap: wrap;
        }
    }

    &__title {
        font-size: 15px;
        color: #fff;
        @media screen and (max-width: 1200px) {
            width: 100%;
        }
    }

    &__price {
        position: absolute;
        right: 0;
        top: 0;
        color: $chains-primary;
        font-size: 16px;
        font-weight: 500;

        @media screen and (max-width: 1200px) {
            position: relative;
            margin-top: 8px;
        }
    }

    &__description {
        color: $chains-primary;
        font-size: 13px;
        line-height: 1.4;
        margin-top: 8px;
    }
}