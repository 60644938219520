.FoodProfileConsumerAffinity {
    transition: all 300ms;
    color: #fff;
    $root: &;
    $block_gap: 20px;

    &__wrapper {
        padding: 10px 0 10px;
        width: 170px;
        z-index: 9;
        position: relative;
        background: #2F80ED;
        text-align: center;
        height: 100%;
        border-radius: 0 5px 5px 0;
        transition: all 300ms;
        box-shadow: inset 5px 0 10px 0 rgba(0, 0, 0, .18);

        .is-expand & {
            box-shadow: none;
        }
    }

    &__block {
        position: relative;
        padding: 16px 4px;
        width: calc(100% - #{$block_gap * 2});
        height: 180px;
        margin: 0 $block_gap 10px;
        border-radius: 4px;
        transition: background-color 600ms;
        border: 1px solid #fff;

        &.is-active {
            background: rgba(#000, 0.3);
        }
    }

    &__title {
        color: #fff;
        font-size: 16px;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__circle {
        width: 100px;
        height: 100px;
        display: inline-block;
        padding-top: 10px;
        vertical-align: middle;
        box-sizing: border-box;
        border: 5px solid #FA3060;
        background: #fff;
        border-radius: 50%;
        color: #FF1B45;
        text-align: center;
    }

    &__circle-title {
        display: inline-block;
        font-weight: 300;
        font-size: 30px;
        line-height: 30px;
        color: #FD2553;
        position: relative;
    }

    &__circle-title-value {
        font-size: 15px;
        line-height: 20px;
        color: #FD2553;
        position: absolute;
        left: 100%;
        top: -5px;
    }

    &__circle-subtitle {
        display: block;
        margin-top: -2px;
        font-size: 11px;
        line-height: 11px;
        color: #FD2553;
    }

    &__circle-icon {
        display: block;
        width: 21px;
        height: 21px;
        margin: auto;
    }

    &__items {
        box-shadow: 0 -1px 0 0 #9CB6FB, 0 1px 0 0 #9CB6FB, 0 -2px 0 0 #6481CE, 0 2px 0 0 #6481CE;
        padding: $block_gap 0;
        margin: $block_gap 0;
    }

    &__item {
        padding: 10px 5px 5px;
        display: flex;
        justify-content: center;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-title {
        font-size: 28px;
        display: block;
        font-weight: 300;
        line-height: 100%;
        color: #fff;
        position: relative;
    }

    &__item-title-value {
        font-size: 14px;
        line-height: 20px;
        position: absolute;
        left: 100%;
        top: -5px;
    }

    &__item-subtitle {
        margin-top: 2px;
        margin-left: 20px;
        font-size: 11px;
        line-height: 100%;
        display: block;
        color: #fff;
        text-align: left;
        text-transform: lowercase;
    }

    &__logo {
        width: 100%;
        height: 30px;
        transition: all 300ms;
        cursor: pointer;
        fill: #FF1B45;
        margin: 12px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        text-decoration: none;
        filter: drop-shadow(0 0 2px rgba(#000, 0.3)) drop-shadow(0 0 2px rgba(#000, 0.3));

        &.is-hidden {
            opacity: 0;
            pointer-events: none;
        }

        &:hover {
            opacity: 0.5;
        }

    }

    &__icon {
        width: 30px;
        height: 100%;
        margin-right: 10px;
    }

    &__link {
        color: #ffffff;
    }

    &__hover-data-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        overflow: hidden;
        transition: backdrop-filter 300ms 0s, z-index 0s 800ms, background-color 300ms;
        z-index: 0;

        .is-expand & {
            backdrop-filter: blur(4px);
            background-color: rgba(0, 0, 0, .4);
            z-index: 8;
            transition: backdrop-filter 300ms, background-color 300ms;
        }
    }

    &__hover-data {
        $width: 400px;
        top: 0;
        right: -$width;
        display: block;
        width: $width;
        margin-right: -$width;
        height: 100%;
        box-sizing: border-box;
        padding: 10px 0 10px 20px;
        text-align: left;
        position: absolute;
        background: #2F80ED;
        transform: translateX(0);
        transition: all 300ms;
        z-index: 8;

        //&:after {
        //    content: '';
        //    display: block;
        //    width: 1px;
        //    position: absolute;
        //    right: 0;
        //    top: 20px;
        //    bottom: 20px;
        //    background: #9CB6FB;
        //}

        &.is-show {
            transform: translateX(-100%);
            right: 170px;
        }
    }

    &__hover-data-header {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__chart-title {
        font-size: 15px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
    }

    &__chart-title-small {
        font-size: 12px;
        margin-left: 5px;
        color: #dddddd;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__tab {
        border: 1px solid #fff;
        border-radius: 4px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        margin-right: 4px;
        height: 25px;
        cursor: pointer;
        background: transparent;
        transition: background-color 300ms;
        width: 120px;

        &:hover {
            background: rgba(#000, 0.2);
        }

        &.is-active {
            pointer-events: none;
            background: rgba(#000, 0.4);
        }
    }

    &__switch-button {
        position: relative;
        border: 0;
        padding: 2px 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;
        margin: 0;
        height: 18px;
        cursor: pointer;
        transition: background-color 300ms;
        background: rgba(#000, 0.2);
        font-size: 12px;
        line-height: 18px;

        &:hover {
            background: rgba(#000, 0.4);

            #{$root}__tooltip, #{$root}__tooltip-triangle {
                opacity: 1;
            }
        }

        &.is-active {
            background: #4fbbf9;
            cursor: default;
        }
    }

    &__tooltip {
        opacity: 0;
        $triangle_w_half: 10px;
        $triangle_h_half: 5px;
        $bd_clr: #444444;
        pointer-events: none;
        text-align: center;
        padding: 4px;
        background: $bd_clr;
        position: absolute;
        bottom: calc(100% + #{$triangle_h_half + 2px});
        right: 0;
        border-radius: 4px;
        color: #fff;
        width: 100px;
        font-size: 9px;
        line-height: 12px;
        text-transform: none;
        transition: opacity 300ms;

        &-triangle {
            opacity: 0;
            position: absolute;
            bottom: calc(100% - #{$triangle_h_half * 0.5});
            left: 50%;
            margin-left: - $triangle_w_half;
            height: 0;
            width: 0;
            border-style: solid;
            border-width: $triangle_h_half $triangle_w_half;
            border-color: transparent;
            border-top-color: $bd_clr;
            pointer-events: none;
            transition: opacity 300ms;
        }
    }
}

@keyframes dbl-blink {
    0% {
        background: rgba(#000, 0.1);
    }
    20% {
        background: rgba(#000, 0.3);
    }
    40% {
        background: rgba(#000, 0.1);
    }
    60% {
        background: rgba(#000, 0.3);
    }
    80% {
        background: rgba(#000, 0.1);
    }
    100% {
        background: rgba(#000, 0.3);
    }
}

.FoodProfileConsumerChart {
    &__group {
        color: #fff;
        border-top: 1px solid #9CB6FB;
        border-bottom: 1px solid #6481CE;
        font-size: 13px;
        line-height: 16px;
        padding: 5px 0;

        &:first-child {
            border-top: 0;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &__group-title {
        text-transform: uppercase;
        color: #fff;
        line-height: 24px;
        height: 20px;
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &__name {
        width: 70px;
        color: #e9e9e9;
        flex-grow: 0;
    }

    &__value {
        width: 35px;
        text-align: right;
        color: #e9e9e9;
        flex-grow: 0;
    }

    &__progress-bar {
        flex-grow: 3;
        border-radius: 4px;
        height: 7px;
        position: relative;
        background: rgba(8, 8, 8, .22);
    }

    &__progress-bar-line {
        background: #fff;
        border-radius: 4px;
        height: 7px;
        position: absolute;
        animation: scale-width 500ms;
        transform-origin: 0% 50%;
    }
}

@keyframes scale-width {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
}