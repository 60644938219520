@import '../../style';

.groups {
    $root: &;

    &__header {
        padding: 10px 50px;
        background: linear-gradient(0deg, rgba(249, 248, 248, 0.5) 101.41%, rgba(246, 243, 243, 0.761539) 102.52%, rgba(186, 183, 183, 0.26) 102.53%, rgba(248, 246, 246, 0.35) 103.5%, #FFFFFF 103.57%), #FFFFFF;
        border-bottom: 1px solid transparentize(#000, .6);

        @include clearfix;
    }

    &__header-title {
        font-size: 29px;
        line-height: 44px;
        text-transform: uppercase;
    }

    &__button {
        float: right;
        padding: 10px 40px;
        font-size: 13px;
        line-height: 24px;
        font-weight: 700;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        background: #2d9cdb;
        border: none;
        outline: none;
        cursor: pointer;
        transition: box-shadow .3s ease, opacity .3s linear;

        &:hover {
            box-shadow: 0 1px 5px rgba(68, 92, 116, .6), inset 0 1px 0 hsla(0, 0%, 100%, .5);
        }

        &--thin {
            padding: 7px 25px;

            @media screen and (max-width: 1300px) {
                padding: 7px 10px;
            }
        }

        &--absolute {
            position: absolute;
            top: 15px;
            right: 35px;
            z-index: 1;
        }
    }

    &__scrollbar-outer {
        height: calc(100vh - 125px);
    }

    &__grid-container {
        padding-top: 35px;
    }

    &__grid-header {
        font-size: 24px;
        line-height: 27px;
        margin-bottom: 15px;
    }

    &__content {
        margin: 0 auto;
        padding: 30px 50px 60px;
        background-color: rgb(246, 246, 246);
        min-height: calc(100vh - 125px);

        @include clearfix;
    }

    &__list {
        position: relative;
        z-index: 1;
        float: left;
        width: 100%;
        min-width: 500px;
    }

    &__item {
        position: relative;
        cursor: default;
        height: 260px;
        float: left;
        @include media-min(1661px) {
            @include init-grid(5, 15px, 1600px);
        }
        @include clearfix;

        @include media(1441px, 1660px) {
            &:nth-child(5n+1) {
                margin-left: grid-margin(1440px, 17px);
                clear: none;
            }
            @include init-grid(4, 17px, 1440px);
        }

        @include media(1281px, 1440px) {
            &:nth-child(4n+1) {
                margin-left: grid-margin(1280px, 17px);
                clear: none;
            }
            @include init-grid(3, 17px, 1280px);
        }

        @include media-max(1280px) {
            &:nth-child(3n+1) {
                margin-left: grid-margin(1024px, 17px);
                clear: none;
            }
            @include init-grid(2, 17px, 1024px);
        }

        &:hover {
            #{$root}__item-actions {
                opacity: 1;
            }
        }

        &:first-child {
            border-top: none;
        }

        &--current, &:hover {

            #{$root}__item-title {
                color: #000;
            }
        }

        &--current {
            position: relative;
            transform: translateY(-5px);
            box-shadow: 1px 3px 14px rgba(0,0,0,.1), 0 1px 29px rgba(0,0,0,.24);

            #{$root}__item-actions {
                opacity: 1;
            }
        }
        &:nth-child(n+2) {
            padding: 0;
        }
    }

    &__item-title {
        font-size: 18px;
        font-weight: 500;
        color: #000;
        //color: #8D8D8D;
    }

    &__details {
        float: right;
        position: relative;
        max-width: calc(100% - 510px);
        width: 70%;
        background-color: #fff;
        box-shadow: 1px 3px 14px rgba(#000, .1);
    }

    &__remove-member {
        cursor: pointer;
        opacity: 0;
        transition: opacity .2s;

        &:hover {
            opacity: 1;
        }
    }

    &__table {

        .table__data {
            text-transform: lowercase;
        }

        .table__data-row {
            &:hover {
                #{$root}__remove-member {
                    opacity: 1;
                }
            }
        }
    }

    &__remove-icon {
        margin-right: 6px;
    }

    &__item-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        position: absolute;
        width: 140px;
        height: 100%;
        top: 0;
        right: 0;
        text-align: center;
        background-color: #73818D;
        text-transform: uppercase;

        button {
            margin: 20px 0;
        }

        @include media-max(1660px) {
            width: 150px;
        }
        @include media-max(1280px) {
            width: 200px;
        }
    }

    &__item-action {
        color: #fff;
        position: relative;
        font-size: 12px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        padding: 5px 15px;

        //&:not(:last-child):after {
        //    content: '';
        //    display: block;
        //    position: absolute;
        //    right: 0;
        //    top: 50%;
        //    transform: translateY(-50%);
        //    width: 1px;
        //    height: 16px;
        //    background-color: #4F4F4F;
        //}

        &:hover {
            text-decoration: underline;
        }
    }

    &__action-icon {
        margin-bottom: 5px;
        fill: #fff;
        display: inline-block;
    }
}

.group-tile {
    display: block;
    text-decoration: none;
    $root: &;
    transition: transform 300ms;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    color: #848484;
    padding: 10px;
    background-color: #fff;

    &__user-name {
        height: 16px;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;
    }

    &__wrapper {
        max-width: 100%;
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: center;
    }

    &__actions-list {
        float: right;
        padding-top: 12px;
    }

    &__button {
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background: #2D9CDB;
        padding: 10px;
        margin-left: -10px;
        margin-top: -10px;
        transition: all 300ms;
        cursor: pointer;

        #{$root}:hover & {
            background: darken(#2D9CDB, 10%);
        }

        &--inner {
            width: 100%;
            height: 100%;
            background: #2180B6;
            border: 1px dashed #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            color: #fff;
            font-size: 30px;
            line-height: 33px;
            text-align: center;
            padding: 20px;
            transition: all 300ms;
            #{$root}:hover & {
                background: lighten(#2180B6, 10%);
            }

            .group-tile__title {
                font-size: 32px;
                @include IE10plus {
                    margin-left: 20px;
                }
            }
        }
    }

    &__icon-plus {
        display: block;
        height: 48px;
        width: 48px;
        position: relative;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 6px;
            top: 0;
            left: 50%;
            margin-left: 10px;
            background: #fff;

            @include media-max(1440px) {
                margin-left: -15px;
            }
        }

        &:after {
            transform: rotate(-90deg);
        }
    }

    &__title-wrap {
        width: calc(100% - 140px);
        padding: 0 5px;
        float: left;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        #{$root}:nth-child(7n) & {
            #{$root}__title {
                @include multiLineEllipsis(33px, 4, #56CCF2, right);
                text-align: center;
            }
            background-color: #56CCF2;
        }
        #{$root}:nth-child(7n+1) & {
            #{$root}__title {
                @include multiLineEllipsis(33px, 4, #9B51E0, right);
                text-align: center;
            }
            background-color: #9B51E0;
        }
        #{$root}:nth-child(7n+2) & {
            #{$root}__title {
                @include multiLineEllipsis(33px, 4, #2D9CDB, right);
                text-align: center;
            }
            background-color: #2D9CDB;
        }
        #{$root}:nth-child(7n+3) & {
            #{$root}__title {
                @include multiLineEllipsis(33px, 4, #BB6BD9, right);
                text-align: center;
            }
            background-color: #BB6BD9;
        }
        #{$root}:nth-child(7n+4) & {
            #{$root}__title {
                @include multiLineEllipsis(33px, 4, #8DAD58, right);
                text-align: center;
            }
            background-color: #8DAD58;
        }
        #{$root}:nth-child(7n+5) & {
            #{$root}__title {
                @include multiLineEllipsis(33px, 4, #219653, right);
                text-align: center;
            }
            background-color: #219653;
        }
        #{$root}:nth-child(7n+6) & {
            #{$root}__title {
                @include multiLineEllipsis(33px, 4, #EB5757, right);
                text-align: center;
            }
            background-color: #EB5757;
        }


        #{$root}:nth-child(7n):hover & {
            #{$root}__title::after, #{$root}__title::before {
                background-color: darken(#56CCF2, 10%);
            }
            background-color: darken(#56CCF2, 10%);
        }
        #{$root}:nth-child(7n+1):hover & {
            #{$root}__title::after, #{$root}__title::before {
                background-color: darken(#9B51E0, 10%);
            }
            background-color: darken(#9B51E0, 10%);
        }
        #{$root}:nth-child(7n+2):hover & {
            #{$root}__title::after, #{$root}__title::before {
                background-color: darken(#2D9CDB, 10%);
            }
            background-color: darken(#2D9CDB, 10%);
        }
        #{$root}:nth-child(7n+3):hover & {
            #{$root}__title::after, #{$root}__title::before {
                background-color: darken(#BB6BD9, 10%);
            }
            background-color: darken(#BB6BD9, 10%);
        }
        #{$root}:nth-child(7n+4):hover & {
            #{$root}__title::after, #{$root}__title::before {
                background-color: darken(#8DAD58, 10%);
            }
            background-color: darken(#8DAD58, 10%);
        }
        #{$root}:nth-child(7n+5):hover & {
            #{$root}__title::after, #{$root}__title::before {
                background-color: darken(#219653, 10%);
            }
            background-color: darken(#219653, 10%);
        }
        #{$root}:nth-child(7n+6):hover & {
            #{$root}__title::after, #{$root}__title::before {
                background-color: darken(#EB5757, 10%);
            }
            background-color: darken(#EB5757, 10%);
        }
        @include media-max(1660px) {
            width: calc(100% - 150px);
        }
        @include media-max(1280px) {
            width: calc(100% - 200px);
        }
    }

    &__footer {
        padding: 25px;
        height: 75px;
        box-sizing: border-box;
        border-top: 1px solid #cfcfcf;
        text-align: right;
        width: 100%;
        background: #ffffff;
    }

    &__text {
        display: inline-block;
        text-align: center;
        padding: 25px 0;
        height: 100%;
        font-size: 13px;
        line-height: 20px;
        box-sizing: border-box;
        width: 140px;
        color: #fff;
        overflow: hidden;
        background-color: #73818D;

        #{$root}:hover & {
            opacity: 0;
        }
        &-header {
            font-size: 18px;
            padding-bottom: 19px;
            @include media-max(1440px){
                font-size: 23px;
            }
        }
        &-content {
            padding: 0 10px;
        }
        &-footer {
            padding: 10px 10px 0;
        }

        @include media-max(1660px) {
            width: 150px;
        }
        @include media-max(1280px) {
            width: 200px;
        }
    }

    &__left {
        width: 50%;
        float: left;
    }

    &__right {
        width: 50%;
        float: right;
        text-align: right;
    }

    &__title {
        max-width: calc(100% - 1em);
        font-size: 24px;
        font-family: $font_search_new_2;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        transition: all 300ms;
        -ms-flex-negative: 0;
        overflow-wrap: break-word;
        word-wrap: break-word;
        &--shrink {
            -ms-flex-negative: 1;
        }
        @include media-max(1440px) {
            font-size: 32px;
        }
    }

    &__sub-title {
        font-size: 18px;
        line-height: 20px;
        color: #848484;

        b {
            color: #333333;
        }
    }

    &__description {
        width: 100%;
        height: 44px;
        font-size: 18px;
        line-height: 22px;
        overflow: hidden;
        text-align: justify;
    }

    &__icon {
        fill: #4f4f4f;
        transition: all 300ms;
        margin: 0 auto;
        vertical-align: middle;
        width: 100%;
        display: block;
    }
}
