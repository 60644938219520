.my-documents-project-tile {
    overflow: hidden;
    display: block;
    text-decoration: none;
    width: 100%;
    margin-bottom: 15px;
    $root: &;
    transition: transform 300ms;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    z-index: 1;
    position: absolute;
    color: #848484;
    height: 100%;
    padding: 10px;
    background-color: #ffffff;

    &--blocked {
        background-color: #BDBDBD;
        cursor: default;
    }

    &--transparent {
        position: relative;
        height: auto;
        background: none;
        margin-bottom: 0;
        float: left;
        border: 0;
        color: #0c0c0c;
        padding: 0;
        margin-bottom: 0;
        //min-height: 56px;

        #{$root}__main {
            padding: 15px 0 0;
            border-top: 0;
        }

        #{$root}__description {
            height: auto;
            font-size: 20px;
            color: #0c0c0c;
        }

        #{$root}__footer {
            display: block;
            border-color: #303030;
            padding: 15px 0;
            background-color: transparent;
        }
    }

    &__button {
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background: #2D9CDB;
        padding: 10px;
        margin-left: -10px;
        margin-top: -10px;
        transition: all 300ms;
        cursor: pointer;

        #{$root}:hover & {
            background: darken(#2D9CDB, 10%);
        }

        &--inner {
            width: 100%;
            height: 100%;
            background: #2180B6;
            border: 1px dashed #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #fff;
            font-size: 30px;
            line-height: 33px;
            text-align: center;
            padding: 20px;
            transition: all 300ms;
            #{$root}:hover & {
                background: lighten(#2180B6, 10%);
            }
        }
    }

    &__icon-plus {
        display: block;
        height: 56px;
        width: 56px;
        position: relative;
        margin-bottom: 20px;

        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            height: 100%;
            width: 6px;
            top: 0;
            left: 50%;
            margin-left: -3px;
            background: #fff;
        }

        &:after {
            transform-origin: 3px 28px;
            transform: rotate(-90deg);
        }
    }

    &__header {
        padding: 30px;
        height: 240px;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 300ms;

        &--color-0 {
            background-color: #828282;
        }
        &--color-1 {
            background-color: #56CCF2;
            &:hover {
                background-color: darken(#56CCF2, 10%);
            }
        }
        &--color-2 {
            background-color: #9B51E0;
            &:hover {
                background-color: darken(#9B51E0, 10%);
            }
        }
        &--color-3 {
            background-color: #2D9CDB;
            &:hover {
                background-color: darken(#2D9CDB, 10%);
            }
        }
        &--color-4 {
            background-color: #BB6BD9;
            &:hover {
                background-color: darken(#BB6BD9, 10%);
            }
        }
        &--color-5 {
            background-color: #8DAD58;
            &:hover {
                background-color: darken(#8DAD58, 10%);
            }
        }
        &--color-6 {
            background-color: #219653;
            &:hover {
                background-color: darken(#219653, 10%);
            }
        }
        &--color-7 {
            background-color: #EB5757;
            &:hover {
                background-color: darken(#EB5757, 10%);
            }
        }
    }

    &__footer {
        padding: 25px;
        height: 75px;
        box-sizing: border-box;
        border-top: 1px solid #cfcfcf;
        text-align: right;
        width: 100%;
        background: #ffffff;
    }

    &__text {
        padding: 10px 0 0;
        height: 50px;
        font-size: 12px;
        line-height: 20px;
        box-sizing: border-box;
        width: 100%;
        color: rgba(#000, .6);
        transition: all 300ms;

        #{$root}:hover & {
            color: rgba(#000, .7);
        }

        @include media(1281px, 1700px) {
            font-size: 11px;
        }

        @include media(1200px, 1280px) {
            font-size: 9px;
        }
    }

    &__left {
        width: 72%;
        float: left;
    }

    &__right {
        width: 28%;
        float: right;
        text-align: right;
    }

    &__title {
        font-size: 26px;
        line-height: 30px;
        font-weight: 700;
        color: #fff;
        text-decoration: none;
        transition: all 300ms;
        max-width: 100%;
        max-height: 100%;

        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;

        //@include IE10plus {
        //    word-break: break-all;
        //}

        @include media-max(1700px) {
            font-size: 20px;
        }

        @include media-max(1440px) {
            font-size: 16px;
        }

        @include media-max(1280px) {

            font-size: 14px;
        }

        &--small {
            font-size: 16px;
            line-height: 22px;
        }

        &--big {
            font: inherit;
            word-break: normal;

            @include IE10plus {
                word-break: normal;
            }
        }
    }

    &__small-text {
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 5px;
    }

    &__sub-title {
        font-size: 18px;
        line-height: 20px;
        color: #b0b0b0;
        margin-bottom: 5px;

        b {
            color: #333333;
        }
    }

    &__description {
        width: 100%;
        height: 44px;
        font-size: 18px;
        line-height: 22px;
        overflow: hidden;
        text-align: justify;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__action {
        display: inline-block;
        color: #4f4f4f;
        transition: all 300ms;
        margin-right: 15px;
        font-size: 10px;
        line-height: 18px;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        width: 50px;
        border: 0;
        background-color: transparent;

        &--in-one-line {
            display: flex;
            float: left;
            margin: 0 20px;
            font-size: 12px;
            width: auto;
            align-items: flex-end;
            line-height: 1;

            .my-documents-project-tile__icon {
                margin-right: 10px;
                width: 20px;
            }
        }

        &--inline {
            display: inline-flex;
        }

        &--line {
            width: auto;
        }

        &:hover {
            color: #2D9CDB;
            #{$root}__icon {
                fill: #2D9CDB;
            }
        }

        &--highlighted {
            color: #2D9CDB;
            #{$root}__icon {
                fill: #2D9CDB;
            }

            &:hover {
                color: orange;
                #{$root}__icon {
                    fill: orange;
                }
            }
        }
        &__gutter-sizer {

        }
    }

    &__icon {
        fill: #4f4f4f;
        transition: all 300ms;
        vertical-align: middle;
        display: block;
    }
}

@keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3)
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1)
    }

    40% {
        transform: scale3d(.9, .9, .9)
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03)
    }

    80% {
        transform: scale3d(.97, .97, .97)
    }

    to {
        opacity: 1;
        transform: scaleX(1)
    }
}

.bounceIn {
    animation: bounceIn .75s both;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 40%, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}

.fadeInUp {
    //animation: fadeInUp 500ms both;
    @for $i from 1 to 12 {
        &:nth-child(n+#{$i}) {
            animation-delay: #{$i * 100ms};
        }
    }
}
