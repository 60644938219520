@import '@styles';

$food_profile_content_widget_height: 310px;
$food_profile_content_widget_header_height: 54px;

.ContentWidget {
    $root: &;
    @include init-grid();
    height: $food_profile_content_widget_height;
    background: #F8F6F6;
    border-radius: 3px;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.18);
    transition: all 300ms;
    margin-bottom: 0;
    flex-grow: 1;

    &:not(#{$root}--no-hover) {
        &:hover {
            margin-top: -5px;
            transition: margin-top 300ms, box-shadow 300ms;
            box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.4);
        }
    }

    &--transparent {
        background-color: transparent;
        box-shadow: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        padding: 20px;
        color: #4f4f4f;

        p {
            max-width: 220px;
        }
    }

    &--hover-data {
        position: absolute;
        top: 0;
        left: 0;
        height: auto;
        margin: 0;
        padding-bottom: 15px;
        transition: opacity 500ms;
        z-index: 2;

        &:not(.is-opened) {
            opacity: 0;
            height: 0;
            overflow: hidden;
        }

    }

    @for $i from 2 to 6 {
        &--col-#{$i} {
            width: grid_width($i);
        }
    }

    &--no-margin {
        margin-left: 0;
    }

    &__col {
        height: 100%;
        margin-bottom: 0;

        @for $i from 1 to 6 {
            &--#{$i} {
                @include init-grid($i, 0px, static_grid_width($i));
                padding-right: 5px;
            }
        }
    }

    &__header {
        height: $food_profile_content_widget_header_height;
        background: #F6F3F3;
        padding: 10px 10px 5px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #FFFFFF;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            box-shadow: 0 -1px 0 #CFCFCF;
            left: 0;
            bottom: -1px;
        }
    }

    &__title {
        font-size: 16px;
        text-transform: uppercase;
        color: #303030;
    }

    &__section-wrapper {
        position: relative;
        min-height: 236px;
        display: flex;
        flex-direction: column;
    }

    &__section {
        padding: 10px;
        box-sizing: border-box;
        justify-content: center;
        display: flex;
        flex-direction: column;

        &--small {
            text-align: left;
            padding: 10px 12px;

            @include media-max(1280px) {
                padding: 6px 12px;
            }
        }

        &:nth-child(2) {
            border-top: 1px solid #CFCFCF;
        }
    }

    &__link {
        font-size: 12px;
        line-height: 16px;
        text-decoration: underline;
        color: #3D8CDE;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        &--light {
            color: #ffffff;
        }

        &--disable {
            color: #a6a6a6;
            font-size: 14px;
        }
    }

    &__button {
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        border: 1px solid #3D8CDE;
        width: 170px;
        color: #3D8CDE;
        margin-right: auto;
        margin-left: auto;
        transition: all 300ms;
        cursor: pointer;
        display: block;
        text-decoration: none;

        &--filled {
            background: #01428E;
            color: #fff;
            width: 180px;
            height: 35px;
            font-family: $font_text;
            font-size: 12px;
            line-height: 34px;
            margin-top: 20px;
            border-color: transparent;
        }

        &:hover, &--hover {
            background: rgba(#3D8CDE, .7);
            color: #fff;
        }

        &--hover {
            cursor: default;
        }

        &--inactive {
            pointer-events: none;
        }

        &--mt {
            margin-top: 7px;
        }
    }

    &__link-wrapper {
        display: block;
        margin-top: -5px;
        text-align: center;

        &--clear {
            clear: both;
        }
    }

    &__caption {
        color: #7a7a7a;
        font-size: 12px;
        line-height: 14px;
        height: 20px;
        text-align: center;
    }
}