.ChainsDetailsContacts {
    @import "../../../ChainPage.helpers.styles";
    font-size: 13px;
    line-height: 18px;
    width: 100%;

    &__icon {
        margin-right: 12px;
        width: 32px;
        height: 32px;
    }

    &__web-link {
        margin-left: auto;
        color: color("info");
    }

    &__social-link {
        margin-left: 4px;
        height: 24px;
        transition: transform 300ms;

        &:hover {
            transform: scale(1.2);
        }
    }

    &__row {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__column {
        display: flex;
        flex-direction: column;

        &:last-child {
            align-items: flex-end;
        }
    }

    &__item {
        height: 50px;
        display: flex;
        align-items: center;
        flex-direction: row;

        &:nth-child(n + 2) {
            margin-top: 16px;
        }
    }
}
