.NotesPopup {
    $header: 60px;
    $global-header: 60px;

    position: relative;
    height: 100%;
    height: calc(100vh - #{$global-header});
    color: #fff;
    width: calc(100vw - 64px);
    max-width: 1680px;

    &__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        color: #ffffff;
        height: 100%;
        background: #131921;
        border-radius: 8px;

        @media screen and (min-width: 1680px) {
            max-width: 1680px;
        }
    }

    &__header {
        height: $header;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
    }

    &__close {
        cursor: pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    &__body {
        height: calc(100% - #{$header});
        padding: 24px;
    }

    &__cell-icon {
        margin-right: 6px;
        margin-top: -2px;
    }
}