@mixin media-min($media-min, $property: width) {
    @media only screen and (min-#{$property}: $media-min) {
        @content
    }
}

@mixin media-max($media-max, $property: width) {
    @media only screen and (max-#{$property}: $media-max) {
        @content
    }
}

@mixin media($media-min, $media-max, $property: width) {
    @media only screen and (min-#{$property}: $media-min) and (max-#{$property}: $media-max) {
        @content
    }
}
