.InstantChartsPermissionOverlay {
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 300;
    font-size: 22px;
    line-height: 25px;
    z-index: 10;


    &__footer {
        display: flex;
    }

    &__button {
        text-align: center;
        width: auto;
        min-width: 200px;
        height: 35px;
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
        padding: 0 20px;
        transition: all 300ms;
        cursor: pointer;
        display: block;
        text-decoration: none;
        background: #01428E;
        color: #fff;
        font-family: $font_text;
        font-size: 16px;
        line-height: 33px;
        border-color: transparent;
        text-transform: uppercase;

        &--hover, &:hover {
            background: rgba(#3D8CDE, .7);
            color: #fff;
        }

        &--hover {
            cursor: default;
        }

        &--green {
            background: #86D004;
            &:hover {
                background: orange;
                color: #fff;
            }
        }

        &:disabled {
            cursor: default;
            background-color: darken(#fcfcfc, 30%);
        }
    }
}