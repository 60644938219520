.NotesFooter {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    &__action {
        margin-left: 16px;
    }
}
