.tooltip {
    position: relative;

    &__container {
        position: absolute;
        left: 100%;
        top: 0;
        background: #fff;
        color: #000;
        z-index: 1;
        padding: 10px;
        border-radius: 6px;
        box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.18);
        text-align: left;
        max-height: calc(100vh - 173px);
        overflow: auto;
        width: calc(100% + 20px); // to remove horizontal scroll in mozilla firefox
        min-width: 285px;
    }

    &__text {
        display: block;
        margin-bottom: 3px;
        font-size: 14px;
        white-space: nowrap;
        text-transform: capitalize;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
