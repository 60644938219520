@use "sass:math";

@import '../../style.scss';


@keyframes popup-fade-in {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes popup-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.popup {
    $root: &;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    transform-origin: center center;
    background-color: rgba(0, 0, 0, 0.35);
    animation: popup-appear 300ms;

    @include IE10plus {
        background-color: rgba(77, 77, 77, 0.95);
    }

    &--fade-in {
        animation: popup-fade-in 1000ms;
    }

    &--no-overflow {
        overflow-y: hidden;
    }

    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

    user-select: none;

    &__note-visibility {
        color: #fff;
        font-family: $font_main;
        font-size: 18px;
        line-height: 20px;
        text-decoration: underline;
        padding: 0;
        border: 0;
        outline: none;
        background: transparent;
        cursor: pointer;
    }

    &__note-content {
        padding: 25px;
    }

    &__note-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__note-footer-right {
        margin-left: auto;
    }

    &--fade-in {
        animation: popup-fade-in 1000ms;
    }

    &__label {
        font-size: 16px;
        font-family: $font_search_new_2;
        line-height: 19px;
        color: #fff;
        font-weight: 300;

        &--uppercase {
            text-transform: uppercase;
        }

        &--transp {
            opacity: 0.6;
        }
    }

    &__upload {
        &-left {
            height: 100%;
            margin-top: -5px;
            width: calc(100% - 185px);
            display: inline-block;
            color: #fff;
        }
        &-right {
            width: 185px;
            height: 100%;
            float: right;
            display: inline-block;

            @include clearfix;
        }
    }

    &__dropzone {
        background-color: inherit;
        z-index: 5;
        border-radius: 22px;
    }

    &__helper {
        &-wrapper {
            width: 290px;
            height: 345px;
            background-color: #f2f2f2;
            border-radius: 0 9px 9px 0;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(100%, -50%);
            z-index: 1;
            padding: 20px 25px 35px;

            &:before {
                box-shadow: 5px 0 20px 3px rgba(0, 0, 0, 0.25);
                content: '';
                display: block;
                height: 100%;
                width: 0;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        &-title {
            font-family: $font_search_new_2;
            font-size: 18px;
            line-height: normal;
            font-weight: 600;
            color: #000;
            padding: 20px 0 10px;
            text-transform: uppercase;
            &--main {
                padding: 0 0 15px;
            }
        }
        &-item {
            &--list {
                list-style-type: circle;
                &:before {
                    content: "\2022";
                    margin-right: 5px;
                }
            }
        }
        &-text {
            font-family: $font_search_new_2;
            font-size: 14px;
            line-height: normal;
            color: #1b1b1b;
        }
        &-topside {
            border-bottom: 1px solid #b5b5b5;
            padding-bottom: 3px;
        }
    }

    &__scrollbar-inner {
        width: calc(100% - 120px);
        display: inline-block;
        margin-left: 11px;
        &--fixed {
            height: 118px;
        }
    }

    &__button-wrapper {
        padding: 15px 25px 20px;
        @include clearfix;
    }

    &__form-wrapper {
        padding: 10px 25px 10px 25px;
        &--bordered {
            border: solid white;
            border-width: 1px 0;
        }
    }

    &__flex-container {
        display: flex;

        &--aligned {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__input-container {
        padding: 25px 20px 15px 25px;
        @include clearfix;
    }

    &__header {
        min-height: 73px;
        padding: 25px;
        display: flex;
        align-items: center;
        background-color: #262626;
        border-radius: 22px 22px 0 0;
        @include clearfix;
        color: white;
        position: relative;
        z-index: 2;

        &-files {
            font-size: 16px;
        }

        &-company {
            margin-top: 5px;
            font-size: 12px;
            line-height: 1;
            max-width: 220px;
            &-name {
                display: inline-block;
                margin-left: 3px;
                text-decoration: underline;
                cursor: pointer;
                line-height: normal;
            }
        }

        &-secondary-text {
            font-size: 12px;
            font-family: $font_main;
            line-height: 14px;
            position: absolute;
            right: 13px;
            top: 20px;
            text-align: right;
            max-width: 120px;
        }

        &-title {
            font-size: 32px;
            line-height: 32px;
            font-weight: 700;

            @include media-max(1200px) {
                padding: 0 10px;
            }
            &--go {
                font-size: 42px;
                line-height: 42px;
                margin-left: 25px;
            }
        }

        &--blue {
            background-color: #53A0FD;
        }
    }

    &__header-right {
        position: absolute;
        right: 25px;
        font-family: $font_search_new_2;
        line-height: 19px;
        color: #fff;
        letter-spacing: 0.05em;
        font-weight: 300;
        text-align: right;
    }

    &--light {
        background-color: rgba(#fff, 0.99);
    }

    &--overflow-scrollable {
        overflow-y: auto;
        z-index: 5;

        #{$root}__inner {
            position: static;
            margin: 80px auto;
            transform: translate(0, 0);
            max-height: inherit;
        }

        #{$root}__text {
            font-family: "Work Sans", "Source Sans Pro", sans-serif;
            font-size: 16px;
            line-height: 19px;
            color: #fff;
            padding: 30px 25px 25px;
            font-weight: 300;
        }
    }

    &__inner {
        position: relative;
        //top: 50vh;
        //left: 50%;
        width: 864px;
        //max-width: 95%;
        //transform: translate(-50%, -50%);
        background-color: #2D9CDB;
        //max-height: 100vh;
        border-radius: 22px;

        &--x-big {
            width: size(1240px);
        }

        &--big {
            width: 1160px;
        }

        &--md {
            width: 865px;
        }

        &--small {
            width: 650px;
        }

        &--xs {
            width: 540px;

        }

        &--white {
            background-color: #fff;
        }

        &--transparent {
            background-color: transparent;
        }

    }

    &__dropzone-active {
        box-shadow: inset 0 0 5px 5px rgba(128, 195, 232, 1);
        background-color: rgba(128, 195, 232, 0.5);
    }

    &-white {
        background-color: #fff;

        .popup__header {
            background-color: #31384A;
        }
    }

    &-red {
        background-color: #C3272F;
    }

    &__input-container {
        position: relative;
    }

    &__scrollbars-wrapper {
        width: 100vw;
        height: 100vh;
    }

    &__project-input {
        font-weight: 300;
        font-size: 18px;
        line-height: 18px;
        padding-bottom: 2px;
        border: 0;
        border-bottom: 1px solid #FFFFFF;
        width: 100%;
        display: block;
        margin-bottom: 5px;
        background-color: transparent;
        color: #fff;

        &::placeholder {
            color: #E5E3E3;
        }
    }

    &__options {
        float: right;
        position: absolute;
        right: 0;
        top: 15px;
        text-align: right;
        &--bottom {
            position: relative;
            top: 0;
        }
        @include clearfix;
    }

    &__project-text-area {
        line-height: 39px;
        font-weight: 300;
        font-size: 18px;
        width: calc(100% - 105px);
        background-image: linear-gradient(transparent 0, transparent 38px, #FFFFFF 0px);
        background-attachment: local;
        background-size: 100% 39px;
        background-position-y: -1px;
        background-color: transparent;
        padding-bottom: 2px;
        color: #ffffff;
        &--wide {
            width: 100%;
        }
    }

    &__input-label {
        font-size: 12px;
        line-height: 25px;
        font-family: $font_search_new_2;
        text-transform: uppercase;
        color: #fff;

        &--no-transform {
            text-transform: none;
        }

        &--light {
            font-weight: 300;
        }
    }

    &__description-label {
        float: left;
        @include clearfix;
    }

    &__margin {
        margin-bottom: 5px;
        &--big {
            margin-bottom: 15px;
        }
    }

    &__title {
        font-size: 32px;
        line-height: 30px;
        font-weight: 700;

        &--centered {
            text-align: center;
        }

        &--medium {
            font-size: 24px;
        }

        &--uppercase {
            text-transform: uppercase;
        }

        &--smaller-font {
            font-size: 19px;
        }
    }

    &__sup-title {
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 700;
    }

    &__input-label {
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        font-family: Muli, sans-serif;
        text-transform: uppercase;
    }

    &__upper {
        text-transform: uppercase;
    }

    &__main {
        position: relative;

        &--terms {
            height: calc(100vh - 250px);
        }

        &-image-container {
            margin-left: 25px;
        }

        &-image {
            max-height: calc(243px - 74px);
        }

        &-wrapper {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;

            & > div {
                display: flex;
                justify-content: center;
                align-items: center;

                &:first-child {
                    margin-bottom: 17px;
                }
            }
        }

        &--align-middle {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &-content {
            @include media-max(1200px) {
                padding: 10px;
            }
            &:after {
                clear: both;
                display: table;
                content: '';
            }
            &--go {
                padding: 37px 25px;
                font-size: 32px;
                line-height: 46px;
                font-weight: 700;
                color: #0C0C0C;
                display: flex;
                height: 243px;
                align-items: center;
            }
        }
    }

    &__input-outer {
        position: relative;
        padding-bottom: 5px;
        border-bottom: 1px solid #000;
        display: flex;
        justify-content: space-between;
        padding-left: 50px;
    }

    &__input {
        display: inline-block;
        flex-grow: 1;
        padding-left: 10px;
        font-size: 18px;
        border: none;

        &--big {
            font-size: 24px;
            padding: 0;
            width: 600px;
            max-width: 100%;
        }

        &--underlined {
            border-bottom: 1px solid;
        }

        &--backgrounded {
            background-color: #FF757C;
            color: #FFFFFF;
            padding: 0 7px;
            font-family: $font_search_new_2;
            font-size: 48px;
            font-weight: 300;
            width: 100%;
        }

        &::-webkit-input-placeholder {
            color: #fff;
        }

        &:-ms-input-placeholder {
            color: #fff;
        }
    }

    &__error-email {
        position: absolute;
        right: 0;
        top: 50%;
        font-size: 18px;
        color: red;
        transform: translateY(-50%);
    }

    &__all-list {
        font-family: Muli, sans-serif;
        font-size: 18px;
        color: #2D9CDB;
        text-decoration: underline;
        cursor: pointer;
    }

    &__suggest {
        position: relative;
        z-index: 2;
        clear: both;
    }

    &__suggest-list {
        position: absolute;
        width: 100%;
        max-height: 225px;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 0 0 6px transparentize(#000, .4);
        border-right: 1px solid #BAB4B4;
        border-bottom: 1px solid #BAB4B4;
        border-left: 1px solid #BAB4B4;

        #{$root}__suggest-item {
            cursor: pointer;
        }
    }

    &__selected-list {
        max-height: 300px;
        margin-bottom: -25px;
        overflow-y: auto;
        background-color: #fff;
    }

    &__suggest-item {
        position: relative;
        min-height: 75px;
        padding: 13px 7px;
        border-bottom: 1px solid #BAB4B4;

        &:last-child {
            border-bottom: 1px solid transparent;
        }

        &--packages-page {
            padding-left: 30px;
        }

        &--selected {
            background-color: rgba(#2DC0E6, .15);
        }

        &:hover {
            background-color: rgba(#2DC0E6, .15);
        }
    }

    &__suggest-circle {
        float: left;
        width: 42px;
        height: 42px;
        margin-top: 3px;
        margin-right: 7px;
        font-weight: 300;
        line-height: 42px;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        background-color: #2DC0E6;

        &--group {
            background-color: #F2994A;
        }

        &--custom {
            background-color: #7E7E7E;
        }
    }

    &__remove {
        position: absolute;
        top: 25px;
        right: 7px;
        cursor: pointer;
        opacity: .6;

        &:hover {
            opacity: 1;
        }
    }

    &__suggest-name {
        min-height: 12px;
        font-size: 22px;
        color: #000;
        font-family: $font_search_new_2;
    }

    &__suggest-email {
        font-size: 22px;
        color: #979797;
    }

    &__textarea {
        width: 100%;
        height: 100%;
        //height: calc(4em + 6px);
        //height: calc(2em + 6px);
        max-width: 100%;
        //max-height: calc(2em + 6px);
        padding: 0 8px 0 0;
        border: none;
        border-bottom: 1px solid transparent;
        resize: none;
        overflow: hidden;
        position: absolute;

        &:focus {
            //border-bottom: 1px solid #000;
            outline: none;
        }

        &::-webkit-input-placeholder {
            font-family: $font_main;
            font-weight: normal;
        }
        &::-moz-placeholder {
            font-family: $font_main;
            font-weight: normal;
        }
        &:-ms-input-placeholder {
            font-family: $font_main;
            font-weight: normal;
        }
        &:-moz-placeholder {
            font-family: $font_main;
            font-weight: normal;
        }

        &--big {
            max-height: none;
            height: 100px;
            resize: none;
            font-family: $font_main;

            &:focus {
                border: 0;
            }
        }
    }

    &__textarea-wrapper {
        position: relative;
    }

    &__textarea-fake {
        box-sizing: border-box;
        font-family: Helvetica, Arial, sans-serif;
        padding-bottom: 1.15em; /* A bit more than one additional line of text. */
        visibility: hidden;
        width: 100%;
    }

    &__textarea-scrollbar {
        height: calc(3em + 20px);
        margin-bottom: 10px;
    }

    &__textarea-label {
        display: block;
        color: #ffffff;
        font-weight: 700;
        font-family: $font_search_new_2;
        font-size: 12px;
        text-transform: uppercase;
    }

    &__textarea-outer {
        padding: 5px 20px 0;
        border-top: 1px solid #C6C6C6;

        @include media-max(1200px) {
            padding: 5px 10px 10px;
        }

        &--share-popup {
            border-top: none;
        }
    }

    &__link {
        font-family: $font_main;
        font-size: 20px;
        margin-right: 10px;
        text-transform: uppercase;
        color: #fff;
        background-color: transparent;
        border: none;
        cursor: pointer;
        transition: color .25s;
        &--small {
            font-size: 14px;
            line-height: 20px;
            margin-right: 0;
        }
        &--big {
            font-size: 25px;
        }
        &--md {
            font-size: 18px;
        }
        &--no-indent {
            margin-right: 0;
        }
        &--indent-left {
            margin-left: 10px;
            padding: 1px 6px;
        }

        &--gray {
            text-transform: capitalize;
            color: #828282;
            line-height: 1;
        }

        &:hover {
            color: darken(#fff, 15%);
            text-decoration: underline;
        }
        &--light {
            text-transform: none;
            font-weight: 300;
            color: #828282;
            font-family: $font_search_new_2;
        }

        &--comfortaa {
            font-family: $font_main;
            font-size: 16px;
            color: #757575;
            text-transform: lowercase;
            font-weight: 700;
            &:hover {
                color: #2D9CDB;
            }
        }

        &--white {
            color: #fff;
            &:hover {
                color: darken(#fff, 15%);
            }
        }

        &--highlighted {
            padding: 0;
            color: #fff;
            fill: #fff;
            font-size: 22px;

            &:hover {
                color: darken(#fff, 15%)
            }
        }
    }

    &__link-icon {
        margin-right: 5px;
        float: left;
        height: 20px;
        fill: currentColor;
        width: 27px;
    }

    &__text {
        font-size: 24px;
        line-height: 1.2;
        color: #4f4f4f;
        clear: both;

        &--big {
            font-size: 36px;
            line-height: 40px;
        }

        &--white {
            color: #fff;
        }

        &--indented {
            padding: 10px 25px 15px 25px;
        }

        &--light {
            font-family: $font_search_new_2;
            font-size: 16px;
            font-weight: 300;
        }
    }

    &__select {
        font-family: $font_search_new_2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        font-size: 16px;
        line-height: 19px;
        height: 19px;
        color: #6A6969;
        background-color: #C4C4C4;
        cursor: pointer;
        box-shadow: 0 1px 5px rgba(#445C74, .4);
        font-weight: 300;
        position: relative;
        transition: all 300ms;
        text-align: center;
        margin-top: 4px;
        word-break: break-word;

        &--small-font {
            font-size: 12px;
            line-height: 12px;
        }

        &--fulfilled {
            background-color: #9FCD63;
            color: #fff;
        }

        &--md {
            width: 185px;
            height: 57px;
            //padding: 18px 0;
        }

        &--lg {
            width: 225px;
            height: 57px;
            //padding: 18px 0;
        }
    }

    &__btn {
        display: inline-block;
        padding: 7px 24px;
        font-size: 13px;
        line-height: 28px;
        height: 42px;
        color: #fff;
        text-transform: uppercase;
        background-color: #9FCD63;
        border: 1px solid transparent;
        cursor: pointer;
        box-shadow: 0 1px 5px rgba(#445C74, .4);
        font-weight: 700;
        position: relative;
        transition: all 300ms;

        &--lower {
            text-transform: none;
        }

        &--indented {
            margin: 0 23px;
        }

        &--rounded {
            border-radius: 100px;
            transform: translateX(5px);

            &:hover:not(:disabled) {
                color: #FFFFFF;
                background: #96e904;
                box-shadow: 0 0 10px #86D004;
                transition: all 200ms;
            }
        }

        &--enlarged {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            width: 195px;
            height: 75px;
            font-size: 22px;
            line-height: 25px;
        }

        &--big {
            width: 150px;
            height: 54px;
            font-size: 23px;
            line-height: 44px;
        }

        &--xmd {
            width: 115px;
            height: 42px;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            font-weight: 700;
            padding: 9px 14px;
        }

        &--md {
            width: 100px;
            height: 39px;
            font-size: 16px;
            line-height: 16px;
            padding: 10px 13px 8px;
        }

        &:focus {
            outline: 2px solid rgba(#9FCD63, .5);
            outline-offset: 5px;
        }

        &--empty {
            border-color: #2D9CDB;
            color: #2D9CDB;
            background-color: transparent;

            &:hover {
                color: #fff;
            }
        }

        &--wide {
            padding-left: 70px;
            padding-right: 70px;
        }

        &--semi-wide {
            padding-left: 40px;
            padding-right: 40px;
            font-size: 18px;
            margin-left: 5px;
        }

        &--dark {
            background: #4F4F4F;
        }

        &:hover {
            background-color: darken(#9FCD63, 10%);
        }

        &--blue {
            background-color: #53A0FD;
            &:hover {
                background-color: darken(#53A0FD, 10%);
            }
        }

        &--white {
            background-color: #FFFFFF;
            color: #C3272F;
            &:hover {
                background-color: darken(#FFFFFF, 15%);
            }
        }

        &--darkblue {
            margin: 0;
            background-color: #2D9CDB;
            &:hover {
                background-color: darken(#2D9CDB, 10%);
            }
        }

        &:disabled {
            cursor: default;
            background: #E0E0E0;
            color: #BDBDBD;
        }

        &--project {
            padding: 15px 30px;
            height: 48px;
            font-size: 15px;
            line-height: 17px;
            box-shadow: none;
        }
    }

    &__footer {
        position: relative;
        padding: 22px 40px;
        border-top: 1px solid #ffffff;
        border-radius: 0 0 22px 22px;

        &--gray {
            background-color: #6A6969;
            color: white;
            border: 0;
        }

        &--thin {
            padding: 15px 25px;
            display: flex;
            align-items: center;
        }

        &--share-package {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include clearfix;

        @include media-max(1200px) {
            padding: 22px 20px;
        }

    }

    &__footer-text {
        font-size: 12px;
        color: #000;
        line-height: 14px;
    }

    &__left-side {
        float: left;
        min-height: 42px;
        display: flex;
        width: 50%;
        align-items: center;

        &--wide {
            width: 65%;
        }

        &--column {
            display: block;
        }
    }

    &__right-side {
        float: right;
        width: 50%;
        text-align: right;

        &--flex {
            margin-left: auto;
            width: auto;
        }
    }

    &__dropzone {
        height: 100%;
        width: 100%;
        transition: all 300ms;
        position: relative;
    }
    &__bg-image {
        pointer-events: none;
    }

    &__buy-confirm {
        border-top: 1px solid #fff;
        padding: 15px 10px 30px 20px;
    }

    &__buy-input-block {
        margin-top: 15px;
    }

    &__buy-label {
        text-transform: uppercase;
        font-size: 13px;
        line-height: 14px;
        font-weight: 700;
        font-family: $font_main;
        color: #fff;
    }

    &__buy-input {
        background-color: rgba(255,255,255,0.7);
        padding: 12px 15px;
        color: #000;
        font-size: 28px;
        font-weight: 700;
        font-family: $font_main;
        line-height: 52px;
        height: 50px;
        width: calc(100% - 10px);
        border: 0;
        border-bottom: 1px solid #fff;
        text-transform: uppercase;
    }

    &__term-link {
        display: inline-block;
        margin-left: 5px;
        padding: 0;
        text-transform: uppercase;
        background-color: transparent;
        border: none;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__content-link {
        color: #2F80ED;
        display: inline-block;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
        &--share {
            position: relative;
            padding-bottom: 1px;
            border-bottom: 1px solid #fff;
            cursor: pointer;
            display: inline-block;
            width: auto;

            &:hover {
                &:after {
                    transform: rotate(90deg);
                }
            }

            &:after {
                display: block;
                content: '';
                position: absolute;
                left: calc(100% + 5px);
                top: 50%;
                transform: translateY(-50%);
                border-top: 6px solid white;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                transition: transform 300ms;
                transform-origin: center 0;
            }
        }
    }

    &__content-link-text{
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__attention {
        float: left;
        background: rgb(147, 217, 255);
        padding: 5px 10px;
        max-width: 65%;
        line-height: 16px;
    }

    &__attention-text {
        color: #C3272F;
        font-family: Muli, sans-serif;
        font-size: 16px;
        font-weight: 300;
    }

    &__attention-text-link {
        color: #C3272F;
        font-weight: 700;
        text-decoration: underline;
        cursor: pointer;
    }

    &__packages-warning-items {
        padding: 20px 40px;
        border-top: 1px solid #fff;
    }

    &__packages-warning-items-list {
        display: flex;
        flex-flow: column wrap;
        flex: 1 1 auto;
        max-height: 300px;
        font-family: Muli, sans-serif;
    }

    &__packages-report-name {
        font-size: 16px;
        color: #3F3F3F;
        vertical-align: middle;
    }

    &__packages-report-type {
        font-size: 12px;
        color: #828282;
        margin-left: 5px;
        vertical-align: middle;
    }

    &__error-msg {
        position: absolute;
        left: 25px;
        bottom: 15px;
        font-size: 10px;
        text-transform: uppercase;
        color: #ff0e0c;
        line-height: 20px;
        background-color: #fff;
        padding: 0 5px;

        &--proj-create {
            bottom: auto;
            left: auto;
            right: 21px;
            top: 140px;
        }

        &--proj-edit {
            bottom: auto;
            top: 59px;
            right: 20px;
            left: auto;
        }

        &--team-create {
            bottom: auto;
            top: 147px;
            right: 140px;
            left: auto;
        }

        &--doc-edit {
            bottom: auto;
            left: auto;
            top: 65px;
            right: 0;
        }

        &--proj-select {
            bottom: auto;
            left: auto;
            right: 45px;
            top: 120px;
        }
    }

    &__price {
        float: right;
        font-weight: bold;
        font-size: 32px;
        line-height: 30px;
        vertical-align: middle;
        color: #2D9CDB;
    }

    &__fade-in {
        animation: popup-fade-in 500ms;
    }
}

.popup-share {
    &--wide {

    }

    &__link {
        font-size: 18px;
        font-weight: 300;
        font-family: $font_search_new_2;
        border: 0;
        background-color: transparent;
        color: #fff;
        margin-right: 10px;
        text-transform: uppercase;
    }

    &__input-wrapper {
        padding: 15px 20px 0;
    }

    &__icon {
        &--no-pe {
            pointer-events: none;
            width: 100%;
        }
    }

    &__scrollbar-vertical {
        height: calc(100% - 36px);
    }

    .popup__all-list {
        float: right;
        line-height: 35px;
        text-decoration: none;
        color: #fff;
        font-family: $font_search_new_2;
        font-size: 16px;
        font-weight: 300;
    }

    .popup__link {
        font-size: 18px;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }

        &--absolute-positioned {
            position: absolute;
            bottom: 10px;
            left: 10px;
        }
    }

    .popup__link-icon {
        height: 22px;
        float: none;
        display: inline-block;
        vertical-align: bottom;
    }

    .popup__input-label {
        padding-top: 15px;
        line-height: 14px;
        font-family: $font_search_new_2;
        font-weight: 700;
        font-size: 12px;
    }

    .popup__input-wrap {
        display: inline-block;
        vertical-align: middle;
    }

    .popup__input {
        min-width: 150px;
        height: 22px;
        padding: 0;
    }

    .popup__input--fake {
        margin-top: 4px;
        width: 100%;
        position: relative;
        color: transparent;
        margin-bottom: 7px;
        padding-left: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;

        .popup__input {
            background-color: transparent;
            border-bottom: 1px solid #fff;
            padding-right: 120px;
            padding-bottom: 3px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            color: #fff;

            &::placeholder {
                color: white;
                font-size: 18px;
            }
        }

    }

    .popup__textarea {
        overflow: hidden;
        max-width: calc(100% - 10px);
        padding-bottom: 2px;
        background-color: transparent;
        font-family: $font_search_new_2;
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;
        border-bottom: 1px solid #ffffff;

        &::-webkit-input-placeholder {
            font-family: $font_search_new_2;
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
        }
        &::-moz-placeholder {
            font-family: $font_search_new_2;
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
        }
        &:-ms-input-placeholder {
            font-family: $font_search_new_2;
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
        }
        &:-moz-placeholder {
            font-family: $font_search_new_2;
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
        }
        &::placeholder {
            font-family: $font_search_new_2;
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            color: #ffffff;
        }
    }

    .popup {
        &__textarea-outer {
            &--share-popup {
                padding-top: 5px;
                padding-right: 10px;
            }
        }

        &__footer {
            padding: 20px;
        }

        &__input-outer {
            display: block;
            height: 53px;
            position: relative;
            margin-bottom: -5px;
            border: 0;
            padding: 0 20px;

            &--short {
                height: 39px;

                .popup__input--fake {
                    margin-top: 5px;
                }
            }

            .popup__suggest-item {
                display: inline-block;
                vertical-align: middle;
                height: 20px;
                min-height: inherit;
                line-height: 13px;
                padding: 1px 3px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-bottom: none;
                white-space: nowrap;
                border-radius: 2px;
                background-color: #C2E9FF;

                &--group {
                    background-color: #ffdcbd;
                }
            }

            .popup__suggest-name {
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                font-size: 13px;
                line-height: 15px;
                color: #413F3F;
            }

            .popup__remove {
                position: static;
                display: inline-block;
                vertical-align: middle;
                width: 10px;
                margin-top: 1px;

                svg {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .popup__error-email {
                position: static;
                float: right;
                display: inline-block;
                transform: none;
                font-size: 18px;
                line-height: 35px;
            }

            .scrollbar-vertical {
                position: absolute;
                width: 6px;
                right: 2px;
                bottom: 2px;
                top: 2px;
                border-radius: 3px;
                background-color: #EAEAEA;
            }
        }

        &__input-message {
            position: absolute;
            right: 20px;
            top: 35px;
            z-index: 2;
        }
    }
}

.menu-gaps-autosuggest {
    .popup {

        &__suggest-list {
            max-height: 315px;
        }

        &__selected-list {
            margin-bottom: 0;
        }

        &__suggest-item {
            min-height: inherit;
            padding: 10px 7px;

            &:hover {
                background-color: #E5F7FF;
            }
        }

        &__remove {
            top: 12px;
        }

        &__input-outer {
            padding-left: 0;
        }

        &__input {
            padding-left: 0;
        }
    }
}

.purchase-popup {
    .popup {
        &__text {
            font-weight: 300;
            color: #fff;
            font-size: 18px;
            font-family: $font_main;
        }
        &__main {
            padding: 20px;
        }
        &__footer {
            padding: 20px;
        }
        &__title {
            width: calc(100% - 80px);
        }
        &__price {
            width: 120px;
        }
    }
}

.terms-popup {
    .popup {
        &__text {
            padding-right: 10px;
            font-size: 14px;

            p {
                margin-top: 14px;
            }
        }
        &__main {
            padding: 20px 10px 20px 20px;
        }
    }
}

.groups-popup, .HelpPopup {

    .popup {

        &__project-input {
            color: #E5E3E3;
        }

        &__footer-wrapper {
            padding: 20px;
            width: 100%;
            border-top: 1px solid #fff;
            @include clearfix;
        }

        &__input-wrapper {
            padding: 0 20px;
            margin-bottom: 15px;
            @include clearfix;

            &:last-of-type {
                margin-bottom: 0;
            }
            &:first-child {
                padding-top: 15px;
                margin-bottom: 0;
            }
            & > .popup__input-wrapper {
                margin-top: -10px;
            }
        }

        &__all-list {
            color: white;
            float: right;
            text-decoration: none;
            font-size: 14px;
        }

        &__input {
            padding: 0;
            width: 100%;
            height: 22px;

            &::placeholder {
                font-size: 16px;
                font-weight: 300;
            }
        }

        &__input--fake {
            margin-top: 5px;
            position: relative;
            color: transparent;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .popup__input {
                background-color: transparent;
                padding-bottom: 4px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                color: #fff;
                border-bottom: 1px solid #fff;
            }

        }

        &__input-outer {
            float: left;
            display: block;
            width: calc(100% - 110px);
            height: 72px;
            position: relative;
            margin-bottom: -5px;
            padding-bottom: 0;
            border-bottom: 0;
            margin-top: 10px;

            &--no-indent {
                width: 100%;
                padding-left: 0;
            }

            .popup__suggest-item {
                display: inline-block;
                vertical-align: middle;
                min-height: inherit;
                padding: 3px 5px;
                margin-right: 7px;
                margin-bottom: 7px;
                border-bottom: none;
                white-space: nowrap;
                border-radius: 3px;
                background-color: #C2E9FF;

                &--group {
                    background-color: #ffdcbd;
                }
            }

            .popup__suggest-name {
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                font-size: 18px;
                line-height: 18px;
                color: #413F3F;
            }

            .popup__remove {
                position: static;
                display: inline-block;
                vertical-align: middle;
                width: 10px;

                svg {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .popup__error-email {
                position: static;
                float: right;
                display: inline-block;
                transform: none;
                font-size: 18px;
                line-height: 35px;
            }

            .popup__error-email {
                position: static;
                transform: none;
            }

            .scrollbar-vertical {
                position: absolute;
                width: 6px;
                right: 2px;
                bottom: 2px;
                top: 2px;
                border-radius: 3px;
                //background-color: #e1e1e1;
            }
        }

        &__input-message {
            position: absolute;
            right: 0;
            top: -75px;
            z-index: 2;
        }
    }
}

.popup-market {
    display: flex;
    flex-direction: column;
    padding: 30px 45px;
    height: 400px;
    background-color: #f2f2f2;
    border-radius: 9px;

    &__wrapper {
        width: 100%;
        height: 100%;
    }

    &__input {
        width: 100%;
        height: 20px;
        border: 0;
        background-color: transparent;
        border-bottom: 1px solid #000;
        padding-bottom: 3px;
        margin-bottom: 10px;
        font-family: $font_search_new_2;
        font-size: 16px;
        font-weight: 300;

        &::placeholder {
            color: #aeaeae;
        }
    }

    &__suggest-list {
        display: flex;
        flex-wrap: wrap;
        background-color: #f5f5f5;
    }

    &__suggest-item {
        width: 25%;
        padding-left: 12px;
        border: solid #e5e5e5;
        border-width: 1px 0 0 1px;
        height: 34px;
        line-height: 34px;
        font-family: $font_main;
        font-size: 12px;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background-color: darken(#f5f5f5, 5%);
        }

        &:nth-child(4n+1) {
            &:nth-last-child(7) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(6) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(5) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(3) {
                border-top-width: 0;
            }
            &:nth-last-child(2) {
                border-width: 0 0 0 1px;
            }
            &:last-child {
                border-width: 0 1px;
                width: calc(25% + 1px);
            }
        }

        &:nth-child(4n+2) {
            &:nth-last-child(6) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(5) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(4) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(2) {
                border-top-width: 0;
            }
            &:last-child {
                border-width: 0 1px;
                width: calc(25% + 1px);
            }
        }

        &:nth-child(4n+3) {
            &:nth-last-child(5) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(4) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(3) {
                border-bottom-width: 1px;
            }
            &:last-child {
                width: calc(25% + 1px);
                border-right-width: 1px;
                border-top-width: 0;
            }
        }

        &:nth-child(4n) {
            &:nth-last-child(4) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(3) {
                border-bottom-width: 1px;
            }
            &:nth-last-child(2) {
                border-bottom-width: 1px;
            }
        }
    }

    &__scrollbar-wrapper {
        height: calc(100% - 33px);
        border: solid #e5e5e5;
        border-width: 0 1px 1px 0;
    }
}

.popup-project-create, .popup-project-edit, .popup-upload-files {
    .popup {

        &__options--bottom {
            position: absolute;
            top: auto;
            bottom: 15px;
            right: 20px;
        }

        &__input-container {
            height: calc(100% - 82px);
            &--fixed {
                height: 280px;
            }
        }

        &__input-label {
            margin-right: 11px;
        }

        &__project-input {
            display: inline;
            line-height: 30px;
            font-size: 26px;
            width: calc(100% - 120px);
        }

        &__main {
            height: 100%;
        }

        &__flex-container--aligned {
            height: calc(100% - 82px);
        }

        &__left-side {
            padding-left: 32px;
        }

        &__text {
            margin-bottom: 25px;
            color: #fff;
            font-family: $font_search_new_2;
            font-weight: 300;
            font-size: 18px;

            &--indented {
                margin-bottom: 60px;
            }
        }

        &__link {
            font-size: 18px;
            font-weight: 300;
            font-family: $font_search_new_2;
        }

        &__btn {
            font-weight: 400;
            font-size: 16px;
        }
        &__description-label {
            margin: 10px 0 0 11px;
        }
    }
}

.popup-confirm {
    .popup {
        &__footer {
            padding: 15px 25px 20px;
            border-top: 1px solid #fff;
            @include clearfix;
        }
        &__link {
            color: #fff;
            &:hover {
                color: darken(#fff, 15%);
            }
        }
        &__text {
            font-family: $font_search_new_2;
            font-size: 16px;
            line-height: 19px;
            color: #fff;
            padding: 30px 25px 25px;
            font-weight: 300;
        }
    }
}

.popup-report {
    border-radius: 22px;
    $root: &;

    .report-item__btn {
        &--download {
            //transform: translate(10px, 7px);
            height: 35px;
            line-height: 37px;
            //margin-top: 4px;
            //margin-bottom: 4px;

            svg {
                height: 35px;
            }
        }
    }

    &__btn-icon-svg {
        width: 20px;
        height: 19px;
        overflow: visible; // fix for Mozilla Firefox

        &--big {
            width: 110px;
            height: 22px;
        }
    }

    &__scrollbar-view {
        display: flex;
        align-items: center;
    }

    &__scroll-wrap {
        width: 650px;
        max-height: calc(100vh - 50px);
        position: relative;

        @include media-max(1280px) {
            width: 600px;

            &--shifted {
                transform: translate(-135px);
            }
        }
    }

    &__btn-wrap-inner {
        display: flex;
        align-items: center;
    }

    &__inner {
        font-family: $font_main;
    }
    &__header-img {
        overflow: hidden;
        cursor: pointer;
        border-radius: 22px 22px 0 0;

        position: relative;
        width: 100%;
        height: 0;
        //padding-top: round((2/3)*100%) + 1;
        padding-top: round(math.div(9, 16)*100%) + 1;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
    }
    &__img {
        position: absolute;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        min-height: 100%;
        min-width: 100%;
        top: 0;
        left: 50%;
        transition: all 700ms;
        max-height: none;
        max-width: none;
        transform: translate(-50%, 0);
    }
    &__action-footer {
        padding: 0 12px 5px 20px;
        height: 60px;
        border-radius: 0 0 22px 22px;
        background-color: rgba(0,0,0,0.03);
        //background-color: rgb(240,240,240);
        position: relative;
        @include clearfix;

        @media screen and (max-width: 1440px) {
            padding: 10px 15px 10px 25px;
        }
    }
    &__info {
        font-size: 14px;
        line-height: 1.2;
        display: inline-block;
        color: #514F4F;
        margin-left: 10px;
    }
    &__button-wrapper {
        padding: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 45px;
        text-decoration: none;
        color: #4F4F4F;
        fill: #4F4F4F;
        background-color: transparent;
        border: 0;

        &:hover {
            color: #2F80ED;
            fill: #2F80ED;
        }

        &--green {
            color: #fff;
            fill: #fff;
            background-color: #86D004;
            padding: 10px 15px;
            margin-left: 15px;
            &:hover {
                background-color: darken(#86D004, 10%);
                color: #fff;
                fill: #fff;
            }
        }

        &--absolute {
            position: absolute;
            right: 3px;
            top: 8px;
            height: auto;
            transition: all 300ms;

            & button {
                font-size: 10px;
                width: auto;
            }
        }
    }

    &__right-side {
        display: flex;
        justify-content: flex-end;
        @include clearfix;
    }

    &__footer-btn {
        text-transform: uppercase;
        border: 0;
        background: transparent;
        color: inherit;
        font-weight: 500;
        cursor: pointer;
        height: 100%;
        transition: all 200ms;
        &--sm {
            font-size: 11px;
            padding: 0 15px 0 10px;
        }
        &--md {
            width: 100px;
            font-size: 14px;
        }
        &--lg {
            font-size: 18px;
        }
        &--gray {
            color: #828282;
            &:hover {
                color: #2F80ED;
                text-decoration: underline;
            }
        }
    }

    &__childs-list {
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 0;
        background: #2D3037;
        border-radius: 22px;
        padding: 15px 5px 15px 70px;
        margin-left: -50px;
        width: 360px;
    }

    &__child-item {
        color: #fff;
        font-family: $font_main;
        font-size: 14px;
        line-height: 32px;
        height: 30px;
        font-weight: 700;
        border-bottom: 1px solid #c4c4c4;
        margin-right: 15px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: nowrap;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: color 100ms;
        display: block;
        text-decoration: none;

        &:hover {
            color: #2DC0E6;
        }

        &:last-child {
            border-bottom: 0;
        }
    }

    &__thumb-vertical {
        position: relative;
        display: block;
        width: 100%;
        cursor: pointer;
        border-radius: inherit;
        background-color: rgba(255, 255, 255, 0.2);
    }
}

.popup-report-text {
    //height: calc(100% - 330px);
    position: relative;
    z-index: 5;
    background: #fff;
    border-radius: 0 0 22px 22px;

    &__header {
        padding: 20px 30px 0 30px;
        position: relative;
    }
    &__title {
        display: block;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-family: $font_search_new_2;
        font-size: 48px;
        line-height: 1;
        font-weight: 700;
        word-break: break-word;
        text-transform: uppercase;

        &--small {
            font-size: 28px;
            line-height: 28px;
        }
    }
    &__link {
        font-size: 16px;
        line-height: 1.2;
        color: #2F80ED;
        cursor: pointer;
        margin-bottom: 5px;
        //border-bottom: 1px solid #2F80ED;
        display: inline-block;
        text-decoration: none;
        &:hover {
            color: orange;
            //border-bottom: 1px solid orange;
        }
    }
    &__main {
        padding: 5px 20px 6px 30px;
        font-size: 16px;
        line-height: 1.2;
        color: #7E7E7E;
        max-height: calc(100vh - 600px);
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;

        p {
            line-height: 1.2em !important;

            &:last-child {
                margin-bottom: 0 !important;
                padding-bottom: 0 !important;
            }
        }

        p, span, li {
            font-family: Comfortaa, sans-serif !important;
            font-size: 14px !important;
            color: #7E7E7E !important;
        }

        //&:after {
        //    content: "";
        //    display: block;
        //    position: absolute;
        //    left: 0;
        //    right: 0;
        //    bottom: 0;
        //    height: 45px;
        //    background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,1) 5px, rgba(255,255,255,0));
        //}
    }

    &__main-inner {
        padding-right: 10px;
    }
}

.popup-note {
    .popup {
        &__textarea {
            background-image: linear-gradient(transparent 0, transparent 25px, #BFBFBF 0px);
            background-attachment: local;
            background-size: 100% 26px;
            font-family: $font_search_new_2;
            font-size: 18px;
            line-height: 26px;
            color: rgba(0,0,0,0.5);
            width: calc(100% - 15px);
        }

        &__textarea-scrollbar {
            height: 150px;
        }

        &__btn {
            font-size: 16px;
            line-height: 18px;
            color: #fff;
            font-weight: 700;
            font-family: $font_main;
            height: 47px;

            &:disabled {
                background-color: #A3A3A3;
            }
        }
    }

}

.popup-report {
    &__left-side, &__right-side {
        &--auto-width {
            width: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        margin: 0;
    }

    &__right-side {
        &--auto-width {
            right: 12px;
        }
    }

    .report-item__btn {
        &--sample {
            transform: translateY(1px);

            .report-item{
                &__btn-icon {
                    vertical-align: middle;
                    transform: none;
                }
            }
        }
    }
}

.scrollbar-view {
    &--hidden {
        overflow-x: hidden!important;
    }

    &--overflow-hidden {
        overflow: hidden !important;
    }
}
