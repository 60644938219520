.ImpersonatePopup {
    border-radius: 9px;
    font-size: 16px;
    line-height: 23px;
    background: #f2f2f2;
    color: #4f4f4f;
    width: 450px;
    box-shadow: 0 0 25px 0 rgba(#000, 0.25);

    &__header {
        font-size: 14px;
        padding: 20px 25px 10px;
    }

    &__body {
        padding: 0 25px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding: 20px 25px;
        border-top: 1px solid #e3e3e3;
    }
}
