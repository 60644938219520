.BrandsAttributes {
    @import "../../../ChainPage.helpers.styles";
    $root: &;

    width: 100%;
    height: 100%;

    &__content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    &__charts {
        width: 60%;
        padding-right: 48px;

        @media screen and (max-width: 1440px) {
            width: 55%;
        }

        @media screen and (max-width: 1200px) {
            width: 100%;
            padding-right: 0;
        }
    }

    &__info-block {
        padding: 32px;
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 1;
    }

    &__additional {
        display: flex;
        width: 40%;

        @media screen and (max-width: 1440px)  {
            width: 45%;
        }

        @media screen and (max-width: 1200px) {
            width: 100%;
            border-top: 1px solid #262E39;
            margin-top: 24px;
            padding-top: 24px;
        }
    }

    &__columns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        width: 100%;
    }

    &__circular {
        position: relative;
        background: rgba(black, .25);
        border-radius: 50%;
        width: 220px;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    &__description {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
    }

    &__circular-total {
        font-family: 'Montserrat', sans-serif;
        font-weight: 900;
        font-size: 33px;
        line-height: 1;
    }

    &__circular-label {
        font-size: 14px;
        font-weight: 700;
    }

    &__circular-value {
        font-weight: 400;
        font-size: 12px;
    }

    &__additional-list {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        flex: 1;
        align-items: center;
        padding-right: calc(50% + 6px);
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 60px;

        @media screen and (max-width: 1200px) {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: 1px;
            height: calc(100% - 60px);
            background: white;
            opacity: 0.1;
            transform: translateY(-50%);

            @media screen and (max-width: 1440px)  {
                display: none;
            }
        }
    }

    &__additional-title {
        font-size: 14px;
        line-height: 1;
        margin-left: 6px;
        width: 55%;
        text-align: left;
    }

    &__additional-value {
        font-weight: 500;
        font-size: 21px;
        width: 45%;
        text-align: right;
    }

    &__additional-list-item {
        background: #6D7784;
        color: #fff;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 8px;
        font-weight: 500;
        line-height: 1;
        flex-direction: row;
        font-size: 14px;
        flex: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 6px;
        padding-bottom: 6px;
        flex: 1;

        &:first-child {
            background: #809BAA;
            margin-left: 6px;
            font-family: Montserrat, Arial, sans-serif;
            font-weight: 900;
            min-width: calc(50% - 12px);
            position: absolute;
            right: 0;
            top: 50%;
            font-size: 40px;
            height: calc(100% - 52px);
            transform: translateY(-50%);
            flex-direction: column;

            @media screen and (max-width: 1200px) {
                height: calc(100% - 10px);
            }

            & > #{$root}__additional-value,
            & > #{$root}__additional-title {
                font-weight: 900;
                font-size: 42px;
                width: auto;
            }

            & > #{$root}__additional-title {
                margin-left: 16px;
            }
        }

        & + & {
            margin-right: 12px;
            margin-top: 6px;
            margin-bottom: 6px;
            width: 100%;
        }
    }

    &__additional-header {
        padding-left: 60px;

        @media screen and (max-width: 1200px) {
            padding-left: 0;
        }
    }

    &__info {
        position: relative;
        height: 20px;
        display: flex;
        align-items: center;
        z-index: 2;
    }

    &__info-dropdown {
        font-size: 12px;
        width: 400px;
        white-space: normal;
        text-align: left;
        text-transform: none;
        padding: 8px;

        p {
            margin-bottom: 8px;
        }
    }

    &__info-button {
        background: none;
        min-width: 0;

        &:hover {
            opacity: 1;
        }

        .Tooltip {
            left: 0;
            transform: none;

            @media screen and (max-width: 1500px) and (min-width: 1200px) {
                left: auto;
                right: 0;
            }

            &:after {
                left: 8px;

                @media screen and (max-width: 1500px) and (min-width: 1200px) {
                    left: auto;
                    right: 8px;
                }
            }
        }
    }
}
