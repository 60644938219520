.ActionButtons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &__button {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}