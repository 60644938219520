
.Loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#000, 0.7);
    backdrop-filter: blur(5px);
    z-index: 1000;

    &.is-no-bg {
        background: none;
    }

    &--simple {
        height: 8px;
        background: none;
        backdrop-filter: none;
        background: inherit;
        position: relative;
    }

    &__dot {
        background: rgba(white, .5);
        display: block;
        height: 8px;
        border-radius: 50%;
        float: left;
        margin: 0 2px;
        opacity: 0;
        width: 8px;
        animation: dots 1s ease-in-out 0s infinite;

        @for $i from 1 to 4 {
            &:nth-child(#{$i}) {
                animation-delay: ($i * .15s);
            }
        }
    }
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    div {
        animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        transform-origin: 40px 40px;

        &:after {
            content: " ";
            display: block;
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background: #fff;
            margin: -4px 0 0 -4px;
        }
        &:nth-child(1) {
            animation-delay: -0.036s;
        }
        &:nth-child(1):after {
            top: 63px;
            left: 63px;
        }
        &:nth-child(2) {
            animation-delay: -0.072s;
        }
        &:nth-child(2):after {
            top: 68px;
            left: 56px;
        }
        &:nth-child(3) {
            animation-delay: -0.108s;
        }
        &:nth-child(3):after {
            top: 71px;
            left: 48px;
        }
        &:nth-child(4) {
            animation-delay: -0.144s;
        }
        &:nth-child(4):after {
            top: 72px;
            left: 40px;
        }
        &:nth-child(5) {
            animation-delay: -0.18s;
        }
        &:nth-child(5):after {
            top: 71px;
            left: 32px;
        }
        &:nth-child(6) {
            animation-delay: -0.216s;
        }
        &:nth-child(6):after {
            top: 68px;
            left: 24px;
        }
        &:nth-child(7) {
            animation-delay: -0.252s;
        }
        &:nth-child(7):after {
            top: 63px;
            left: 17px;
        }
        &:nth-child(8) {
            animation-delay: -0.288s;
        }
        &:nth-child(8):after {
            top: 56px;
            left: 12px;
        }
    }
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@keyframes dots {
    0% { opacity: 0; }
    25% { opacity: 1; }
    100% { opacity: 0; }
}