.TabThumb {
    @import "../../../pages/ChainPage/ChainPage.helpers.styles";
    padding: 4px 16px;
    border-bottom: 2px solid transparent;
    color: #fff;
    opacity: 0.5;
    font-size: 16px;
    cursor: pointer;
    user-select: none;
    transition: color 300ms ease-in;
    * {
        text-decoration: none;
    }

    &:hover {
        opacity: 1;
    }

    &.is-active {
        opacity: 1;
        border-color: color("info");
        pointer-events: none;
    }
}