.error-page{
    height: 100vh;
    background-image: url('../../img/error-bg.jpg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;

    &__inner{
        height: 100%;
        background-color: transparentize(#FFF, .05);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__snap-logo {
        width: 95px;
        height: 95px;
        background-image: url('../../img/span-logo.png');
        margin: 0 auto 40px;
    }

    &__content{
        text-align: center;
    }

    &__logo{
        fill: #323232;
    }

    &__title{
        font-family: Muli, sans-serif;
        font-weight: 400;
        font-size: 64px;
        line-height: 1;
        margin-bottom: 40px;
    }

    &__text{
        max-width: 460px;
        margin-bottom: 40px;
        font-weight: 300;
        font-size: 22px;
    }

    &__home-link{
        padding: 15px 24px;
        color: #fff;
        background-color: #f35e42;
        text-decoration: none;
        transition: background-color .1s;

        &:hover{
            background-color: darken(#F35E42, 15%);
        }
    }
}
