.WhoOffers {
    height: 60vh;
    width: 100%;
    position: relative;

    &__menu-items {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.WhoOffersMenuItem {
    padding: 12px 0;
    border-bottom: 1px solid #323840;
    font-size: 14px;
    line-height: 16px;
    font-family: 'DM Sans', sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;

    .table__data-highlight {
        background: #317b55;
        box-shadow: -2px 0 0 0 rgb(49, 123, 85), 2px 0 0 0 rgb(49, 123, 85);
    }

    &__title {
        text-transform: uppercase;
    }

    &__description {
        margin-top: 8px;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.WhoOffersTableRow {
    display: flex;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}