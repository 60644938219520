@use "sass:math";

$step: .1s;
//@for $i from 0 through 100 {
//    .animate--slide-in-up:nth-child(#{$i}){
//        animation: slideInUp .5s ease $step*$i - .1  both;
//    }
//    .animate--slide-in-left:nth-child(#{$i}){
//        animation: slideInLeft .5s ease $step*$i - .1  both;
//    }
//}

@keyframes slideInUp {
    0% {
        transform: translateY(40px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@mixin IE10plus (){
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin Edge12plus() {
    @supports (-ms-ime-align:auto) {
        @content;
    }
}

@mixin Safari10plus() {
    @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance:none) {
            @content;
        }
    }
}

@function size($font-size) {
    @return math.div($font-size, 1920px) * 100vw;
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, $number * 0 + 1);
    }
    @return $number;
}

@function em($size, $base-font-size: 16px) {
    @return math.div(strip-unit($size), strip-unit($base-font-size)) * 1em;
}