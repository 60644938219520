.InputRadio {
    position: relative;
    user-select: none;

    &__input {
        visibility: hidden;
        position: absolute;
        left: -9999px;
        opacity: 0;
    }

    &__field {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__icon {
        $color: #56CCF2;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $color;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 8px;
            height: 8px;
            margin-left: -4px;
            margin-top: -4px;
            background: $color;
            border-radius: 50%;
            opacity: 0;
        }
    }

    &__label {
        color: white;
        font-size: 13px;
        font-weight: 400;
        padding-left: 4px;
    }

    &--inherit-label & {
        &__label {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
        }
    }

    &--active & {
        &__icon {
            &:after {
                opacity: 1;
            }
        }
    }
}