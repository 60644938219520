.InputField {
    @import "../../../pages/ChainPage/ChainPage.helpers.styles";
    $root: &;

    position: relative;

    @mixin isUpperLabel {
        transform: translate(0, 1.5px) scale(0.75);
    }

    &__input {
        width: 100%;
        background-color: transparent;
        padding: 32px 10px 10px 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #4A6282;
        color: #fff;
        font-size: 14px;
        line-height: 20px;
        outline: 0;
        transition: border-color 200ms cubic-bezier(0.0, 0, 0.2, 1);

        &:focus {
            &+ #{$root}__label {
                @include isUpperLabel;
            }
        }
    }

    &__label {
        position: absolute;
        top: 10px;
        left: 0;
        font-size: 14px;
        color: #5E6C81;
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        transform: translate(0, 24px) scale(1);
        transform-origin: top left;
        pointer-events: none;

        &.is-upper {
            @include isUpperLabel;
        }

        &.is-required {
            &:after {
                content: '*';
                padding-left: 2px;
                color: color("red");
            }
        }
    }

    &--has-error & {
        &__input {
            border-color: color("red");
        }

        &__label {
            color: color("red");
        }
    }
}