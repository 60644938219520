@import "../../../pages/ChainPage/ChainPage.helpers.styles";

.Notes {
    &__form {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 200;
    }
}

@mixin Notes {
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 440px;
    background: color("section-bg");
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 8px;

    &__header {
        height: 60px;
        background: #56ccf2;
        color: #fff;

        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &__loader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(black, 0.2);
        z-index: 100;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 300;
    }

    &__no-data {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        opacity: 0.25;
        user-select: none;
        cursor: default;
    }

    &__form {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #131921;
        display: flex;
        flex-direction: column;
    }

    &__body {
        flex: 1;
        overflow: auto;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    &__footer {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 16px;
        border-top: 1px solid rgba(white, 0.2);
    }
}
