.Tabs {
    @import "../../../pages/ChainPage/ChainPage.helpers.styles";

    position: relative;
    z-index: 1;
    width: 100%;

    &__header {
        display: flex;
        margin-bottom: 24px;
    }
}

