.ChainMap {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100px;

    &__container-map {
        & > div {
            background: lighten(#000, 20) !important;
        }
    }

    &__loader {
        background: rgba(black, .5);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__loader-anim {
        &-enter,
        &-appear {
            opacity: 0;
            transition: all ease-in 500ms;
        }

        &-enter-active,
        &-appear-active {
            opacity: 1;
            transition: all ease-in 500ms;
        }

        &-exit-active {
            opacity: 0;
            transition: all ease-out 500ms;
        }
    }
}
