.ContactCard {
    $root: &;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 275px;
    padding-top: 16px;
    padding-bottom: 50px;
    background: #131921;
    box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    color: #FFFFFF;
    cursor: default;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: #303D4E;

        #{$root}__edit {
            opacity: 1;
        }
    }

    &--preview {
        cursor: default;

        &:hover {
            background: #131921;
        }
    }

    &__custom-card {
        background-color: #252F3D;
    }

    &__work-since {
        position: absolute;
        left: 8px;
        top: 8px;
        padding: 4px;
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        background-color: #6D7784;
        border-radius: 4px;
    }

    &__edit {
        right: 10px;
    }

    &__edit {
        position: absolute;
        top: 10px;
        opacity: 0;
        cursor: pointer;
    }

    &__photo {
        display: block;
        width: 110px;
        height: 110px;
        min-height: 110px;
        min-width: 110px;
        margin-bottom: 4px;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -55px;
        margin-top: -55px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        background-color: #fff;
    }

    &__no-photo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 110px;
        margin-bottom: 4px;
        border-radius: 50%;
        font-size: 40px;
        line-height: 110px;
        background-color: #374354;
    }

    &__name {
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
        max-height: 42px;
        overflow: hidden;
        text-transform: uppercase;
    }

    &__department,
    &__position {
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
    }

    &__department {
        color: #5E6C81;
    }

    &__position {
        padding: 0 12px 8px;
        text-align: center;
        max-height: 18px * 3 + 4px;
        overflow: hidden;
    }

    &__contacts {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;

        &:after {
            content: '';
            position: absolute;
            left: 10%;
            top: 0;
            background: #374354;
            height: 1px;
            width: 80%;
        }
    }

    &__contacts-item {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #131921;
        border-radius: 50%;
        margin-left: 4px;
        margin-right: 4px;
    }
}