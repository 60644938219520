.Button {
    $color-amout: 15%;

    @mixin base-button-styles($color) {
        background: $color;
        padding: 8px 24px;
        border-radius: 24px;
    }

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 100px;
    font-size: 13px;
    cursor: pointer;
    border: none;
    color: #ffffff;
    text-transform: uppercase;
    transition: background-color 300ms, opacity 300ms;
    white-space: nowrap;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;

    &:hover {
        opacity: 0.8;

        .NewTooltip {
            display: block;
        }
    }

    &--wrapper {
        min-width: 0;
        background: transparent;
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &--active {
        border-bottom-color: #56ccf2;
        color: #ffffff;
    }

    &--icon {
        background: none;
        min-width: 0;
        padding: 0;
    }

    &--primary {
        @include base-button-styles(#2eafd9);
    }

    &--accent {
        @include base-button-styles(#86D004);
    }

    &--accent-gradient {
        @include base-button-styles(#86D004);
        background: linear-gradient(225deg, #86D004 0%, #2D9CDB 100%);
    }

    &--danger {
        @include base-button-styles(#f44336);
    }

    &--secondary {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        background-color: transparent;
    }

    &--tabs-link {
        font-family: "DM Sans", sans-serif;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        font-size: 16px;
        line-height: 1;
        padding-bottom: 5px;
        text-decoration: none;
        background: none;
        transition: all 300ms ease-in;
        color: #676d75;
        text-transform: none;

        &.is-active,
        &:hover {
            color: #ffffff;
        }

        &.is-active {
            border-bottom-color: #56ccf2;
        }
    }

    &__loader {
        background: inherit;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
}
