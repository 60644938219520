.Slider {
    @import "../../styles/helpers/ie.scss";
    $root: &;
    $thumbnails-height: 11%;
    $border-active: #2d9cdb;
    $gap: 8px;

    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__total-counter {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__slider {
        flex: 1;
        height: 100%;

        @include onlyIE {
            flex-basis: auto;
        }

        &--has-extra-space {
            padding-left: 60px;
            padding-right: 60px;
        }

        &.is-centered {
            #{$root}__slide {
                justify-content: center;
                align-items: center;

                & > img {
                    width: auto;
                    height: auto;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &__custom-column {
        display: flex;
        width: 100%;

        & > div {
            flex: 1;
            max-width: calc((100% / var(--columns)) - (16px / var(--columns) ) * calc(var(--columns) - 1));

            & + div {
                margin-left: 16px;
            }
        }
    }

    &__thumbnails {
        height: $thumbnails-height;
    }

    &__slide,
    &__thumb-slide {
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;

        & > img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    &__arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(black, 0.45);
        z-index: 2;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 300ms ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &--next {
            right: $gap;

            #{$root}__arrow-icon {
                transform: rotate(180deg);
            }
        }
        &--prev {
            left: $gap;
        }

        &:hover {
            background-color: rgba(black, 0.85);
        }

        &.slick-disabled {
            opacity: 0.25;
            cursor: default;

            &:hover {
                background: rgba(black, 0.45);
            }
        }
    }

    &--has-thumbnails & {
        &__slider {
            height: calc(100% - #{$thumbnails-height} - #{$gap});
        }
    }

    .slick {
        &-track,
        &-list {
            height: 100%;
        }

        &-list {
            margin-left: -($gap * 0.5);
            margin-right: -($gap * 0.5);
        }

        &-slide {
            padding: 0 $gap * 0.5;

            & > div {
                height: 100%;
                width: 100%;
                cursor: pointer;
            }
        }

        &-slider {
            &.is-outside-arrow {
                .slick-next {
                    right: -40px;
                }

                .slick-prev {
                    left: -40px;
                }
            }
            &.is-multi-row {
                .slick-slide {
                    padding: ($gap * 2) ($gap * 0.5);

                    & > div {
                        width: auto;
                        height: auto;
                        padding: ($gap * 0.5) 0;
                    }

                    & > div + div {
                        margin-top: $gap;
                    }
                }
            }
        }
    }

    &__thumbnails {
        margin-top: $gap;
        overflow: hidden;

        .slick {
            &-slide {
                user-select: none;
                & > div {
                    transition: opacity 300ms ease;

                    &:hover {
                        opacity: 0.8;
                    }
                }
                &.slick-current {
                    & > div {
                        position: relative;
                        cursor: default;

                        &:hover {
                            opacity: 1;
                        }

                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            border: 4px solid $border-active;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}


.slick-slider {
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide img {
    display: block;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}