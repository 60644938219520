.ChartLine {
    @import "@styles";
    $width: 100%;

    position: relative;
    height: 100%;
    width: $width;
    user-select: none;

    &__line {
        border-radius: 5px 5px 0 0;
        height: 100%;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(180deg, #EB5757 25%, #FB8C00 100%);
            background-size: 100% 300px;
            background-position: center bottom;
        }
    }

    &__quarter,
    &__value {
        position: absolute;
        left: 0;
    }

    &__value {
        top: -24px;
        width: 100%;
        height: 20px;
        font-size: 14px;
        background: rgba(white, .1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__quarter {
        font-size: 12px;
        line-height: 1;
        bottom: -20px;
        color: $chains-primary;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
    }
}