.page-container{
    outline: none;
    transition: filter 400ms, transform 400ms;
    transition-timing-function: linear;
    backface-visibility: hidden;

    &--blurred {
        filter: blur(10px);
        transition: transform 500ms, filter 500ms;
        transition-timing-function: linear;
    }
}
