.DownloadButton {
    width: 106px;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
    transition: all 0.5s ease;

        &:hover {
            .DownloadLinkList {
                transform: translateY(0);
                display: flex;
                position: absolute;
                left: 0;
                top: 100%;
            }
        }

    &__link-list {
        display: none;
        transform: translateY(-10px);
    }

    &__reports {
        width: 750px;
    }
}
