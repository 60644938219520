.spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    //transform: translate(-50%, -50%);
    margin-top: -20px;
    margin-left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1.0s;
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}

@keyframes spinner-timer {

    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-timer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid;
    position: relative;
    box-sizing: border-box;
    color: cyan;

    &:before,
    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 0;
        left: 50%;
        top: 50%;
        border: 2px solid;
        margin-top: -2px;
        margin-left: -2px;
        box-sizing: border-box;
        border-radius: 2px;
        transform-origin: 2px 2px;
    }
    &:before {
        width: 50%;
        animation: spinner-timer 1s linear 0s infinite;
    }
    &:after {
        width: 40%;
        animation: spinner-timer 10s linear 0s infinite;
    }
}

.spinner-boiling {
    display: block;
    width: 64px;
    height: 64px;
    margin-top: -32px;
    margin-left: -32px;
    position: relative;
    background-image: url('../../img/loading-64x64.gif');
}

.spinner-blink {
    width: 200px;
    height: 200px;
    margin-top: -100px;
    margin-left: -100px;
    animation: blink 2s ease-in-out infinite;
    .icon {
        width: 100%;
        height: 100%;
    }
}

.spinner-pow {
    margin: 0 auto;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin: 0;
    }
    width: 200px;
    height: 200px;
    animation: pow 2s ease-in-out infinite;

    &--top {
        position: absolute;
        top: 100px;
        left: 50%;
        margin-left: -50px;
    }
}

.spinner-tiny-pow {
    margin: 0 auto;
    width: 20px;
    height: 20px;
    animation: pow 2s ease-in-out infinite;
}

.spinner-line {
    width: 100%;
    height: 5px;
    background-color: #2DC0E6;
    animation: fill 8s cubic-bezier(.96,.08,.29,.66) infinite;
}

@keyframes blink {
    0% {
        opacity: .7;
        transform: scale(1);
    }

    25% {
        opacity: 0.5;
        transform: scale(1.1);
    }

    100% {
        opacity: .7;
        transform: scale(1);
    }
}

@keyframes pow {
    0% {
        opacity: .7;
        transform: scale(1);
    }

    25% {
        opacity: 0.5;
        transform: scale(1.1);
    }

    100% {
        opacity: .7;
        transform: scale(1);
    }
}

@keyframes fill {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

.blink-animation {
    animation: blinker linear 1s 3;
}

@keyframes blinker {
    50% { opacity: 0; }
}
