.ChainsDetailsHeader {
    @import "../ChainPage.helpers.styles";
    @import "../../../styles/helpers/ie.scss";

    z-index: 10;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 213px;
    background: #181B20;
    font-family: 'DM Sans', sans-serif;
    padding: 0 32px;
    perspective: 1000;
    position: relative;

    @media screen and (max-width: 1300px) {
        padding: 0 16px;
    }

    &__info-block {
        display: flex;
        align-items: center;
        max-width: 55%;
        color: #FFFFFF;
        margin-right: auto;
        transform-origin: left center;
        will-change: transform;
        position: absolute;
        left: 32px;
        top: 0;
        height: 100%;

        @media screen and (max-width: 1300px) {
            left: 16px;
        }
    }

    &__logo-wrapper {
        width: 124px;
        height: 124px;
        border-radius: 8px;
        overflow: hidden;
        background: #fff;
        margin-right: 20px;

        @include onlyIE {
            display: flex;
            align-items: center;
        }

        @media screen and (max-width: 1300px) {
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
        }
    }

    &__logo {
        width: 100%;
        height: 100%;
        object-fit: contain;

        @include onlyIE {
            height: auto;
        }
    }

    &__main-info {
        display: flex;
        flex-direction: column;
    }

    &__chain-name {
        font-size: 32px;
        line-height: 1;
        font-weight: 700;

        @media screen and (max-width: 1300px) {
            font-size: 28px;
        }
    }

    &__details {
        margin-top: 6px;
        font-size: 14px;
        line-height: 1;
        white-space: nowrap;

        @media screen and (max-width: 1300px) {
            font-size: 12px;
        }
    }

    &__sub-title-item {
        position: relative;
        margin-right: 20px;

        &::before {
            content: '';
            position: absolute;
            top: calc(50% - 2px);
            right: -11px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #D8D8D8;
        }
    }

    &__link {
        color: #56CCF2;
        text-decoration: underline;
        cursor: pointer;
    }

    &__official-name {
        margin-top: 6px;
        font-size: 18px;
        line-height: 1.4;
        transition: all 150ms linear;

        @media screen and (max-width: 1300px) {
            font-size: 16px;
        }

        &.is-hidden {
            opacity: 0;
            visibility: hidden;
            margin-bottom: -18px;
        }
    }

    &__navigation {
        position: relative;
        margin-left: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 75%;
        max-height: 34px;
    }

    &__download-button {
        position: relative;
        margin-left: 12px;
        width: auto;
    }

    &__notes-add {
        $size: 48px;
        min-width: $size;
        height: $size;
        width: $size;
        padding: 0;
        margin-left: 16px;

        @include onlyIE {
            overflow: visible;
        }
    }

    &__notes {
        position: absolute;
        width: 480px;
        height: 360px;
        right: 16px;
        top: calc(100% + 16px);
        background: white;
        height: 0;
    }

    &__notes-wrapper {
        position: relative;
    }

    &__notes-add-counter {
        width: 20px;
        height: 20px;
        background: color("red");
        color: #fff;
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__overlay {
        background: rgba(black, 0);
        position: fixed;
        left: 0;
        top: 100%;
        width: 0;
        height: 0;
        transition: background-color 300ms ease;
        z-index: -1;

        &.is-open {
            background: rgba(black, .5);
            width: 100vw;
            height: 100vh;
        }
    }
}
