.tile-label {
    position: absolute;
    top: 40px;
    left: -21px;
    width: 132px;
    height: 12px;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    font-family: $font-search;
    background-image: linear-gradient(to left, #2F80ED 0%, #2D9CDB 17.68%, #2D9CDB 79.01%, #2F80ED 100%);
    color: #ffffff;
    text-align: center;
    transform-origin: 0 100%;
    transform: rotate(-30deg);
    z-index: 2;
    text-indent: -15px;

    &--big {
        height: 25px;
        font-size: 14px;
        line-height: 25px;
        top: 40px;
        left: -5px;
    }

    &--yellow {
        background-image: radial-gradient(#F2994A 0%, #FFB97B 17.68%, #F2994A 79.01%, #C2630E 100%);
    }
}
