@import '../../style';

.custom-drop-down {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #f2f2f2;
    border-radius: 9px;
    font-size: 16px;
    line-height: 23px;
    color: #4f4f4f;
    width: 270px;
    box-shadow: 0 0 25px 0 rgba(#000, 0.25);
    z-index: 2;
    $dd: &;

    &__title {
        line-height: 48px;
    }

    &--animated-in {
        animation: swipe-in;
        animation-duration: 300ms;
    }

    &--animated-out {
        animation: swipe-out;
        animation-duration: 300ms;
    }

    &__select-view {
        height: 100%;
        width: 100%;
    }

    &__create-view {
        height: 100%;
        width: 100%;
    }

    &--wide {
        width: 450px;
    }

    &--xwide {
        transform: translate(-50%, -250px);
        width: 695px;
        max-height: 500px;
    }

    &--share {
        transform: translate(-50%, -87.5px)
    }

    &__type {
        top: 78%;
        left: 18%;
        width: 200px;
        box-shadow: none;
    }

    &__header {
        font-size: 26px;
        font-family: $font_main;
        color: #0C0C0C;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 45px 15px;
        border-bottom: 1px solid #DEDEDE;
    }

    &__main {
        font-family: $font-search;
        padding: 25px;
        max-height: 360px;

        &--small {
            height: auto;
            padding: 5px 0;
        }

        &--high {
            height: 370px;
        }

        &--no-pad {
            padding-bottom: 0;
        }

        &--project {
            padding: 10px 45px 10px;
            max-height: 375px;
        }
    }

    &__options {
        overflow: auto;
        word-break: break-word;

        &--small {
            max-height: none;
        }

        &--comp {
            padding-bottom: 11px;
            border-bottom: 1px solid #DEDEDE;
        }

        &--proj {
            transition: height 100ms;
            margin-top: 12px;
            border: solid #E5E5E5;
            min-height: 50px;
            border-width: 1px 0;
        }
    }

    &__option {
        padding: 0 0 0 28px;
        white-space: nowrap;
        margin-top: 3px;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 300ms;
        cursor: pointer;
        text-transform: capitalize;

        &--focus {
            background: rgba(#2F80ED, .2);
        }

        &--proj {
            background-color: #F5F5F5;
            margin: 0;
            height: 34px;
            border: solid #E5E5E5;
            border-width: 0 1px 0 1px;
            font-size: 12px;
            line-height: 16px;
            font-family: $font_main;
            display: flex;
            align-items: center;
            color: #000;
            padding: 0;
            &:hover {
                background-color: darken(#f5f5f5, 10%);
            }
        }

        &--active {
            padding-left: 6px;

            #{$dd}__option-title {
                width: 277px;
                padding-left: 0;
            }
            &::before {
                content: '✓';
                display: inline-block;
                height: 16px;
                width: 16px;
                border-radius: 50%;
                background-color: #9FCD63;
                color: #fff;
                font-size: 9px;
                line-height: 16px;
                padding-left: 5px;
                margin: 0 5px 0 1px;
            }
        }
    }

    &__option-title {
        padding-left: 28px;
        width: 305px;
        border-right: 1px solid #E5E5E5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__option-date {
        text-align: center;
        width: 85px;
        border-right: 1px solid #E5E5E5;
    }

    &__option-length {
        text-transform: lowercase;
        text-align: center;
        width: 85px;
        border-right: 1px solid #E5E5E5;
    }

    &__option-author {
        padding-left: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 115px;
        &--empty {
            padding-left: 55px;
        }
    }

    &__text {
        &--bold {
            text-align: justify;
            font-weight: 700;
        }
    }

    &__radio {
        cursor: pointer;
        @include clearfix;
    }

    &__label {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 26px);
        cursor: pointer;
    }

    &__option-header {
        color: #828282;
        font-size: 16px;
        line-height: 16px;
        font-family: $font-search;
        letter-spacing: 0.5px;
        margin-bottom: 5px;
        &:before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 16px;
            height: 16px;
            border: 1px solid #828282;
            border-radius: 50%;
            margin-right: 10px;
        }

        &:hover {
            color: #4F4F4F;
        }

        &--selected {
            color: #4F4F4F;
            font-weight: 600;

            &:before {
                content: '✓';
                color: #fff;
                font-size: 10px;
                padding-left: 4px;
                padding-top: 1px;
                border: none;
                background-color: #9FCD63;
            }
        }
    }

    &__footer {
        border-top: 1px solid #e3e3e3;
        padding: 15px 25px;
        text-align: center;
        @include clearfix;
        &--no-border {
            border: 0;
            padding: 5px 45px 25px;
        }
    }

    &__input {
        font-size: 16px;
        border: 0;
        border-bottom: 1px solid;
        padding-bottom: 4px;
        background-color: transparent;
        width: 100%;
        font-family: $font-search;
        margin-bottom: 5px;
        display: block;
        &--margin {
            margin: 5px 0 7px;
            color: #828282;
            font-size: 12px;
            line-height: 16px;
            border-bottom: none;
        }

        &::placeholder {
            //color: rgba(#000, .5);
            color: #AEAEAE;
        }

        &--error {
            &::placeholder {
                color: rgba(#ff0e0c, .5);
            }
        }

        &--comp {
            font-size: 16px;
            font-family: $font_search;
            border-color: #000;
            padding-bottom: 3px;
            margin-bottom: 10px;
        }
    }

    &__textarea {
        padding: 15px;
        font-size: 16px;
        background-color: transparent;
        outline: none;
        width: 100%;
        margin-top: 20px;
        height: 275px;
        border: 1px solid #E5E5E5;
        overflow: auto;
        resize: none;
        font-family: $font-search;

        &::placeholder {
            color: #AEAEAE;
        }

        &--error {
            &::placeholder {
                color: rgba(#ff0e0c, .5);
            }
        }
    }

    &__searchbox-wrap {
        padding: 10px 25px 15px;
        border-top: 1px solid #E3E3E3;
        overflow: hidden;
    }

    &__search-box {
        position: relative;
        width: 100%;
        color: #333333;
        font-size: 12px;
        font-family: $font_search_new_2;
        line-height: 16px;
    }

    &__suggest {
        position: absolute;
        height: 90px;
        width: 100%;
        top: 19px;
        left: 0;
        overflow: auto;
        z-index: 2;
        background: #fff;
        box-shadow: -3px 3px 10px 0 rgba(0, 0, 0, 0.4);
        transition: all 300ms 50ms;
    }

    &__msg {
        font-size: 12px;
        text-transform: uppercase;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__select {
        width: 100%;
        overflow: auto;
        z-index: 1;
    }

    &__scrollbar-outer {
        height: 120px;
        width: 100%;
        border: 1px solid #BBBBBB;
        &--comp {
            height: 131px;
        }
    }

    &__scrollbar-inner {
        display: flex;
        flex-wrap: wrap;
    }

    &__pre-footer {
        padding: 10px 20px 7px;
        display: flex;
        justify-content: flex-start;
    }

    &__section {
        height: 42px;
        padding: 0 45px 20px;
    }

    &__section-inner {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-family: $font_search;
        line-height: 1;
    }

    &__section-static {
        margin-right: 10px;
        width: 110px;
    }

    &__section-left {
        display: flex;
    }

    &__link {
        text-transform: lowercase;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
        cursor: pointer;
        background-color: transparent;
        box-shadow: none;
        border: 0;

        &:hover {
            text-decoration: underline;
        }
    }
}

.drop-down-contact {
    padding: 3px 5px;
    position: relative;
    cursor: pointer;
    width: calc(33% - 2px);
    color: #413F3F;
    height: 40px;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    $ddc: &;
    background-color: #EDEDED;
    border: solid #BBBBBB;
    border-width: 0 1px 1px 0;

    &:hover {
        background-color: darken(#EDEDED, 5%);
    }

    &--checked {
        background-color: #fff;
        color: #000;

        #{$ddc}__title {
            margin-left: 0;
            color: #000;
            max-width: 85px;
            &:before {
                content: '\2713';
                color: #fff;
                font-size: 10px;
                padding: 1px 3px 1px 3px;
                border: none;
                background-color: #9FCD63;
                border-radius: 50%;
                width: 14px;
                margin: 0 3px 0 0;
                height: 14px;
            }
        }

        &:hover {
            background-color: darken(#fff, 5%);
        }
    }

    &--disabled {
        opacity: 0.5;
        &:hover {
            background-color: #EDEDED;
        }
    }

    &__title {
        width: 110px;
        color: #413F3F;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__sub-title {
        font-size: 10px;
        color: #9f9f9f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:nth-last-child(2):nth-child(3n-1), &:nth-last-child(1):nth-child(3n+1),
    &:nth-last-child(3):nth-child(3n+1), &:nth-last-child(2):nth-child(3n+1),
    &:nth-last-child(1):nth-child(3n), &:nth-last-child(1):nth-child(3n-1) {
        border-bottom: 0;
    }
    &:nth-child(3n+1):nth-child(4), &:nth-child(3n+1):nth-child(1),
    &:nth-child(3n+2):nth-child(2), &:nth-child(3n+2):nth-child(5),
    &:nth-child(3n):nth-child(3), &:nth-child(3n):nth-child(6){
        border-bottom: 1px solid #BBBBBB;
    }
}

.multi-option {
    font-size: 16px;
    color: #4F4F4F;
    display: flex;
    align-items: center;
    height: 22px;
    padding-left: 0;
    line-height: 23px;
    &:hover {
        background-color: rgba(47, 128, 237, 0.2);
    }

    &__marker {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #BDBDBD;
        text-align: center;
        color: #fff;
        font-size: 12px;
        line-height: 13px;
        float: left;
        margin-right: 8px;
        &--checked {
            background-color: #9FCD63;
            color: #fff;
            border-color: transparent;
        }
    }

}

@keyframes swipe-in {
    0% {
        left: 50%;
        opacity: 1;
    }
    50% {
        left: 25%;
        opacity: 0.3
    }
    51% {
        left: 75%;
        opacity: 0.3;
    }
    100% {
        left: 50%;
        opacity: 1;
    }
}

@keyframes swipe-out {
    0% {
        left: 50%;
        opacity: 1;
    }
    50% {
        left: 75%;
        opacity: 0.3
    }
    51% {
        left: 25%;
        opacity: 0.3;
    }
    100% {
        left: 50%;
        opacity: 1;
    }
}
