.BrandsParties {
    @import "../../../ChainPage.helpers.styles";

    &__content {
        display: flex;
    }

    &__column-wrapper {
        flex: 1;
        padding: 32px;

        & + & {
            margin-left: 16px;
        }
    }

    &__column {
        flex: 1;
    }

    &__list {
        columns: 2;
        padding-top: 8px;
    }

    &__list-item {
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        margin-bottom: 12px;
        min-width: 100%;
    }

    &__list-item-icon {
        margin-right: 8px;
    }
}