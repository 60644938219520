.DocumentActions {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &--vertical {
        .ActionButton {
            flex-direction: column;

            &__icon {
                margin-bottom: 4px;
            }
        }
    }
}