@import '../../style';

.search-results {
    $root: &;

    &__content {
        width: 100%;
        box-sizing: border-box;
        padding: 30px 30px 0;
        background: #F8F6F6;
    }

    &__search-button {
        float: left;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 40px;
    }

    &__tabs {
        display: flex;
        align-items: center;
    }

    &__section {
        max-width: 1540px;
        width: calc(100vw - 200px);
        margin: 0 auto 40px;
        @include clearfix;
        min-height: 275px;
        position: relative;
        z-index: 2;

        @include media-max(1300px) {
            width: calc(100vw - 130px);
        }

        &--centered {
            text-align: center;
        }
    }

    &__header {
        box-sizing: border-box;
        padding: 15px 0;
        display: flex;
    }

    &__anchor-list {
        position: fixed;
        right: 0;
        top: 90px;
        z-index: 3;
    }

    &__anchor-item {
        margin-bottom: 3px;
        background: #FFFFFF;
        box-shadow: 0 5px 14px rgba(0, 0, 0, 0.18);

        &:hover {

        }
    }

    &__anchor-link {
        box-sizing: border-box;
        padding-left: 9px;
        display: block;
        width: 90px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #4F4F4F;
        cursor: pointer;
        transition: all 300ms;
        text-decoration: none;

        @include media-max(1300px) {
            font-size: 8px;
            width: 60px;
            padding-left: 5px;
        }

        &:hover {
            background: rgba(#2DC0E6,.8);
            color: #fff;
        }

        &.active {
            background: #2DC0E6;
            color: #fff;
        }

        &--download {
            background: #86D004;
            color: #ffffff;
            &:hover {
                background: orange;
            }
        }
    }

    &__flex-container {
        display: flex;
        margin-bottom: 10px;
        position: relative;

        &--fixed-height {
            height: 310px;
        }
    }

    &__title {
        color: #4A4A4A;
        font-size: 36px;
        font-weight: 300;
        line-height: 40px;
        margin-bottom: 10px;
    }
}

.search-results-tabs {
    &__thumbnail {
        min-width: 95px;
        margin-left: 10px;
        background: #f9f9f9;

        &.rounded-button {
            text-transform: lowercase;
        }
    }
}

.search-results-section {
    &__wrapper {
        height: 100%;
        position: relative;
    }
}
