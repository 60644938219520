@import '../../style';

.documents-table {
    width: 100%;
    $root: &;

    &__container {
        padding: 0 50px 100px;
    }

    &__outer {
        //padding: 10px 30px;
        background-color: #fff;
    }

    &__head-row {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 20px;
            width: calc(100% - 40px);
            border-bottom: 2px solid #323232;
        }
    }

    &__head-text {
        font-weight: bold;
        cursor: pointer;
        user-select: none;
    }

    &__head-icon {
        cursor: pointer;
        position: absolute;

        &--asc {
            &:after{
                display: none;
            }

            &:before {
                transform: translateY(3px);
            }
        }

        &--dsc {
            &:before {
                display: none;
            }

            &:after {
                transform: translateY(-3px);
            }
        }
    }

    &__head-icon-svg{
        height: 10px;
    }

    &__btns{
        width: 35%;
        height: 57px;
        //background-color: #fff;

    }
}

.documents-table-row {
    cursor: pointer;
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    $root: &;

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        border-bottom: 1px solid #D8D8D8;
    }

    &:after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #D8D8D8;
    }

    &:hover{
        background-color: lighten(#2DC0E6, 43%);
        .documents-table__btns{
            display: block;
        }
    }

    &__full-info {
        //display: none;
    }

    //&__wrapper:hover #{$root}__full-info {
    //    display: block;
    //}

    &:last-child .documents-table-cell {
        border-bottom: 1px solid transparent;
    }

    &__wrapper {
        position: relative;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 20px;
        transition: margin 250ms;
        background-color: #fff;

        &--active {
            z-index: 1;
            margin-top: -15px;
            margin-bottom: -15px;
            transition: margin-top 250ms, margin-bottom 250ms 125ms;
            background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
            box-shadow: 1px 3px 14px rgba(0, 0, 0, 0.1), 0px 1px 29px rgba(0, 0, 0, 0.23947);

            &:last-child {
                margin-bottom: 0px;

                .documents-table-cell {
                    border-bottom: 1px solid #D8D8D8;
                }
            }
        }

        &:last-child {

            .documents-table-cell {
                border-bottom: 1px solid transparent;
            }

            .documents-table-row{
                &:after{
                    display: none;

                    @include IE10plus {
                        display: block;
                    }
                }
            }
        }
    }
}

.documents-table-cell {
    width: 12%;
    //flex-basis: 13%;
    position: relative;
    padding: 10px 0;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:first-child {
        width: 35%;
        //flex-basis: 30%;
    }

    &:nth-child(2) {
        width: 18%;
        //flex-basis: 30%;
    }

    &:nth-child(5) {
        width: 18%;
        //flex-basis: 30%;
    }

    &--mac-type {
        width: 30%;
        //flex-basis: 57%;
    }

    &--document-type{

        &:nth-child(3) {
            width: 18%
        }
        &:nth-child(5) {
            width: 12%
        }
    }

    &--hoverable{
        &:hover{
            color: #2DC0E6;
        }
    }

    &--left {
        justify-content: flex-start;
    }

    &--center {
        justify-content: center;
        text-align: center;
    }

    &--with-btns {
        flex-basis: 50%;
    }

    &--header {
        padding: 15px 0;
        font-size: 14px;
        border-top: 1px solid transparent;
        border-bottom: none;
    }

    &__icon {
        display: inline-block;
        vertical-align: middle;
        height: 36px;
        width: 29px;
        min-width: 29px;
        margin-right: 10px;

        svg{
            width: 100%;
            height: 100%;
        }
    }

    @media screen and (max-width: 1700px){
        font-size: 16px;
    }

    @media screen and (max-width: 1500px){
        font-size: 15px;
    }

    @media screen and (max-width: 1280px) {
        font-size: 13px;
    }
}



.reports-tab {
    .documents-table-cell {

        @media screen and (max-width: 1280px) {
            width: 7%;
        }

        &:first-child {

            @media screen and (max-width: 1280px) {
                width: 35%;
            }
        }

        &:nth-child(2) {

            @media screen and (max-width: 1280px) {
                width: 14%;
            }
        }
    }

    .documents-table__btns {

        @media screen and (max-width: 1280px) {
            width: 43%;
            margin-left: 1%;
        }
    }
}
