.InstantChartMtLink {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    height: 48px;
    position: relative;
    $arrow_thickness: 3px;
    $arrow_height: 6px;
    $arrow_width: 10px;
    font-size: 13px;
    font-weight: 400;
    vertical-align: center;
    text-decoration: none;

    svg {
        margin-left: 10px;
    }

    &:hover {
        &:before, &:after {
            opacity: 1;
        }
        &:before {
            transform: translateX(0);
        }
        &:after {
            transform: scaleX(1);
        }
    }

    &:before, &:after {
        content: '';
        color: #00b4ff;
        display: block;
        position: absolute;
        transition: transform 300ms, opacity 300ms;
        opacity: 0;
    }

    &:before {

        left: calc(100% + 8px);
        width: 0;
        height: 0;
        top: 50%;
        margin-top: - $arrow_height;
        border-style: solid;
        border-width: $arrow_height $arrow_width;
        border-color: transparent;
        border-left-color: currentColor;
        transform: translateX(-20px);
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: calc(100% - 2px);
        width: 10px;
        height: $arrow_thickness;
        top: 50%;
        margin-top: - $arrow_thickness * 0.5;
        background: currentColor;
        transform-origin: 0 50%;
        transform: scaleX(0);
    }
}