@import '../../style';

.SideNavigation {
    position: fixed;
    right: 0;
    top: 90px;
    z-index: 1;
    font-family: Muli, sans-serif;

    &__item {
        margin-bottom: 3px;
        background: #FFFFFF;
        box-shadow: 0 5px 14px rgba(0, 0, 0, 0.18);
    }
}

.SideNavigationButton {
    box-sizing: border-box;
    padding-left: 9px;
    display: block;
    width: 90px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: #4F4F4F;
    cursor: pointer;
    transition: all 300ms;
    text-decoration: none;

    @include media-max(1400px) {
        font-size: 8px;
        width: 60px;
        padding-left: 5px;
    }

    &:hover {
        background: rgba(#2DC0E6,.8);
        color: #fff;
    }

    &.active {
        background: #2DC0E6;
        color: #fff;
    }

    &--accent {
        background: #86D004;
        color: #ffffff;

        &:hover {
            background: orange;
        }
    }
    
    &--disabled {
        opacity: 0.8;
        pointer-events: none;
    }
}