@import '../../../style';

.doctype-page {

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        max-width: 1920px;
        margin: 0 auto;
        padding: 15px 50px;
        font-size: 24px;
        background: linear-gradient(0deg, rgba(249, 248, 248, 0.5) 101.41%, rgba(246, 243, 243, 0.761539) 102.52%, rgba(186, 183, 183, 0.26) 102.53%, rgba(248, 246, 246, 0.35) 103.5%, #FFFFFF 103.57%), #FFFFFF;
        box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.18), inset 0px 1px 3px rgba(0, 0, 0, 0.5);

        @include clearfix;

        .documents__change-view-outer {
            margin-top: 0;
        }
    }

    &__section {
        max-width: 1920px;
        min-width: 1024px;
        margin: 0 auto;
        filter: blur(0px);
        backface-visibility: hidden;
    }

    &__content {
        height: calc(100vh - 60px);
        background-color: #F6F6F6;
    }

    &__visibility-sensor-after {
        width: 6px;
        height: 1000px;
        max-height: 100%;
        margin-top: -2000px;
        opacity: 0;
        z-index: -1;
    }

    &__download-info {
        vertical-align: super;

        .info-icon {
            width: 20px;
            height: 20px;
            transition: all .2s;
        }

        &:hover {
            .info-icon {
                fill: #2D9CDB;
            }
        }

        &--in-doc-tab {
            float: right;
            margin-left: 10px;

            .info-icon {
                width: 24px;
                height: 24px;
                fill: #717171;
            }
        }
    }
}

.trendspotting-tiles {
    &__wrapper {
        min-height: 100vh;
        margin: 0 auto;
    }
}

.trendspotting-tiles-area {
    padding: 0 50px;
    @include media-max(1280px) {
        padding: 0;
    }

    .grid-sizer {
        width: 300px;
    }

    .gutter-sizer {
        width: 20px;
    }
}

.sensor {
    font-size: 60px;
    background-color: red;
}
