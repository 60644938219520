.TilesView {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 300ms linear, opacity 300ms linear, background-color 300ms linear, box-shadow 300ms linear;
    width: 100%;
    height: calc(100% - 50px);
    padding: 8px;
    border-radius: 8px;
    background-color: #40546F;
    box-shadow: 0 10px 10px -5px rgba(0,0,0, 0.24);

    &__inner {
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        opacity: 1;
        transform-origin: 50% 0;
        align-content: flex-start;
    }

    &--zoomed-1 {
        transform: scale(1) translate(0px, 0px) translateZ(0px) rotateX(0deg);
        z-index: 10;
    }

    &--zoomed-2 {
        transform: scale(0.98) translate(0px, 24px) translateZ(0px) rotateX(0deg);
        z-index: 9;
        background-color: #34455C;
    }

    &--zoomed-3 {
        transform: scale(0.96) translate(0px, 48px) translateZ(0px) rotateX(0deg);
        z-index: 8;
        background-color: #283547;
    }

    &--zoomed-out {
        transform: scale(1) translate(0px, 0px) translateZ(500px) rotateX(30deg);
        pointer-events: none;
        opacity: 0;
        z-index: 11;
    }
}