.DescriptionList {
    @import "@styles";
    $value-size: 50px;

    position: relative;
    width: 100%;
    height: 100%;
    columns: var(--columns);
    column-gap: 32px;

    &__item {
        display: inline-flex;
        font-size: 14px;
        line-height: 1;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 100%;
        max-width: 100%;
        border-bottom: 1px solid #2A3442;
        color: $chains-primary;
        cursor: default;

        &:hover {
            color: #fff;
            background: rgba(#56CCF2, .08);
        }
    }

    &__value {
        max-width: $value-size;
        min-width: $value-size;
        margin-left: auto;
        text-align: right;
        color: white;
    }

    &__label {
        max-width: calc(100% - #{$value-size});
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
    }
}