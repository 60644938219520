@use "sass:math";

$default-grid-wrapper: 1240px;
$default-grid-margin: 10px;
$default-col-n: 5;

@function size_in_parent($el-size, $parent-size: $default-grid-wrapper) {
    @return math.div($el-size, $parent-size)*100%;
}

@function grid_margin($wrap-w: $default-grid-wrapper, $col-m: $default-grid-margin) {
    @return size_in_parent($col-m, $wrap-w);
}

@function static_grid_margin() {
    @return $default-grid-margin;
}

@function static_grid_width($col-size: 1, $col-n: $default-col-n, $wrap-w: $default-grid-wrapper, $col-m: $default-grid-margin) {
    $col-w: math.div($wrap-w - ($col-n - 1) * $col-m, $col-n);
    @return $col-w * $col-size + $col-m * ($col-size - 1);
}

@function grid_width($col-size: 1, $col-n: $default-col-n, $wrap-w: $default-grid-wrapper, $col-m: $default-grid-margin) {
    @return size_in_parent(static_grid_width($col-size, $col-n, $wrap-w, $col-m), $wrap-w);
}

@mixin init-grid($col-n: $default-col-n, $grid-margin: $default-grid-margin, $grid-wrapper: $default-grid-wrapper) {
    width: grid_width(1, $col-n, $grid-wrapper, $grid-margin);
    margin-left: grid_margin($grid-wrapper, $grid-margin);
    float: left;
    margin-bottom: $grid-margin;
    &:nth-child(#{$col-n}n+1) {
        margin-left: 0;
        clear: left;
    }
}