.FoodPipelineMenu {
    $color: #CCCCCC;
    $item-width: 100px;
    $item-circle-size: 14px;
    $color-inception: rgb(26, 175, 84);
    $color-adoption: rgb(248, 61, 38);
    $color-proliferation: rgb(175, 81, 214);
    $color-ubiquity: rgb(147, 98, 84);

    position: relative;
    display: flex;
    $root: &;

    &:before {
        content: '';
        position: absolute;
        top: 0.5 * $item-circle-size;
        margin-top: -0.5px;
        left: 0.5 * $item-width;
        right: 0.5 * $item-width;
        height: 1px;
        background: $color;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: $item-width;
        gap: 8px;
        color: #fff;

        &.is-active {
            &.stage-inception {
                color: $color-inception;
            }
            &.stage-adoption {
                color: $color-adoption;
            }
            &.stage-proliferation {
                color: $color-proliferation;
            }
            &.stage-ubiquity {
                color: $color-ubiquity;
            }
        }
    }

    &__item-bullet {
        width: $item-circle-size;
        height: $item-circle-size;
        border-radius: 50%;
        background: currentColor;
        border: 1px solid $color;
    }

    &__item-title {
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        font-weight: 400;
        color: #fff;

        #{$root}__item.is-active & {
            font-size: 14px;
            line-height: 17px;
        }
    }

    &__hover-popup {
        width: 200px;
        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
    }

    &__hover-list {
        display: flex;
        flex-direction: column;
    }

    &__hover-item {
        text-decoration: none;
        text-transform: capitalize;

        &:hover {
            text-decoration: underline;
        }
    }
}