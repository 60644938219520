.rounded-button {
    box-sizing: border-box;
    display: inline-block;
    height: 40px;
    padding: 0 8px 0 10px;
    font-size: 16px;
    letter-spacing: .015em;
    line-height: 40px;
    color: #838383;
    text-align: left;
    text-transform: capitalize;
    border-radius: 20px;
    border: 1px solid #eaeaea;
    transition: all 300ms;
    cursor: pointer;
    text-decoration: none;
    $root: &;

    &:hover {
        transform: scale(1.1);
    }

    &__amount{
        float: right;
        display: inline-block;
        vertical-align: middle;
        box-sizing: content-box;
        height: 26px;
        min-width: 20px;
        margin-top: 6px;
        margin-left: 10px;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: 300;
        font-size: 14px;
        line-height: 29px;
        text-align: center;
        color: #FFF;
        border-radius: 13px;
        background-color: #2D9CDB;
        animation: flipInY 1s both;
    }

    &#{$root}__active, &#{$root}__active-immitation {
        border-color: transparent;
        background: #2D9CDB;
        color: #ffffff;

        #{$root}__amount{
            color: #2D9CDB;
            background-color: #fff;
        }
    }

    &__active {
        pointer-events: none;
    }

    &--center-aligned{
        text-align: center;
    }
}

@keyframes flipInY {
    0% {
        transform: perspective(400px) rotateY(90deg);
        opacity: 0;
    }
    40% {
        transform: perspective(400px) rotateY(-10deg);
    }
    70% {
        transform: perspective(400px) rotateY(10deg);
    }
    100% {
        transform: perspective(400px) rotateY(0deg);
        opacity: 1;
    }
}
