.GapReportModal {
    @import "../../../../styles/helpers/ie.scss";

    width: 1200px;
    max-width: 100%;
    text-align: center;
    height: 80vh;


    &__header {
        height: 60px;
        border-bottom: 2px solid #888;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__body {
        height: calc(100% - 160px);
        align-items: center;
    }

    &__interactive-lists {
        height: calc(100% - 100px);
    }

    &__heading1 {
        text-transform: uppercase;
        font-size: 40px;
        line-height: 40px;
    }

    &__heading2 {
        text-transform: uppercase;
        font-size: 24px;
        line-height: 30px;

        &.mb {
            margin-bottom: 20px;
        }
    }

    &__heading3 {
        font-size: 18px;
        line-height: 20px;

        &.mb {
            margin-bottom: 20px;
        }
    }

    &__link {
        color: #00b0f0;
        cursor: pointer;

        &--text {
            pointer-events: none;
        }

        &--accent {
            color: orange;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__list {
        height: calc(100% - 50px);
        font-size: 14px;
        text-align: left;
        position: relative;
    }

    &__saved-queries-table {
        height: calc(100% - 50px);
        max-width: 800px;
        margin: 0 auto;
    }

    &__high-button {
        background-color: transparent;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        border: 1px solid #fff;
        color: #fff;
        font-size: 16px;
        margin: 0 4px;
        width: 300px;
        cursor: pointer;
        transition: background-color 300ms;

        &:hover {
            background: rgba(#fff, 0.3);
        }
    }

    &__section {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        &--justify {
            justify-content: space-between;
        }

        &--center {
            justify-content: center;
        }
    }

    &__start {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        img {
            height: 180px;
            margin-top: 40px;
            object-fit: contain;

            @include onlyIE {
                height: auto;
                width: 400px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__section-line {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__columns {
        position: relative;
        display: flex;
        height: calc(100% - 60px);
        width: 100%;
        flex-direction: row;
    }

    &__column {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex: 0 1 50%;
        margin: 0 4px;
    }

    &__column2 {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex: 0 2 50%;
        margin: 0 4px;
    }

    &__form {
        margin-top: 16px;
        height: calc(100% - 65px);
    }

    &__autocomplete {
        height: 100%;
    }

    &__footer {
        height: 80px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 2px solid #888;
        justify-content: space-between;
        transform: translateY(20px);
    }
}