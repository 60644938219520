.Socials {
    @import "../../ChainPage.helpers.styles";

    &__section {
        position: relative;
        padding-top: 40px;
        padding-bottom: 40px;

        & + & {
            @include section-divider;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: calc(75vh - 114px);
    }

    &__title {
        font-weight: 500;
        font-size: 32px;
        padding-bottom: 32px;
    }

    &__footer-action {
        color: color("link");
        display: inline-flex;
        width: auto;
        text-transform: none;
        font-weight: 700;
        font-size: 13px;
        background: none;
        text-decoration: underline;
        min-width: 0;
        padding-left: 0;
        padding-right: 0;

        &:hover {
            text-decoration: none;
        }
    }

    &__grid {
        display: flex;
        width: 100%;
    }

    &__column {
        flex: 1;

        & + & {
            margin-left: 16px;
        }
    }

    &__grid-item {
        flex: 1;
        width: 25%;

        & + & {
            margin-left: 16px;
        }

        &.is-large {
            width: 100%;
            margin-bottom: 16px;
            margin-left: 0 !important;
            margin-right: 0;
        }
    }

    &__youtube-frame-wrapper {
        position: relative;
        padding-top: 56.25%;
        width: 80vw;
    }

    &__youtube-frame {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        max-height: 90vh;
    }

    &__footer {
        position: relative;
        text-align: right;

        &--no-gap {
            margin-bottom: -24px;
        }
    }

    &__footer-action {
        position: relative;
        z-index: 1;
    }

    &__content {
        justify-content: center;
    }

    &__no-data {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}