.DocumentViewItem {
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    min-height: 172px;
    padding: 22px;
    background-color: #fff;
    border: 1px solid #E8E9EA;
    text-decoration: none;

    &__preview {
        width: 40%;
    }

    &__image {
        width: 100%;
        display: block;

        &--blank {
            min-height: 120px;
            margin-right: auto;
            width: auto;
            margin-left: auto;
        }
    }

    &__details {
        width: 60%;
        padding-left: 22px;
    }

    &__group {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 26px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
        color: black;
    }

    &__info {
        font-family: 'Lato', Sans-Serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.2;
        color: #514F4F;
        display: flex;
        border-bottom: 1px solid #000;
        padding-bottom: 8px;
        padding-top: 8px;
        margin-bottom: 8px;
    }

    &__info-item {
        position: relative;

        & + & {
            padding-left: 20px;

            &:after {
                content: '';
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: #514F4F;
                top: 50%;
                margin-top: -1.5px;
                position: absolute;
                left: 8px;
            }
        }
    }

    &__info-link {
        text-decoration: none;

        &:hover {
            color: #2F80ED;
        }
    }

    &__actions {
        font-size: 20px;
    }

    &__copy {
        margin-left: auto;
        font-size: 16px;
        text-transform: uppercase;
        color: #828282;
        font-family: 'Lato', Sans-Serif;
        cursor: pointer;
        margin-top: -12px;

        &:hover {
            color: #2F80ED;
            text-decoration: underline;
        }
    }
}