.BrandsDetailedAttributes {
    @import "../../../ChainPage.helpers.styles";
    @import "@styles";

    &__columns {
        display: flex;
    }

    &__content {
        width: 100%;
        padding: 16px;
    }

    &__column {
        flex: 1;

        & + & {
            margin-left: 60px;
        }
    }

    &__title {
        color: #56CCF2;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 1;
        width: 100%;
        border-bottom: 2px solid;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
    }

    &__sort-icon {
        position: relative;
        z-index: 1;
        cursor: pointer;
        width: 30px;
        display: flex;
        justify-content: center;

        & > svg {
            fill: #979797;
        }

        &:hover {
            & > svg {
                fill: #fff;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        border-bottom: 1px solid #2A3442;
        padding: 8px 0;


        &-label {
            opacity: 0.5;
            max-width: 75%;
        }
    }

    &__footer {
        margin-top: 24px;
    }

    &__footer-action {
        color: color("link");
        display: inline-flex;
        width: auto;
        text-transform: none;
        font-weight: 700;
        font-size: 13px;
        background: none;
        text-decoration: underline;
        padding-left: 0;
        min-width: 0;

        &:hover {
            text-decoration: none;
        }
    }

    &__no-data {
        font-size: 46px;
        line-height: 1;
        color: $chains-primary;
        padding: 40px 0;
        text-align: center;
        cursor: default;
    }
}