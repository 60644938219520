@use '@datassential/platform-ui-lib/lib/style/constants' as *;

.Filters {
    @import "../../../styles/helpers/ie.scss";

    height: 60px;
    position: relative;
    $root: &;
    font-family: "DM Sans", sans-serif;

    &.is-opened {
        #{$root}__overlay {
            pointer-events: all;
            opacity: 1;
        }

        #{$root}__content {
            pointer-events: all;
            opacity: 1;
        }
    }

    &__header {
        position: relative;
        z-index: 4;
    }

    &__overlay {
        position: fixed;
        z-index: 2;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        background: rgba(#000, 0.8);
        pointer-events: none;
        opacity: 0;
        transition: opacity 300ms;
    }

    &__content {
        position: absolute;
        z-index: 3;
        top: 64px;
        left: 0;
        right: 0;
        opacity: 0;
        pointer-events: none;
        transition: opacity 300ms;

        @include onlyIE {
            z-index: 201; // upper custom scrollbar index
        }
    }
}

.FiltersHeader {
    height: 100%;
    display: flex;
    border: 1px solid #202A37;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
    justify-content: stretch;

    &__item {
        flex: 1 1 100%;

        &:first-child {
            border-left: 0;
        }
    }
}

.FiltersThumb {
    $backgroundColor: #131921;
    padding: 10px 24px;
    border-left: 1px solid #202A37;
    position: relative;
    height: 100%;
    cursor: pointer;
    transition-delay: 0s;
    transition-duration: 300ms;
    transition-property: background-color;
    background: $backgroundColor;

    &:hover {
        background: #222C3B;
    }

    &--active {
        pointer-events: none;

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            height: 2px;
            background: #32C1E9;
        }
    }

    &--invalid {
        background: #FF490F;
    }

    &--modified {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 8px;
            right: 8px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: #FF490F;
        }
    }

    &__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 40px;
        transition: line-height 300ms;

        &.is-compressed {
            line-height: 22px;
        }
    }

    &__sub-title, &__input {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        height: 18px;
        color: #84A4AC;
        position: relative;
    }

    &__input {
        width: 100%;
        background: transparent;
        height: 46px;
        box-shadow: none;
        border: 0;
        padding: 0;
        padding-top: 28px;
        border-bottom: 1px solid #84A4AC;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.FiltersContent {
    $footer-height: 80px;
    $header-height: 35px;
    $gap: 24px;
    $background: #131921;
    $border: #13343c;
    $radio-height: 50px;
    $root: &;

    padding-bottom: 80px;
    height: calc(
            100vh
            - #{$platform-navigation-height}
            - #{$platform-header-height}
            - 24px /* top chains page padding top */
            - 24px /* top chains header height */
            - 32px /* top chains header margin bottom */
            - 60px /* filters tabs height */
            - 30px /* screen bottom gap */
    );
    background: $background;
    border: 1px solid $border;
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;

    &__header,
    &__body {
        transition: opacity 500ms ease-in-out;

        &.is-disabled {
            pointer-events: none;
            opacity: 0.25;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        margin-bottom: 8px;
        height: $header-height;
    }

    &__body {
        padding: 24px;
        height: 100%;

        & & {
            padding: 0;

            &#{$root}__body--fluid {
                padding-bottom: 24px;
            }

            &:not(#{$root}__body--fluid) {
                min-height: 200px;
                padding-right: 12px;
            }
        }

        &--fluid {
            height: auto;
        }
    }

    &__footer {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 24px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: $footer-height;
        background: #1F2733;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: $border;
        }
    }

    &__part {
        display: flex;

        &:first-child {
            margin-right: auto;
        }

        &--pr {
            padding-right: 20px;
        }
    }

    &__filter {
        cursor: pointer;
        text-decoration: underline;
        color: #84A4AC;

        &:hover {
            text-decoration: none;
        }
    }

    &__title {
        color: #32C1E9;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
    }

    &__sub-title {
        font-size: 14px;
        color: #84A4AC;
        text-transform: none;
        font-weight: 400;
    }

    &__input {
        width: 80px;
        margin-right: 50px;
        display: flex;
    }

    &__label {
        padding-top: 32px;
        padding-left: 8px;
    }

    &__radio {
        display: flex;
        font-size: 18px;
        font-weight: 700;
        height: $radio-height;
    }

    &__checkboxes {
        display: flex;
        flex-wrap: wrap;
    }

    &__checkbox {
        & + & {
            margin-left: $gap;
        }
    }

    &__grid {
        height: calc(100% - #{$radio-height});
    }

    &__rows,
    &__columns {
        display: flex;
        height: calc(100% - #{$footer-height});
        width: 100%;
    }

    &__columns {
        &--fluid {
            height: auto;
        }

        &--border-bottom {
            position: relative;
            margin-bottom: $gap;

            &:after {
                content: '';
                width: calc(100% + #{$gap * 2});
                position: absolute;
                left: -$gap;
                bottom: 0;
                height: 1px;
                background: $border;
            }
        }
    }

    &__rows {
        flex-direction: column;
    }

    &__row {
        &--fluid {
            flex: 1;
        }
    }

    &__column {
        $divider-width: 80px;

        position: relative;
        min-width: calc(50% - #{$divider-width * 0.5});
        height: 100%;
        flex: 1;

        &--fluid {
            height: auto;
            padding-bottom: $gap;
        }

        &--border {
            position: relative;
            padding-left: 16px;
            padding-right: $gap;

            &:after {
                content: '';
                width: 1px;
                position: absolute;
                left: 0;
                top: -$gap;
                height: calc(100% + #{$gap});
                background: $border;
            }
        }

        &--divider {
            width: $divider-width;
            flex-basis: $divider-width;
            flex-grow: 0;
            min-width: 0;

            &:before {
                content: '';
                background: #13343C;
                width: 1px;
                height: calc(100% + #{$gap * 2});
                position: absolute;
                left: 50%;
                margin-left: -0.5px;
                top: -$gap;
            }

            &:after {
                content: 'OR';
                position: absolute;
                left: 0;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                background: $background;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
                padding-top: 12px;
                padding-bottom: 12px;
            }
        }
    }


}
