.Modal {
    $root: &;
    $z-index: 1000;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $z-index;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-nested {
        z-index: $z-index + 1;
    }

    &-enter {
        opacity: 0;
        z-index: 100;
        transform: translateY(30px);
    }

    &-enter-active {
        opacity: 1;
        transform: translateY(0px);
        transition: all 600ms;
    }

    &-enter-done {
        opacity: 1;
    }

    &-exit {
        opacity: 0;
        transition: opacity 150ms;
    }

    &__slide-top-enter {
        opacity: 0;
        z-index: 100;
        transform: translateY(-500px);
    }

    &__slide-top-enter-active {
        opacity: 1;
        transform: translateY(0px);
        transition: all 300ms;
    }

    &__slide-top-enter-done {
        opacity: 1;
    }

    &__slide-top-exit-active {
        transform: translateY(-500px);
    }

    &__slide-top-exit {
        opacity: 0;
        transition: all 300ms;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(#000, 0.8);
        backdrop-filter: blur(5px);
        z-index: 1;
    }

    &__content {
        position: relative;
        z-index: 2;
    }

    &__inner {
        overflow-y: auto;
        max-height: 90vh;
        background: #1c2531;
        color: #fff;
        z-index: 2;
        position: relative;
        min-width: 600px;
        padding: 24px;
        border-radius: 8px;
        max-width: calc(100vw - 20px);

        @media (max-height: 900px) {
            max-height: 95vh;
        }

        @media (max-width: 1280px) {
            max-width: calc(100vw - 20px);
        }
    }

    &__close {
        position: absolute;
        z-index: 2;
        width: 32px;
        height: 32px;
        top: 0;
        right: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            pointer-events: none;
            vertical-align: top;
        }
    }

    &.is-content-full & {
        &__content {
            width: 100%;
            height: 100%;
            padding: 32px;
        }

        &__close {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
